import { useMemo, FC } from 'react';
import { TransactionStats } from 'interface/dashboardInterface';
import { GroupedBarChart } from 'components/common/chart/barChart';
import { cardBrand } from 'components/dashboard/constant';
import { percentage } from 'utils/helper';

interface IProps {
  groupedTransactionStats: [string, TransactionStats[]][];
}

interface IDatasets {
  master: number[];
  visa: number[];
  other: number[];
}

const CardBreakdown: FC<IProps> = ({ groupedTransactionStats }) => {
  const cardBreakDownData = useMemo(() => {
    const values: IDatasets = {
      master: [],
      visa: [],
      other: [],
    };
    let totalValue = 0;

    groupedTransactionStats.forEach(([, statsData]) => {
      let master = 0;
      let visa = 0;
      let other = 0;
      statsData.forEach(stat => {
        const card_brand = stat.CardBrand.toLowerCase();
        if (card_brand === cardBrand.master) {
          master += stat.Count;
        } else if (card_brand === cardBrand.visa) {
          visa += stat.Count;
        } else {
          other += stat.Count;
        }
        totalValue += stat.Count;
      });
      values.master.push(master);
      values.visa.push(visa);
      values.other.push(other);
    });

    return {
      chart: {
        labels: groupedTransactionStats.map(([date]) => date),
        datasets: [
          {
            label: 'Master',
            borderColor: '#F90182',
            backgroundColor: '#F90182',
            legend: 'Master Card',
            data: values.master,
            total: values.master.reduce((prev, current) => prev + current, 0),
          },
          {
            label: 'Visa',
            borderColor: '#6AD2FF',
            backgroundColor: '#6AD2FF',
            legend: 'VISA',
            data: values.visa,
            total: values.visa.reduce((prev, current) => prev + current, 0),
          },
          {
            label: 'Others',
            borderColor: '#C237F3',
            backgroundColor: '#C237F3',
            legend: 'Others',
            data: values.other,
            total: values.other.reduce((prev, current) => prev + current, 0),
          },
        ],
      },
      totalValue: totalValue,
    };
  }, [groupedTransactionStats]);
  return (
    <div className="lg_card_container row-span-2">
      <div className="header">
        <p className="header_text extend_sm">Card Breakdown</p>
      </div>
      <div className="body">
        <div className="left_side_details">
          <div className="stats_details">
            {cardBreakDownData.chart.datasets.map(data => {
              return (
                <div key={data.label}>
                  <p className="head_text">
                    <span
                      className="indicator"
                      style={{ background: data.backgroundColor }}
                    />
                    {data.legend}
                  </p>
                  <p className="sub_text">
                    {data.total +
                      '/' +
                      percentage(
                        data.total,
                        cardBreakDownData?.totalValue,
                        false,
                        1,
                      ) +
                      '%'}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="chart_wrapper approval_chat">
          <GroupedBarChart
            data={cardBreakDownData.chart}
            hasPercentageValue={true}
            className="chart"
          />
        </div>
      </div>
    </div>
  );
};

export default CardBreakdown;
