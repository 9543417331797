import { useMemo, FC } from 'react';
import { chargeType } from 'components/dashboard/constant';
import { LifetimeSubscriptionStatsData } from 'interface/dashboardNewIterface';
import { ScriptableContext } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { filledLineChartoptions } from './LineChart/constant';
interface IProps {
  groupedTransactionStats: [string, LifetimeSubscriptionStatsData[]][];
}
interface IDatasets {
  subscriptionCount: number[];
  straightCount: number[];
  totalCount: number[];
}

const SalesCount: FC<IProps> = ({ groupedTransactionStats }) => {
  const salesRevenueData = useMemo(() => {
    const values: IDatasets = {
      straightCount: [],
      subscriptionCount: [],
      totalCount: [],
    };
    groupedTransactionStats.forEach(([, statsData]) => {
      const { straight, subscription, total } = statsData.reduce(
        ({ straight, subscription }, stat) => {
          if (stat.Kind === chargeType.subscription) {
            subscription += stat.Approved;
            return {
              straight,
              subscription,
              total: straight + subscription,
            };
          }
          straight += stat.Approved;
          return {
            straight,
            subscription,
            total: straight + subscription,
          };
        },
        {
          straight: 0,
          subscription: 0,
          total: 0,
        },
      );
      values.straightCount.push(Number(straight?.toFixed(2)));
      values.subscriptionCount.push(Number(subscription?.toFixed(2)));
      values.totalCount.push(Number(total?.toFixed(2)));
    });
    const overallTotal = values.totalCount.reduce(
      (prev, current) => prev + current,
      0,
    );
    const subscriptionTotal = values.subscriptionCount.reduce(
      (prev, current) => prev + current,
      0,
    );
    const straightTotal = values.straightCount.reduce(
      (prev, current) => prev + current,
      0,
    );
    return {
      topContainer: [
        {
          legend: 'Total',
          total: overallTotal,
        },
        {
          legend: 'Subscription',
          borderColor: '#F90182',
          total: subscriptionTotal,
        },
        {
          legend: 'Straight Sale',
          borderColor: '#6AD2FF',
          total: straightTotal,
        },
      ],
      chart: {
        labels: groupedTransactionStats.map(([date]) => date),
        datasets: [
          {
            label: 'Subscription',
            borderColor: '#F90182',
            legend: 'Subscription Revenue',
            data: values.subscriptionCount,
            total: subscriptionTotal,
            pointBorderColor: 'white',
            pointBackgroundColor: '#05CD99',
            backgroundColor: (context: ScriptableContext<'line'>) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(249, 1, 130, 0.5)'); // Darker Rose with 50% opacity
              gradient.addColorStop(1, 'rgba(249, 1, 130, 0)'); // Transparent Rose
              return gradient;
            },

            OverallTotal: values.totalCount.map(
              (_, index) =>
                values.subscriptionCount[index] + values.straightCount[index],
            ),
          },
          {
            label: 'Straight',
            borderColor: '#6AD2FF',
            legend: 'Straight Sale Revenue',
            pointBorderColor: 'white',
            backgroundColor: (context: ScriptableContext<'line'>) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(249, 1, 130, 0.5)'); // Darker Rose with 50% opacity
              gradient.addColorStop(1, 'rgba(249, 1, 130, 0)'); // Transparent Rose
              return gradient;
            },
            data: values.straightCount,
            total: straightTotal,
          },
        ],
      },
      totalSubscription: subscriptionTotal,
      totalStraight: subscriptionTotal,
      total: overallTotal,
    };
  }, [groupedTransactionStats]);

  return (
    <div className="lg_card_container">
      <div className="header">
        <p className="header_text">Sales Count</p>
      </div>
      <div className="body">
        <div className="left_side_details">
          <div className="stats_details">
            {salesRevenueData.topContainer.map(data => {
              return (
                <div
                  key={data?.legend}
                  className="flex flex-col justify-center items-center">
                  <p className="head_text">
                    {data?.legend !== 'Total' ? (
                      <span
                        className="indicator"
                        style={{ background: data?.borderColor }}
                      />
                    ) : null}

                    {data?.legend}
                  </p>
                  <p className="sub_text">{data?.total}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="chart_wrapper">
          <Line
            options={filledLineChartoptions(salesRevenueData?.chart.labels, '')}
            data={salesRevenueData?.chart}
            className="chart"
          />
        </div>
      </div>
    </div>
  );
};

export default SalesCount;
