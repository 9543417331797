import { PopUpModal } from 'shared-components';
import { Checkbox } from 'shared-components';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import membershipOfferService from 'services/membershipOfferService';
import {
  IMemberShipProductsDetails,
  IProductsOfStore,
} from 'interface/membershipOffersInterface';
import { IStoreFront } from 'interface/settingsInterface';
import { Loader } from 'components/common/loader';
import PlaceholderIcon from 'assets/icons/placeholder_image.svg';
import './style.scss';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import TextField from 'components/common/textField';
import { debounce } from 'lodash';
import ToggleSwitch from 'components/common/toggleSwitch';

interface IAddProductPopupProps {
  storeFront: IStoreFront | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProductsAdded: React.Dispatch<
    React.SetStateAction<IMemberShipProductsDetails[]>
  >;
  productsAdded: IMemberShipProductsDetails[];
}

const AddProductPopup: FC<IAddProductPopupProps> = ({
  storeFront,
  open,
  setOpen,
  setProductsAdded,
  productsAdded,
}) => {
  const [products, setProducts] = useState<IMemberShipProductsDetails[]>([]);
  const [productsFiltered, setProductsFiltered] = useState<
    IMemberShipProductsDetails[]
  >([]);
  const [selectedProductes, setSelectedProducts] =
    useState<IMemberShipProductsDetails[]>(productsAdded);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextAPIDetails, setNextAPIDetails] = useState({
    Cursor: '',
    HasNextPage: false,
  });
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [inputSearchValue, setInputValue] = useState('');
  const [showMembershipOnly, setShowMembershipOnly] = useState(false);
  const handleChangeWithDebounce = useCallback(
    debounce(e => {
      setProducts([]);
      setProductSearch(pre => ({
        ...pre,
        Filter: e.target.value,
      }));
    }, 500),
    [],
  );

  const [productSearch, setProductSearch] = useState<IProductsOfStore>({
    ShopID: storeFront?.ShopID || '',
    StoreID: storeFront?.StoreID || '',
    Limit: 25,
  });

  const getProductsOfStore = async () => {
    if (!productSearch.ShopID || !productSearch.StoreID) return;
    setLoading(true);
    const res = await membershipOfferService.getAllProducts(productSearch);
    if (res.status === 200 && res.data?.Result?.length) {
      setProducts(prevData => [...prevData, ...res.data.Result]);
      setNextAPIDetails({
        Cursor: res.data.EndCursor,
        HasNextPage: res.data.HasNextPage,
      });
    } else {
      setProducts([]);
    }
    setLoading(false);
  };

  const lastElementRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && nextAPIDetails.HasNextPage) {
          setProductSearch(prevPage => ({
            ...prevPage,
            Cursor: nextAPIDetails.Cursor,
          }));
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [nextAPIDetails],
  );

  useEffect(() => {
    if (productSearch.ShopID && productSearch.StoreID) getProductsOfStore();
  }, [productSearch]);

  useEffect(() => {
    const filteredProduct = products.filter(product => {
      if (showMembershipOnly) {
        return true;
      } else {
        return (
          product.MembershipGroupsCount === 0 ||
          productsAdded.find(products => products.ID === product.ID)
        );
      }
    });
    setProductsFiltered(filteredProduct);
  }, [showMembershipOnly, products]);
  return (
    <PopUpModal
      heading={`Add Products`}
      open={open}
      handleClose={() => setOpen(false)}
      className="grid"
      buttons={[
        {
          buttonLabel: 'Cancel',
          buttonType: 'negative',
          buttonFunction: () => setOpen(false),
        },
        {
          buttonLabel: 'Add',
          buttonType: 'positive',
          buttonFunction: () => {
            setOpen(prev => !prev);
            setProductsAdded(selectedProductes);
          },
        },
      ]}>
      <div className="h-full grid grid-rows-[min-content_1fr] overflow-auto">
        {/* for future use */}
        <div className="flex gap-2">
          <TextField
            fixedSize="md"
            placeholder="Search Products"
            value={inputSearchValue}
            onChange={e => {
              setInputValue(e.target.value);
              handleChangeWithDebounce(e);
            }}
            className="w-full input-field"
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
          {/*<Select
            value={filterMembership}
            onChange={e => setFilterMembership(e)}
            options={statusTypes}
            placeholder="Select"
          /> */}
        </div>
        <div className="grid grid-rows-[min-content_1fr] overflow-auto">
          <div className="flex justify-between py-3">
            <div className="flex items-center">
              <Checkbox
                checked={
                  selectedProductes.length ===
                  products.filter(
                    product =>
                      product.MembershipGroupsCount === 0 ||
                      productsAdded.find(
                        products => products.ID === product.ID,
                      ),
                  ).length
                }
                onChange={val => {
                  if (val.target.checked) {
                    setSelectedProducts(() => {
                      return products.filter(
                        product =>
                          product.MembershipGroupsCount === 0 ||
                          productsAdded.find(
                            products => products.ID === product.ID,
                          ),
                      );
                    });
                  } else {
                    setSelectedProducts([]);
                  }
                }}
              />
              <span className="font-medium text-white">Select All</span>
            </div>
            <div className="flex items-center">
              <p className="common_input_label text-white">
                Items already in an offer
              </p>
              <ToggleSwitch
                checked={showMembershipOnly}
                className="filter_form_input"
                onChange={e => {
                  setShowMembershipOnly(e.target.checked);
                }}
              />
            </div>
          </div>
          <div className="product-list-container text-white rounded-md overflow-auto h-full">
            {productsFiltered?.map((product, index) => {
              const isMembershipProduct =
                product.MembershipGroupsCount &&
                !productsAdded.find(products => products.ID === product.ID);
              return (
                <div
                  className="products-container"
                  key={index}
                  ref={
                    index === productsFiltered.length - 1
                      ? lastElementRef
                      : null
                  }>
                  <div className="flex items-center justify-between p-2">
                    <div className="flex items-center gap-3">
                      <Checkbox
                        disabled={!!isMembershipProduct}
                        disableRipple={true}
                        className={`!p-0 ${isMembershipProduct ? 'opacity-50' : ''}`}
                        checked={selectedProductes.some(
                          selectedProduct => selectedProduct.ID === product.ID,
                        )}
                        onChange={val => {
                          if (val.target.checked) {
                            setSelectedProducts(pre => [...pre, product]);
                          } else {
                            setSelectedProducts(pre =>
                              pre.filter(item => item.ID !== product.ID),
                            );
                          }
                        }}
                      />
                      <img
                        src={product.ImageURL || PlaceholderIcon}
                        alt="Product"
                        className="w-10 h-10 rounded-md"
                      />
                      <span className="font-normal ">{product.Title}</span>

                      {isMembershipProduct ? (
                        <div className="status_badge success_status">
                          Membership
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Loader loading={loading} />
      </div>
    </PopUpModal>
  );
};

export default AddProductPopup;
