import React from 'react';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toolTipInfo } from '../../../page-builder/utils/constants';

interface TooltipProps {
  title: keyof typeof toolTipInfo;
  children: React.ReactElement;
}

const CustomTooltip: React.FC<TooltipProps> = ({ title, children }) => {
  return (
    <div className="flex justify-between w-full items-center">
      {children}
      <Tooltip title={toolTipInfo[title]} placement="top" className="ml-2">
        <InfoOutlinedIcon className="!w-[18px] !h-[18px]" />
      </Tooltip>
    </div>
  );
};

export default CustomTooltip;
