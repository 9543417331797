import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PopOver from 'components/common/popOver';
import React, { useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IOrder } from 'interface/orderInterface';
import PlaceholderIcon from 'assets/icons/placeholder_image.svg';
import 'components/orders/cart/style.scss';

const defaultAmount = '0.00';

interface IProps {
  row: IOrder;
}
const Cart: React.FC<IProps> = ({ row }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClosePopover = () => {
    setAnchorEl(null); // Close the popover
  };
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Set the anchor position to the clicked button
  };

  const { subTotal, vipSavings } = useMemo(() => {
    let subTotal = 0; // sum of item's total amount
    let vipSavings = 0; //  each item's unit_amount and original_unit_amount
    if (row?.Items?.length) {
      row?.Items.forEach(item => {
        subTotal += Number(item?.UnitPrice || item?.SalePrice) * item?.Quantity;
        if (item?.OriginalPrice) {
          vipSavings +=
            (Number(item?.OriginalPrice) -
              Number(item?.UnitPrice || item?.SalePrice)) *
            item?.Quantity;
        }
      });
    }
    return { subTotal, vipSavings: vipSavings.toFixed(2) };
  }, [row]);

  return (
    <>
      <div className="order-cart">
        {row.Items?.length}
        <button onClick={e => handleOpenPopover(e)}>
          <KeyboardArrowDownIcon />
        </button>
      </div>
      <PopOver
        child={
          <div>
            <div className="cart-items">
              <div className="cart-title">Cart Items</div>
              <div className="carts-list">
                {row.Items &&
                  row.Items.map((item, key) => {
                    return (
                      <div className="item" key={key}>
                        <div className="product-wrap">
                          <div>
                            <img
                              src={item?.ImageURL || PlaceholderIcon}
                              alt="Product"
                              className="product-image"
                            />
                          </div>
                          <div className="item-content">
                            <p className="item-name">{item?.Name}</p>
                            <p className="qty-wrap">
                              Quantity: {item?.Quantity}&nbsp;
                              <CloseIcon fontSize="small" /> &nbsp;
                              <span>
                                $
                                {item?.UnitPrice ||
                                  item?.SalePrice ||
                                  defaultAmount}
                              </span>
                              <div className="total-product-amount">
                                $
                                {Number(item?.UnitPrice) * item?.Quantity ||
                                  Number(item?.SalePrice) * item?.Quantity ||
                                  defaultAmount}
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="summary-content">
                <div className="summary-container">
                  <div className="sum-header">Order Summary</div>
                  <div className="sum-content">
                    <p>
                      Subtotal <span>${subTotal || defaultAmount}</span>
                    </p>
                    <p>
                      VIP Savings{' '}
                      <span className="vip-price">
                        ${vipSavings || defaultAmount}
                      </span>
                    </p>
                    <p className="total">
                      Total <span>${row.TotalAmount}</span>
                    </p>
                    <p>
                      Paid <span>${row.TotalAmount}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        onClose={handleClosePopover}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default Cart;
