import { useMemo, FC, useState } from 'react';
import { TransactionStats } from 'interface/dashboardInterface';
import {
  chargeType,
  totalRevenueFields,
  transactionStatus,
} from 'components/dashboard/constant';
import { GroupedLineChart } from 'components/common/chart/lineChart';
import ButtonGroup from 'components/common/ButtonGroup';
import { UsDollarFormatter } from 'utils/helper';
interface IProps {
  groupedTransactionStats: [string, TransactionStats[]][];
}
interface IDatasets {
  subscription: number[];
  straight: number[];
  total: number[];
}

const revenueTypes = {
  grossRevenue: 'Gross Revenue',
  netRevenue: 'Net Revenue',
};
const filterButtons = Object.values(revenueTypes).map(types => ({
  name: types,
}));

const SalesRevenue: FC<IProps> = ({ groupedTransactionStats }) => {
  const [revenueType, setRevenueType] = useState(revenueTypes.grossRevenue);

  const salesRevenueData = useMemo(() => {
    const values: IDatasets = {
      straight: [],
      subscription: [],
      total: [],
    };

    groupedTransactionStats.forEach(([, statsData]) => {
      const { straight, subscription, total } = statsData.reduce(
        ({ straight, subscription }, stat) => {
          const isRevenue = totalRevenueFields.includes(stat.Status);
          if (!isRevenue) {
            return {
              straight,
              subscription,
              total: straight + subscription,
            };
          }
          if (stat.ChargeType === chargeType.subscription) {
            subscription += stat.Amount;
            if (
              revenueType === revenueTypes.netRevenue &&
              (stat.Status === transactionStatus.partialRefund ||
                stat.Status === transactionStatus.refunded)
            ) {
              subscription -= stat.Amount;
            }
            return {
              straight,
              subscription,
              total: straight + subscription,
            };
          }
          straight += stat.Amount;
          if (
            revenueType === revenueTypes.netRevenue &&
            (stat.Status === transactionStatus.partialRefund ||
              stat.Status === transactionStatus.refunded)
          ) {
            straight -= stat.Amount;
          }
          return {
            straight,
            subscription,
            total: straight + subscription,
          };
        },
        {
          straight: 0,
          subscription: 0,
          total: 0,
        },
      );

      values.straight.push(Number(straight?.toFixed(2)));
      values.subscription.push(Number(subscription?.toFixed(2)));
      values.total.push(Number(total?.toFixed(2)));
    });

    return {
      chart: {
        labels: groupedTransactionStats.map(([date]) => date),
        datasets: [
          {
            label: 'Total',
            borderColor: '#C237F3',
            backgroundColor: '#C237F3',
            legend: 'Total',
            data: values.total,
            total: values.total.reduce((prev, current) => prev + current, 0),
          },
          {
            label: 'Subscription',
            borderColor: '#F90182',
            backgroundColor: '#F90182',
            legend: 'Subscription',
            data: values.subscription,
            total: values.subscription.reduce(
              (prev, current) => prev + current,
              0,
            ),
          },
          {
            label: 'Straight',
            borderColor: '#6AD2FF',
            backgroundColor: '#6AD2FF',
            legend: 'Straight Sale',
            data: values.straight,
            total: values.straight.reduce((prev, current) => prev + current, 0),
          },
        ],
      },
      totalSubscription: values.subscription.reduce(
        (prev, current) => prev + current,
        0,
      ),
      totalStraight: values.straight.reduce(
        (prev, current) => prev + current,
        0,
      ),
      total: values.total.reduce((prev, current) => prev + current, 0),
    };
  }, [groupedTransactionStats, revenueType]);

  return (
    <div className="lg_card_container row-span-2">
      <div className="header">
        <p className="header_text">Sales Revenue</p>
        <ButtonGroup
          active={revenueType}
          setActive={setRevenueType}
          buttons={filterButtons}
        />
      </div>
      <div className="body">
        <div className="left_side_details">
          <div className="stats_details">
            {salesRevenueData.chart.datasets.map(data => {
              return (
                <div key={data.label}>
                  <p className="head_text">
                    <span
                      className="indicator"
                      style={{ background: data.borderColor }}
                    />
                    {data.legend}
                  </p>
                  <p className="sub_text">$ {UsDollarFormatter(data.total)}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="chart_wrapper">
          <GroupedLineChart
            data={salesRevenueData.chart}
            prefix="$"
            isLabelRequired={true}
            className="chart"
          />
        </div>
      </div>
    </div>
  );
};

export default SalesRevenue;
