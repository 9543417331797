import React, { useMemo } from 'react';
import { ReactComponent as SubscriptionRevenue } from 'assets/icons/subscription-revenue.svg';
import { ReactComponent as StraightSaleRevenue } from 'assets/icons/straight-sale-revenue.svg';
import { ReactComponent as ActiveSubscribers } from 'assets/icons/active-subscribers.svg';
import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import {
  LifetimeStats,
  LifetimeSubscriptionStatsData,
} from 'interface/dashboardNewIterface';
import { chargeType, transactionStatus } from './constant';
import { ISubscriptionStats } from 'interface/dashboardInterface';
import { Tooltip } from '@mui/material';
import { UsDollarFormatter } from 'utils/helper';

interface IStatsCardProps {
  subscriptionStats: ISubscriptionStats | null;
  lifeTimeStats: LifetimeStats[] | null;
  transactionStats: LifetimeSubscriptionStatsData[] | null;
}
export const approvedTransactionStatus = [
  transactionStatus.captured,
  transactionStatus.authorized,
];

export default function StatsCards({
  subscriptionStats,
  lifeTimeStats,
  transactionStats,
}: IStatsCardProps) {
  const cardStats = useMemo(() => {
    let totalSubscriptionRevenue = 0;
    let totalStraightSaleRevenue = 0;
    if (!lifeTimeStats) {
      return {
        totalStraightSaleRevenue: 0,
        totalSubscriptionRevenue: 0,
        ordersApproved: 0,
        subcriptionsOrderApproved: 0,
      };
    }
    lifeTimeStats?.forEach(stat => {
      if (stat.Kind === chargeType.subscription) {
        totalSubscriptionRevenue += stat.GrossRevenue;
        return;
      }
      totalStraightSaleRevenue += stat.GrossRevenue;
    });
    return {
      totalStraightSaleRevenue,
      totalSubscriptionRevenue,
    };
  }, [lifeTimeStats]);

  const ordersStats = useMemo(() => {
    let ordersApproved = 0;
    let subcriptionsOrderApproved = 0;
    if (!transactionStats) {
      return {
        ordersApproved: 0,
        subcriptionsOrderApproved: 0,
      };
    }
    transactionStats.forEach(stat => {
      if (stat.Kind === chargeType.subscription) {
        return (subcriptionsOrderApproved += stat.Approved);
      }
      return (ordersApproved += stat.Approved);
    });
    return {
      ordersApproved,
      subcriptionsOrderApproved,
    };
  }, [transactionStats]);
  return (
    <div className="statistics_cards row-full">
      <div className="card-wrapper">
        <p className="date">Lifetime</p>
        <div className="cards">
          <div className="card">
            <div className="icon_wrapper subs_revenue">
              <SubscriptionRevenue />
            </div>
            <div className="content_wrapper">
              <p className="label">Subscription Revenue</p>
              <p className="sub_text">
                ${UsDollarFormatter(cardStats.totalSubscriptionRevenue)}
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
          <div className="card">
            <div className="icon_wrapper str_sale ">
              <StraightSaleRevenue />
            </div>
            <div className="content_wrapper">
              <p className="label">Straight Sale Revenue</p>
              <p className="sub_text">
                ${UsDollarFormatter(cardStats?.totalStraightSaleRevenue)}
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
          <Tooltip
            placement="bottom"
            PopperProps={{ className: 'cycle-tooltip' }}
            title={
              <div className="cycle-wrapper">
                <div className="cycle divider">
                  <p className="title">Active Subscribers</p>
                  <p className="title">
                    {
                      subscriptionStats?.LifetimeSubscriptionStats
                        ?.ActiveSubscriptions
                    }
                  </p>
                </div>
                <div className="cycle divider">
                  <p className="title">Subscribers in Salvage</p>
                  <p className="title">
                    {' '}
                    {subscriptionStats?.LifetimeSubscriptionStats?.InSalvage}
                  </p>
                </div>
                <div className="cycle">
                  <p className="title">Cancelled Subscribers</p>
                  <p className="title">
                    {' '}
                    {
                      subscriptionStats?.LifetimeSubscriptionStats
                        ?.CanceledSubscriptions
                    }
                  </p>
                </div>
              </div>
            }>
            <div className="card">
              <div className="icon_wrapper active_subs">
                <ActiveSubscribers />
              </div>
              <div className="content_wrapper">
                <p className="label">Active Subscribers</p>
                <p className="sub_text">
                  {
                    subscriptionStats?.LifetimeSubscriptionStats
                      ?.ActiveSubscriptions
                  }{' '}
                </p>
                <div className="helper_text"></div>
              </div>
            </div>
          </Tooltip>

          <div className="card">
            <div className="icon_wrapper orders">
              <BoxIcon className="order-box" />
            </div>
            <div className="content_wrapper">
              <p className="label">Orders</p>
              <p className="sub_text"> {ordersStats?.ordersApproved}</p>
              <div className="helper_text"></div>
            </div>
          </div>
          <div className="card">
            <div className="icon_wrapper subs_orders">
              <BoxIcon className="order-box" />
            </div>
            <div className="content_wrapper">
              <p className="label">Subscription Orders</p>
              <p className="sub_text">
                {' '}
                {ordersStats.subcriptionsOrderApproved}
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
