import {
  PaddingProps,
  paddingFields,
} from '../../../../page-builder/puck/reusable-props/padding';
import './styles.scss';
import { ComponentConfig, DropZone } from '@measured/puck';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import {
  GapProps,
  gapField,
} from '../../../../page-builder/puck/reusable-props/gap';
import {
  marginFields,
  MarginProps,
} from '../../../../page-builder/puck/reusable-props/margin';
import CustomTooltip from '../../../../components/common/tooltip';

export type ColumnsProps = {
  distribution: 'auto' | 'manual';
  backgroundColor: string;
  columns: {
    span?: number;
  }[];
  Direction: 'column' | 'column-reverse';
} & PaddingProps &
  GapProps &
  MarginProps;

export const Columns: ComponentConfig<ColumnsProps> = {
  label: (
    <CustomTooltip title="Columns">
      <span>Columns</span>
    </CustomTooltip>
  ) as React.ReactNode as string,
  fields: {
    distribution: {
      label: 'Distribution',
      type: 'radio',
      options: [
        {
          value: 'auto',
          label: 'Auto',
        },
        {
          value: 'manual',
          label: 'Manual',
        },
      ],
    },
    columns: {
      label: 'Columns',
      type: 'array',
      getItemSummary: (col, id) =>
        `Column ${Number(id) + 1}, span ${
          col.span ? Math.max(Math.min(col.span, 12), 1) : 'auto'
        }`,
      arrayFields: {
        span: {
          label: 'Span (1-12)',
          type: 'number',
          min: 0,
          max: 12,
        },
      },
    },
    ...gapField,
    Direction: {
      type: 'radio',
      options: [
        { label: 'Straight', value: 'column' },
        { label: 'Reverse', value: 'column-reverse' },
      ],
    },
    backgroundColor: {
      type: 'custom',
      label: 'Background Color',
      render: ({ value, onChange }) => (
        <FieldLabel
          label={
            (
              <CustomTooltip title={'backgroundColor'}>
                <span>Background Color</span>
              </CustomTooltip>
            ) as React.ReactNode as string
          }>
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
    ...paddingFields,
    ...marginFields,
  },
  defaultProps: {
    gap: 'gap-2',
    distribution: 'auto',
    columns: [{}, {}],
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    Direction: 'column',
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    backgroundColor: 'white',
  },
  render: ({
    columns,
    distribution,
    Direction,
    padding,
    gap,
    margin,
    backgroundColor,
  }) => {
    return (
      <div
        className={`Columns relative ${gap} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right} ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}
        style={{
          gridTemplateColumns:
            distribution === 'manual'
              ? 'repeat(12, 1fr)'
              : `repeat(${columns.length}, 1fr)`,
          flexDirection: Direction,
          backgroundColor,
        }}>
        {columns.map(({ span }, idx) => (
          <div
            key={idx}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 'fit-content',
              gridColumn:
                span && distribution === 'manual'
                  ? `span ${Math.max(Math.min(span, 12), 1)}`
                  : '',
            }}>
            <DropZone zone={`column-${idx}`} />
          </div>
        ))}
      </div>
    );
  },
};
