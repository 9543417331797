import {
  Plugin,
  TooltipCallbacks,
  Chart,
  ChartType,
  TooltipModel,
  BarControllerDatasetOptions,
  ChartDatasetProperties,
  TooltipItem,
  ChartDataset,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { UnionToIntersection } from 'chart.js/dist/types/utils';
import { Ratio } from 'components/dashboard/ApprovalRatio';
import formatNumber from 'utils/helper';
const maxBarThickness = 18;

type CustomBarDataset = _DeepPartialObject<
  {
    type: 'bar';
    amount: (number | [number, number] | null)[];
    rawData?: Ratio[];
  } & BarControllerDatasetOptions
> &
  ChartDatasetProperties<'bar', (number | [number, number] | null)[]>;

export interface IDatasets extends UnionToIntersection<ChartDataset<'bar'>> {
  data: number[];
  count: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

const externalTooltipHandlers = (context: {
  chart: Chart;
  tooltip: TooltipModel<'doughnut' | 'bar'>;
}) => {
  const { chart, tooltip } = context;

  let tooltipEl = chart?.canvas?.parentNode?.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('tooltip-container');
    const tooltipElement = document.createElement('div');
    tooltipElement.classList.add('tooltip-wrapper');
    tooltipEl.appendChild(tooltipElement);
    chart?.canvas?.parentNode?.appendChild(tooltipEl);
  }

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  if (tooltip?.body) {
    const isTooltipBelow = tooltip.caretY > chart.height / 2;
    if (tooltip.caretX < chart.width / 2) {
      tooltipEl.className = `tooltip-container barChart pointer-left ${isTooltipBelow && 'pointer-bottom'}`;
      tooltipEl.style.transform = `translate(8px, ${isTooltipBelow ? 'calc(-100% + 20px)' : '0'})`;
    } else {
      tooltipEl.className = `tooltip-container barChart pointer-right ${isTooltipBelow && 'pointer-bottom'}`;
      tooltipEl.style.transform = `translate(calc(-100% - 8px), ${isTooltipBelow ? 'calc(-100% + 20px)' : '0'})`;
    }

    const tableBody = document.createElement('div');

    tooltip.body.forEach((body: { lines: string[] }) => {
      const tooltipRow = document.createElement('div');
      tooltipRow.classList.add('tooltip-row');
      const tooltipData = body?.lines?.[0].split(' ');
      tooltipRow.innerHTML = `<div class='tooltip-row-box' > ${tooltip.title}  ${tooltipData?.[1] ? `<div><span class='tooltip-row-count'>${tooltipData?.[1]}</span>` : ''}
      ${tooltipData?.[0] ? `<span class='tooltip-row-percentage'>(${tooltipData?.[0]})</span>` : ''}</div></div>`;
      tableBody.appendChild(tooltipRow);
    });

    const tableRoot = tooltipEl?.querySelector('.tooltip-wrapper');
    while (tableRoot?.firstChild) {
      tableRoot?.firstChild.remove();
    }
    tableRoot?.appendChild(tableBody);
  }
  const { offsetLeft, offsetTop } = chart.canvas;
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = offsetLeft + tooltip.caretX + 'px';
  tooltipEl.style.top = offsetTop + tooltip.caretY + 'px';
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

const tooltip = ({
  prefix = '',
  suffix = '',
  hasPercentageValue = false,
  isPercentagedata = false,
  customPrefixSuffix,
  isApprovalRatio,
}: {
  prefix?: string;
  suffix?: string;
  hasPercentageValue?: boolean;
  isPercentagedata?: boolean;
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
  isApprovalRatio?: boolean;
} = {}) => {
  return {
    mode: 'index' as const,
    position: 'nearest' as const,
    intersect: false,
    padding: 8,
    callbacks: {
      title: () => '',
      label: context => {
        const dataSets = context?.chart?.config?.data?.datasets;
        if (hasPercentageValue && dataSets?.length > 0) {
          let percentage: string = '0';
          if (dataSets.length > 0) {
            const data = dataSets.reduce((value, eachValue) => {
              const eachData = eachValue?.data?.[context?.dataIndex] || 0;
              if (typeof eachData === 'number') {
                return value + eachData;
              }
              return value;
            }, 0);
            percentage =
              (+context.formattedValue / +data) * 100
                ? Number((+context.formattedValue / +data) * 100).toFixed(2)
                : '0';
          }

          return `${context.dataset.label + ': ' + prefix + context.formattedValue + suffix + '/' + percentage + '%'}`;
        }

        if (isApprovalRatio && dataSets?.length > 0) {
          const currentDataset: CustomBarDataset = context.dataset;
          return `${currentDataset?.rawData ? currentDataset?.rawData[context?.dataIndex].tooltip : ''}: `;
        }
        if (isPercentagedata && dataSets?.length > 0) {
          const label = context.dataset.label || '';
          const currentDataset: CustomBarDataset = context.dataset;
          const value =
            (currentDataset?.amount
              ? currentDataset?.amount[context?.dataIndex]
              : currentDataset?.data[context?.dataIndex]) || 0;
          return `${label + ' : ' + prefix + value + suffix + '/' + context.raw + '%'}`;
        }
        if (customPrefixSuffix && dataSets?.length > 0) {
          const label = context.dataset.label || '';
          const value = context.dataset.data[context?.dataIndex] || 0;
          let pre = '';
          let suf = '';
          if (customPrefixSuffix?.[label]) {
            pre = customPrefixSuffix?.[label].prefix ?? '';
            suf = customPrefixSuffix?.[label].suffix ?? '';
          }
          return `${pre + value.toLocaleString() + suf}`;
        }
        if (customPrefixSuffix && dataSets?.length > 0) {
          const label = context.dataset.label || '';
          const value = context.dataset.data[context?.dataIndex] || 0;
          let pre = '';
          let suf = '';
          if (customPrefixSuffix?.[label]) {
            pre = customPrefixSuffix?.[label].prefix ?? '';
            suf = customPrefixSuffix?.[label].suffix ?? '';
          }
          return `${pre + value.toLocaleString() + suf}`;
        }
        const label = context.dataset.label || '';
        const value = context.parsed.y || 0;
        const labelText = `${label}: ${prefix + formatNumber(value) + suffix}`;
        return labelText;
      },
      beforeTitle: () => '',
      afterTitle: () => '',
      beforeBody: () => '',
      afterBody: () => '',
      beforeLabel: () => '',
      afterLabel: () => '',
      labelTextColor: () => '',
      beforeFooter: () => '',
      footer: () => '',
      afterFooter: () => '',
      labelColor: () => {},
      labelPointStyle: () => {},
    } as TooltipCallbacks<'bar'>,
    displayColors: false,
    bodyColor: '#A3AED0',
    bodyFont: {
      size: 14,
      family: 'work-sans',
      weight: 500,
    },
    bodySpacing: 8,
    boxPadding: 4,
    backgroundColor: '#141E2D',
    borderColor: '#192739',
    borderWidth: 2,
    enabled: false,
    external: externalTooltipHandler,
  };
};

export const groupedBarChartoptions = ({
  prefix,
  suffix,
  hasPercentageValue,
  isPercentagedata,
  customPrefixSuffix,
  isApprovalRatio,
}: {
  prefix: string;
  suffix: string;
  hasPercentageValue: boolean;
  isPercentagedata: boolean;
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
  isApprovalRatio?: boolean;
}) => {
  return {
    interaction: {
      mode: 'nearest' as const,
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    barPercentage: 0.6,
    categoryPercentage: 0.5,
    maxBarThickness,
    borderRadius: 20,
    opacity: 1,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 5,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: number | string) => {
            return `${prefix + formatNumber(Number(value)) + suffix}`;
          },
          align: 'end' as const,
          padding: 8,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          color: '#a3aed026',
          drawTicks: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: tooltip({
        prefix,
        suffix,
        hasPercentageValue,
        isPercentagedata,
        customPrefixSuffix,
        isApprovalRatio,
      }),
    },
  };
};

export const stackedBarChart = ({ prefix = '', suffix = '' } = {}) => {
  return {
    interaction: {
      mode: 'nearest' as const,
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    barPercentage: 0.5,
    categoryPercentage: 0.8,
    borderRadius: {
      topLeft: 20,
      topRight: 20,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: number | string) => {
            return `${prefix + formatNumber(Number(value)) + suffix}`;
          },
          align: 'end' as const,
          padding: 8,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          color: '#a3aed026',
          drawTicks: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      backgroundBar: {
        barColor: '#A3AED026',
      },
      tooltip: {
        ...tooltip({
          isPercentagedata: true,
          prefix: '$',
        }),
        mode: 'index' as const,
      },
    },
  };
};

export const doughnutBarChart = () => {
  return {
    borderWidth: 1,
    cutoutPercentage: 60,
    responsive: true,
    maintainAspectRatio: false,
    hoverOffset: 10,
    layout: {
      padding: {
        top: 20,
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        boxPadding: 4,
        backgroundColor: '#141E2D',
        borderColor: '#192739',
        borderWidth: 2,
        enabled: false,
        external: function (
          this: TooltipModel<'doughnut'>,
          context: {
            chart: Chart;
            tooltip: TooltipModel<'doughnut'>;
          },
        ) {
          externalTooltipHandlers(context);
        },
        callbacks: {
          label: function (context: TooltipItem<'doughnut'>) {
            const label = context?.dataset?.label || '';
            const count = (context?.dataset as IDatasets)?.count?.[
              context?.dataIndex
            ];
            const value = context?.raw || '';
            return `${label}${count} ${value}%`;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
};
const externalTooltipHandler = (context: {
  chart: Chart;
  tooltip: TooltipModel<ChartType>;
}) => {
  const { chart, tooltip } = context;
  let tooltipEl = chart?.canvas?.parentNode?.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('tooltip-container');
    const tooltipElement = document.createElement('div');
    tooltipElement.classList.add('tooltip-wrapper');
    tooltipEl.appendChild(tooltipElement);
    chart?.canvas?.parentNode?.appendChild(tooltipEl);
  }

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  if (tooltip?.body) {
    const isTooltipBelow = tooltip?.caretY > chart?.height / 2;
    if (tooltip.caretX < chart.width / 2) {
      tooltipEl.className = `tooltip-container barChart pointer-left ${isTooltipBelow && 'pointer-bottom'}`;
      tooltipEl.style.transform = `translate(8px, ${isTooltipBelow ? 'calc(-100% + 20px)' : '0'})`;
    } else {
      tooltipEl.className = `tooltip-container barChart pointer-right ${isTooltipBelow && 'pointer-bottom'}`;
      tooltipEl.style.transform = `translate(calc(-100% - 8px), ${isTooltipBelow ? 'calc(-100% + 20px)' : '0'})`;
    }

    const tableBody = document.createElement('div');
    tooltip.body.forEach((body: { lines: string[] }, index: number) => {
      const tooltipRow = document.createElement('div');
      tooltipRow.classList.add('tooltip-row');
      if (tooltip?.labelColors?.[index].borderColor) {
        tooltipRow.style.color = `${tooltip?.labelColors?.[index].borderColor}`;
      }
      const tooltipData = body?.lines?.[0]?.split(':');
      tooltipRow.innerHTML = `${tooltipData?.[0] ? `<span>${tooltipData?.[0]}</span>` : ''}
      ${tooltipData?.[1] ? `<span class='tooltip-data'>${tooltipData?.[1]}</span>` : ''}`;
      tableBody.appendChild(tooltipRow);
    });

    const tableRoot = tooltipEl?.querySelector('.tooltip-wrapper');
    while (tableRoot?.firstChild) {
      tableRoot?.firstChild.remove();
    }
    tableRoot?.appendChild(tableBody);
  }
  const { offsetLeft, offsetTop } = chart.canvas;
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = offsetLeft + tooltip.caretX + 'px';
  tooltipEl.style.top = offsetTop + tooltip.caretY + 'px';
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
export const horizontalBarChart = () => {
  return {
    responsive: true,
    indexAxis: 'y' as const,
    borderRadius: 20,
    scales: {
      x: {
        grid: {
          color: '#a3aed026',
          drawTicks: false,
        },
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
          callback: (value: number | string) => `${value}%`,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        beginAtZero: true,
        ticks: {
          display: false,
          stepSize: 1,
          maxTicksLimit: 5,
          minTicksLimit: 5,
        },
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        boxPadding: 4,
        backgroundColor: '#141E2D',
        borderColor: '#192739',
        borderWidth: 2,
        enabled: false,
        external: externalTooltipHandlers,
        callbacks: {
          label: function (context: TooltipItem<'bar'>) {
            const label = context?.dataset?.label || '';
            const count = (context?.dataset as IDatasets)?.count[
              context?.dataIndex
            ];
            const value = context?.raw || '';
            return `${label}${count} ${value}%`;
          },
        },
      },
    },
  };
};
export const overlapedBarChart = ({
  prefix = '',
  suffix = '',
  labelPrefix = '',
  labelSuffix = '',
  hasPercentageValue,
  isPercentagedata,
  customPrefixSuffix,
}: {
  prefix: string;
  suffix: string;
  labelPrefix: string;
  labelSuffix: string;
  hasPercentageValue: boolean;
  isPercentagedata: boolean;
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
}) => {
  return {
    datasets: {
      bar: {
        barPercentage: 2,
        categoryPercentage: 0.5,
      },
    },
    interaction: {
      mode: 'nearest' as const,
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    borderRadius: {
      topLeft: 4,
      topRight: 4,
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: number | string) => {
            return `${labelPrefix + formatNumber(Number(value)) + labelSuffix}`;
          },
          align: 'end' as const,
          padding: 8,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          color: '#a3aed026',
          drawTicks: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        ...tooltip({
          prefix,
          suffix,
          hasPercentageValue,
          isPercentagedata,
          customPrefixSuffix,
        }),
      },
    },
  };
};

export const backgroundBarPlugin: Plugin<'bar'> = {
  id: 'backgroundBar',
  beforeDatasetsDraw: (chart, args, options) => {
    const {
      data,
      ctx,
      chartArea: { top, width, height },
      scales: { x },
    } = chart;
    ctx.beginPath();
    const segment = width / (data.labels?.length as number);
    const barWidth =
      segment *
      stackedBarChart().barPercentage *
      stackedBarChart().categoryPercentage;
    ctx.fillStyle = options.barColor;
    data.labels?.forEach((_, i) => {
      ctx.roundRect(
        x.getPixelForValue(i) - barWidth / 2,
        top,
        barWidth,
        height,
        [20, 20, 0, 0],
      );
    });
    ctx.fill();
  },
};
