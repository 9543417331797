import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import './style.scss';
import { Loader } from 'components/common/loader';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import { ISubscriptionPlan } from 'interface/settingsInterface';
import { get } from 'lodash';
import storeService from 'services/storeService';
import AddSubscriptionPlan from 'components/Actions/addSubscriptionPlan';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';
import membershipPlanService from 'services/membershipPlanService';
import { showNotification } from 'helper/common/commonFunctions';
import { subscription_frequency, subscription_trial_unit } from 'utils/helper';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: ISubscriptionPlan) => ReactNode;
  hide?: boolean;
}
interface SubscriptionPlanProps {
  storeId?: string;
}

export default function SubscriptionPlan({ storeId }: SubscriptionPlanProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    ISubscriptionPlan[]
  >([]);

  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);

  const defaultSubscription = {
    Amount: '',
    ChannelID: '',
    Description: '',
    FreeTrialLength: 0,
    FreeTrialUnit: '',
    Frequency: '',
    Interval: 0,
    Name: '',
    StoreID: storeId ? storeId : '',
  };

  const [selected, setSelected] =
    useState<ISubscriptionPlan>(defaultSubscription);

  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Name',
      hide: false,
      cellRender: row => {
        return <>{row?.Name}</>;
      },
    },
    {
      id: 'Frequency',
      label: 'Frequency of subscription',
      hide: false,
      cellRender: row => {
        return (
          <>
            {row?.Frequency
              ? subscription_frequency.find(
                  value => value.value === row.Frequency,
                )?.label
              : row?.Frequency}
          </>
        );
      },
    },
    {
      id: 'CheckoutURL',
      label: 'Trial period',
      hide: false,
      cellRender: row => {
        return (
          <>
            {row?.FreeTrialLength}{' '}
            {
              subscription_trial_unit?.find(
                value => value.value === row?.FreeTrialUnit,
              )?.label
            }
          </>
        );
      },
    },
    {
      id: 'StoreDescription',
      label: 'Frequency Interval',
      hide: false,
      cellRender: row => {
        return <>{row?.Interval}</>;
      },
    },
    {
      id: 'StoreDescription',
      label: 'Price of subscriptions',
      hide: false,
      cellRender: row => {
        return <>{row?.Amount}</>;
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <div className="flex items-center justify-end gap-3">
            <DeleteIcon
              className="h-15 cursor-pointer"
              onClick={() => {
                setIsDeletePopup(true);
                setSelected(row);
              }}
            />
            <CreateIcon
              className="cursor-pointer"
              onClick={() => {
                setShowPopUp(true);
                setSelected(row);
              }}
            />
          </div>
        );
      },
    },
  ];

  const getSubscriptions = async () => {
    if (!storeId) return;
    setLoading(true);
    const res = await storeService.getSubscriptionPlans(storeId);
    setLoading(false);
    if (res?.data && res?.data?.Result) {
      setSubscriptionPlans(res?.data?.Result);
    } else {
      setSubscriptionPlans([]);
    }
  };

  const removeDubscriptionPlan = async () => {
    if (!selected?.ID || !storeId) {
      setIsDeletePopup(false);
      return;
    }
    setLoading(true);
    const res = await membershipPlanService.deleteMembershipPlan(
      storeId,
      selected.ID,
    );
    setLoading(false);
    if (res?.status === 200 || res?.status === 204) {
      getSubscriptions();
      showNotification('success', 'Subscription Plan Deleted Successfully');
    }
    setIsDeletePopup(false);
  };

  useEffect(() => {
    getSubscriptions();
  }, [storeId]);

  return (
    <>
      <div className="h-full">
        <div className="h-full">
          <TableContainer className="Common_Table settingsTable !h-fit max-h-full min-h-half">
            <Table aria-labelledby="tableTitle" className="table" stickyHeader>
              <TableHead>
                <TableRow>
                  {headCellList.map(headCell => {
                    if (headCell.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        className={
                          headCell.label == 'Actions' ? '!text-right' : ''
                        }
                        key={headCell.label}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <>
                <TableBody className="table-body">
                  {subscriptionPlans.length > 0 ? (
                    subscriptionPlans.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}>
                          {headCellList.map(headCell => {
                            if (headCell.hide) {
                              return null;
                            }
                            return (
                              <TableCell
                                className="table_cell"
                                key={headCell.label}
                                component="th"
                                id={headCell.id}
                                scope="row">
                                <>
                                  {headCell?.cellRender
                                    ? headCell.cellRender(row)
                                    : get(row, headCell.id)}
                                </>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={15} className="!h-40">
                        <div className="no-data-row">No data found</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {!subscriptionPlans.length && (
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={10}>
                        <button
                          className="sticky-button"
                          onClick={() => {
                            setShowPopUp(true);
                            setSelected(defaultSubscription);
                          }}>
                          <AddIcon /> Add Subscription Plan
                        </button>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                )}
              </>
            </Table>
          </TableContainer>
        </div>
        <Loader loading={loading} />
        {showPopUp && (
          <AddSubscriptionPlan
            defaultStoreValues={selected}
            handleClose={() => setShowPopUp(false)}
            onSuccess={getSubscriptions}
          />
        )}
        <ConfirmationDialog
          open={isDeletePopup}
          handleClose={() => {
            setIsDeletePopup(false);
          }}
          message="Delete Subscription Plan"
          onSubmit={() => {
            removeDubscriptionPlan();
          }}
        />
      </div>
    </>
  );
}
