/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { InputHTMLAttributes, useMemo } from 'react';
import { ReactComponent as ActionIcon } from 'assets/icons/Support.svg';
import ActionFilter from 'components/customers/customerDetails/customerDetailsFilter/actionFilter';
import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';
import { ICustomerDetails } from 'interface/customerInterface';

interface ISideFilter extends InputHTMLAttributes<HTMLInputElement> {
  customer: ICustomerDetails | null;
  refetch: () => void;
}

const SideFilter: React.FC<ISideFilter> = ({ customer, refetch }) => {
  const dispatch = useAppDispatch();
  const { currentFilter, filterSource } = useAppSelector(
    state => state.pathConfig,
  );
  const filterAction = [{ key: 'Action', icon: ActionIcon }];

  const setSelectedFilter = (filter: string) => {
    dispatch(
      setPageConfig({ filterSource: 'customerDetails', currentFilter: filter }),
    );
  };

  const getFilterAction = useMemo(() => {
    const { Status: status } = customer?.Subscription || {};
    let list: string[] = [];
    switch (status) {
      case 'canceled':
        list = ['Change Subscription'];
        break;
      case 'active':
      case 'salvage':
        list = [
          'Change Subscription',
          'Change Next Billing Date',
          'Change Subscription Value',
          'Change Subscription Frequency',
        ];
        break;
      case 'paused':
        list = ['Change Subscription'];
        break;
      default:
        list = [];
    }
    return customer?.IsBlacklisted ? list : [...list, 'Blacklist customer'];
  }, [customer]);

  const renderComponent = () => {
    return (
      <ActionFilter
        filterList={[...getFilterAction]}
        customer={customer}
        refetch={refetch}
      />
    );
  };

  return (
    <div className="side_filter_container">
      {filterAction.map((item, index) => (
        <div
          className={`transaction_side_filtericon ${currentFilter === item.key ? 'active' : ''} ${'' || ''}`}
          key={index}
          onClick={() => setSelectedFilter(item.key)}>
          <item.icon />
        </div>
      ))}
      {!!currentFilter && filterSource === 'customerDetails' && (
        <div className="filter_selected_wrapper">
          <div className="filter_header">
            <p className="filter_section_Header">{currentFilter}</p>
            <CloseIcon
              className="close_ison"
              onClick={() => dispatch(setCurrentFilter(''))}
            />
          </div>
          <div className="filter_container">{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default SideFilter;
