import { useMemo, FC } from 'react';
import { TransactionStats } from 'interface/dashboardInterface';
import { cardBrand, transactionStatus } from 'components/dashboard/constant';

interface IProps {
  groupedTransactionStats: [string, TransactionStats[]][];
}
const ChargeBackSummary: FC<IProps> = ({ groupedTransactionStats }) => {
  const chargebackSummary = useMemo(() => {
    const chargeOption = groupedTransactionStats.reduce(
      (prev, [, statsData]) => {
        statsData.forEach(stat => {
          const card_brand = stat.CardBrand.toLowerCase();
          if (
            [transactionStatus.captured, transactionStatus.authorized].includes(
              stat.Status,
            )
          ) {
            if (card_brand === cardBrand.master) {
              prev.mcApprovedTransactions += stat.Count;
            } else if (card_brand === cardBrand.visa) {
              prev.visaApprovedTransactions += stat.Count;
            }
            prev.overallApprovedTransactions += stat.Count;
          } else if (stat.Status === transactionStatus.chargeback) {
            if (card_brand === cardBrand.master) {
              prev.mcChargebacks += stat.Count;
            } else if (card_brand === cardBrand.visa) {
              prev.visaChargebacks += stat.Count;
            }
            prev.overallChargebacks += stat.Count;
          }
        });
        return prev;
      },
      {
        overallApprovedTransactions: 0,
        overallChargebacks: 0,
        visaApprovedTransactions: 0,
        visaChargebacks: 0,
        mcApprovedTransactions: 0,
        mcChargebacks: 0,
        mcChargebackRatio: '0',
        visaChargebackRatio: '0',
        overallChargebackRatio: '0',
      },
    );
    chargeOption.mcChargebackRatio = chargeOption.mcChargebacks
      ? (
          (chargeOption.mcChargebacks * 100) /
          chargeOption.mcApprovedTransactions
        ).toFixed(2)
      : '0';
    chargeOption.overallChargebackRatio = chargeOption.overallChargebacks
      ? (
          (chargeOption.overallChargebacks * 100) /
          chargeOption.overallApprovedTransactions
        ).toFixed(2)
      : '0';
    chargeOption.visaChargebackRatio = chargeOption.visaChargebacks
      ? (
          (chargeOption.visaChargebacks * 100) /
          chargeOption.visaApprovedTransactions
        ).toFixed(2)
      : '0';
    return chargeOption;
  }, [groupedTransactionStats]);

  return (
    <div className="md_card_container row-span-2">
      <div className="header">
        <p className="header_text extend_sm">Chargeback Summary</p>
      </div>
      <div className="chargeback_summary">
        <div className="content_wrapper">
          <p className="label">Overall Approved Transactions</p>
          <p className="value">
            {chargebackSummary?.overallApprovedTransactions}
          </p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">Overall Chargebacks</p>
          <p className="value">{chargebackSummary?.overallChargebacks}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">Overall Chargeback Ratio</p>
          <p className="value">
            {chargebackSummary?.overallChargebackRatio}
            {chargebackSummary?.overallChargebackRatio !== '0' ? '%' : ''}
          </p>
        </div>
        <hr className="divider" />
        <div className="content_wrapper">
          <p className="label">VISA Approved Transactions</p>
          <p className="value">{chargebackSummary?.visaApprovedTransactions}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">VISA Chargebacks</p>
          <p className="value">{chargebackSummary?.visaChargebacks}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">VISA Chargeback Ratio</p>
          <p className="value">
            {chargebackSummary?.visaChargebackRatio}
            {chargebackSummary?.visaChargebackRatio !== '0' ? '%' : ''}
          </p>
        </div>
        <hr className="divider" />
        <div className="content_wrapper">
          <p className="label">MC Approved Transactions</p>
          <p className="value">{chargebackSummary?.mcApprovedTransactions}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">MC Chargebacks</p>
          <p className="value">{chargebackSummary?.mcChargebacks}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">MC Chargeback Ratio</p>
          <p className="value">
            {chargebackSummary?.mcChargebackRatio}
            {chargebackSummary?.mcChargebackRatio !== '0' ? '%' : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChargeBackSummary;
