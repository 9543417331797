import { useState, useEffect } from 'react';
import { ComponentConfig } from '@measured/puck';
import { DropZone } from '@measured/puck';
import { CartTotal } from '../../../../page-builder/components/cart/cart-total/CartTotal';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import {
  PaddingProps,
  paddingFields,
} from '../../../puck/reusable-props/padding';
import './styles.scss';
import { ReduxSelectorProps } from '../../../services/constants';
import CustomTooltip from '../../../../components/common/tooltip';
import InputField from '../../../../components/common/input-field';

export type SplitLayoutProps = PaddingProps & {
  border: boolean;
  bgColor: string;
  bgColorLeft: string;
  bgColorRight: string;
  borderColor: string;
  orderSummaryText: string;
  orderSummaryTextColor: string;
  orderSummaryCartTotal: boolean;
};

export const SplitLayout = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<SplitLayoutProps> => {
  return {
    label: (
      <CustomTooltip title="SplitLayout">
        <span>Simple Split Layout</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...paddingFields,
      border: {
        type: 'radio',
        label: 'Border',
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      borderColor: {
        type: 'custom',
        label: 'Border Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Border Color">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      bgColor: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Background Color">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      bgColorLeft: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Background Color(Left Column)">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      bgColorRight: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Background Color(Right Column)">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      orderSummaryText: {
        type: 'custom',
        label: 'Order Summary Text',
        render: ({ value, onChange, field }) => (
          <InputField value={value} field={field} onChange={onChange} />
        ),
      },
      orderSummaryTextColor: {
        type: 'custom',
        label: 'Summary Text Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Text Color">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      orderSummaryCartTotal: {
        type: 'radio',
        label: 'Mobile Summary Cart Total',
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
    },
    defaultProps: {
      border: true,
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      borderColor: '#E5E5E5',
      bgColor: '#F9F9F9',
      bgColorLeft: '#F9F9F9',
      bgColorRight: '#F9F9F9',
      orderSummaryCartTotal: true,
      orderSummaryTextColor: '#000',
      orderSummaryText: 'Show order summary',
    },
    render: ({
      border,
      bgColorLeft,
      bgColorRight,
      bgColor,
      padding,
      borderColor,
      orderSummaryCartTotal,
      orderSummaryText,
      orderSummaryTextColor,
    }) => {
      const [dropDown, setDropDown] = useState(false);
      const [total, setTotal] = useState(true);
      const toggleDropZone = () => setDropDown(!dropDown);
      useEffect(() => {
        const handleResize = () => {
          setDropDown(window.innerWidth >= 1024);
          setTotal(window.innerWidth <= 1024);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      return (
        <>
          <div
            className={`split-layout-grid ${border ? 'lg:border-t-2' : ''} sm:max-w-8xl lg:max-w-6xl xl:max-w-6xl pb-14 lg:px-8 mx-auto gap-x-8 ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
          ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
          ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}`}
            style={{
              borderColor: borderColor,
              backgroundColor: bgColor,
            }}>
            <div
              className={`left-split-layout mx-auto lg:max-w-none split-layout-child px-4`}
              style={{ width: '50%', backgroundColor: bgColorLeft }}>
              <DropZone zone="left" />
            </div>
            <div
              className={`right-split-layout split-layout-child ${border ? 'lg-max:border-l-0 border-l-2' : ''} px-4`}
              style={{
                backgroundColor: bgColorRight,
                width: '50%',
              }}>
              <div className="flex justify-between">
                <button
                  type="button"
                  className="flex items-center display-on-mobile-only"
                  style={{ color: orderSummaryTextColor }}
                  onClick={toggleDropZone}>
                  {orderSummaryText}
                  <ChevronDownIcon
                    className={`h-5 w-5 ml-2 transition-transform duration-200 ${
                      dropDown ? 'transform rotate-180' : ''
                    }`}
                    aria-hidden="true"
                  />
                </button>
                {total && orderSummaryCartTotal && (
                  <CartTotal useAppSelector={useAppSelector} />
                )}
              </div>
              {dropDown && <DropZone zone="right" />}
            </div>
          </div>
        </>
      );
    },
  };
};
