import { useMemo, FC, useState } from 'react';
import { TransactionStats } from 'interface/dashboardInterface';
import { GroupedBarChart } from 'components/common/chart/barChart';
import { cardBrand, disputeAlert } from 'components/dashboard/constant';
import { percentage } from 'utils/helper';
import ButtonGroup from 'components/common/ButtonGroup';

interface IProps {
  groupedTransactionStats: [string, TransactionStats[]][];
}

interface IDatasets {
  overall: number[];
  cb: number[];
  rdr: number[];
  ethoca: number[];
}

const cardTypesFilter = {
  all: 'All',
  visa: 'VISA',
  masterCard: 'Master Card',
};
const filterButtons = Object.values(cardTypesFilter).map(types => ({
  name: types,
}));

const CoverageHealth: FC<IProps> = ({ groupedTransactionStats }) => {
  const [cardType, setCardType] = useState(cardTypesFilter.all);

  const coverageHealthData = useMemo(() => {
    const values: IDatasets = {
      overall: [],
      cb: [],
      rdr: [],
      ethoca: [],
    };
    let allTransactionTotal = 0;
    let overallTotal = 0;
    let cbTotal = 0;
    let rdrTotal = 0;
    let ethocaTotal = 0;

    groupedTransactionStats.forEach(([, statsData]) => {
      // TODO: Implement groupby based on DisputeAlerts
      let overall = 0;
      let cb = 0;
      let rdr = 0;
      let ethoca = 0;
      statsData.forEach(stat => {
        const card_brand = stat.CardBrand.toLowerCase();
        if (
          (cardType === cardTypesFilter.visa &&
            card_brand !== cardBrand.visa) ||
          (cardType === cardTypesFilter.masterCard &&
            card_brand !== cardBrand.master)
        ) {
          return;
        }
        allTransactionTotal += stat.Count;
        if (stat.DisputeAlerts?.some(alert => alert)) {
          overall += stat.Count;
        }
        if (stat?.DisputeAlerts?.includes(disputeAlert.Chargeback)) {
          cb += stat.Count;
        }
        if (stat?.DisputeAlerts?.includes(disputeAlert.ETHOCA)) {
          ethoca += stat.Count;
        }
        if (stat?.DisputeAlerts?.includes(disputeAlert.RDR)) {
          rdr += stat.Count;
        }
      });
      overallTotal += overall;
      cbTotal += cb;
      rdrTotal += rdr;
      ethocaTotal += ethoca;
      values.overall.push(overall);
      values.cb.push(cb);
      values.rdr.push(rdr);
      values.ethoca.push(ethoca);
    });
    return {
      labels: groupedTransactionStats.map(([date]) => date),
      datasets: [
        {
          label: `Overall ${cardType} Dispute`,
          borderColor: '#F90182',
          backgroundColor: '#F90182',
          legend: `Overall ${cardType} Dispute`,
          data: values.overall,
          total: overallTotal,
          hide: [],
        },
        {
          label: `${cardType} CB #`,
          borderColor: '#6AD2FF',
          backgroundColor: '#6AD2FF',
          legend: `${cardType} CB`,
          data: values.cb,
          total: cbTotal,
          hide: [],
        },
        {
          label: `${cardType} RDR #`,
          borderColor: '#C237F3',
          backgroundColor: '#C237F3',
          legend: `${cardType} RDR`,
          data: values.rdr,
          total: rdrTotal,
          hide: [cardTypesFilter.masterCard],
        },
        {
          label: `${cardType} Ethoca #`,
          borderColor: '#f36337',
          backgroundColor: '#f36337',
          legend: `${cardType} ETHOCA`,
          data: values.ethoca,
          total: ethocaTotal,
          hide: [cardTypesFilter.visa],
        },
      ].filter(dataSet => !dataSet.hide.includes(cardType)),
      allTransactionTotal,
    };
  }, [cardType, groupedTransactionStats]);

  return (
    <div className="lg_card_container row-span-2">
      <div className="header">
        <p className="header_text">Coverage Health</p>
        <ButtonGroup
          active={cardType}
          setActive={setCardType}
          buttons={filterButtons}
        />
      </div>
      <div className="body">
        <div className="left_side_details">
          <div className="stats_details">
            {coverageHealthData.datasets.map(data => {
              return (
                <div key={data.label}>
                  <p className="head_text">
                    <span
                      className="indicator"
                      style={{ background: data.backgroundColor }}
                    />
                    {data.legend}
                  </p>
                  <p className="sub_text">
                    {data.total +
                      '/' +
                      percentage(
                        data.total,
                        coverageHealthData.allTransactionTotal,
                        false,
                        1,
                      ) +
                      '%'}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="chart_wrapper">
          <GroupedBarChart
            data={coverageHealthData}
            hasPercentageValue={true}
            className="chart"
          />
        </div>
      </div>
    </div>
  );
};

export default CoverageHealth;
