import { FC } from 'react';

import { IStoreFrontEventsFilter } from 'interface/storeInterface';
import { Controller, useForm } from 'react-hook-form';
import { MultipleInputTag } from 'components/common/multiInput';
import { Button } from 'shared-components';
import TextField from 'components/common/textField';
import 'components/storeFrontEvents/Filter/FilterForm/style.scss';
import dayjs from 'dayjs';
import DatePicker from 'components/common/datePicker';
import { defaultStoreFrontEventValues } from 'components/storeFrontEvents';
import AutoCompleteSelect from 'components/common/selectAutoComplete';

interface IProps {
  sideFilter: IStoreFrontEventsFilter;
  filterSubmission: (_data: IStoreFrontEventsFilter) => void;
  setPage: (_page: number) => void;
}

export const resolvedOptions = [
  { label: 'All', value: 'all' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
const FilterForm: FC<IProps> = ({ sideFilter, filterSubmission, setPage }) => {
  const methods = useForm<IStoreFrontEventsFilter>({
    defaultValues: sideFilter,
  });
  const { handleSubmit, reset, control, watch } = methods;

  const onSubmit = (data: IStoreFrontEventsFilter) => {
    filterSubmission(data);
    setPage(1);
  };
  return (
    <div className="store-front-event-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <Controller
          name="tags"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <MultipleInputTag
                tagsValue={value}
                label="Tags"
                placeholder="Type Tag"
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          name="resolved"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <AutoCompleteSelect
                options={resolvedOptions}
                placeholder="select"
                labelKey="label"
                onChange={val => onChange(val?.value || null)}
                value={
                  resolvedOptions?.find(type => type.value === value) || null
                }
                className="w-full dropdown-field"
                label="Resolved"
              />
            );
          }}
        />
        <Controller
          name="eventName"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                label="Event Name"
                placeholder="Enter Event"
                value={value}
                fixedSize="md"
                onChange={e => {
                  onChange(e.target.value);
                }}
              />
            );
          }}
        />
        <Controller
          name="ipAddress"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                label="IP Address"
                placeholder="Enter IP"
                value={value}
                fixedSize="md"
                onChange={e => {
                  onChange(e.target.value);
                }}
              />
            );
          }}
        />
        <div className="common_box">
          <p className="common_input_label">Date</p>
          <div className="flexContainer duel-datepicker">
            <Controller
              name="StartTime"
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                return (
                  <DatePicker
                    maxDate={dayjs(watch('EndTime')).tz()}
                    disableFuture={true}
                    error={error?.message}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
            <Controller
              name="EndTime"
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                return (
                  <DatePicker
                    minDate={dayjs(watch('StartTime')).tz()}
                    disableFuture={true}
                    error={error?.message}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="button_wrapper">
          <Button
            className="transaction_for_submit"
            type="submit"
            label="Apply Filter"
          />
          <Button
            variant="secondary"
            className="transaction_for_submit clear_button"
            onClick={() => {
              reset(defaultStoreFrontEventValues);
            }}
            label="Clear Filter"
          />
        </div>
      </form>
    </div>
  );
};
export default FilterForm;
