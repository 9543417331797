import { pageNames, Path, Permission } from 'router/constant';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as CustomerIcon } from 'assets/icons/customer.svg';
import { ReactComponent as OrderIcon } from 'assets/icons/order.svg';
import { ReactComponent as TransactionsIcon } from 'assets/icons/transactions.svg';
import { ReactComponent as SubscriptionIcon } from 'assets/icons/fluent_receipt-sparkles-24-filled.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/uil_setting.svg';
import { ReactComponent as ReportingIcon } from 'assets/icons/reportingIcon.svg';
import { ReactComponent as AdminIcon } from 'assets/icons/admin-icon.svg';

import { ISideNavigationItems } from 'interface/commonInterface';

export const sideNavigationItems: ISideNavigationItems[] = [
  {
    name: 'Dashboard',
    path: Path.DASHBOARD,
    key: 'dashboard',
    icon: DashboardIcon,
    permissionKey: Permission.DASHBOARD,
    pageName: pageNames.DASHBOARD,
  },
  {
    name: 'Transactions',
    path: Path.TRANSACTIONS,
    key: 'transactions',
    icon: TransactionsIcon,
    permissionKey: Permission.TRANSACTION,
    pageName: pageNames.TRANSACTION,
  },
  {
    name: 'Orders',
    path: Path.ORDERS,
    key: 'orders',
    icon: OrderIcon,
    permissionKey: Permission.ORDER,

    pageName: pageNames.ORDER,
    children: [
      {
        name: 'Abandoned Carts',
        path: Path.ABANDONED_CARTS,
        key: 'abandoned_carts',
        icon: null,
        pageName: pageNames.ABANDONED_CARTS,
      },
    ],
  },
  {
    name: 'Customers',
    path: Path.CUSTOMERS,
    key: 'customers',
    icon: CustomerIcon,
    permissionKey: Permission.CUSTOMER,
    pageName: pageNames.CUSTOMER,
  },
  {
    name: 'Subscription',
    path: Path.MANAGEMENT,
    key: 'subscription',
    icon: SubscriptionIcon,
    permissionKey: Permission.SUBSCRIPTION,
    pageName: pageNames.SUBSCRIPTION,
    children: [
      {
        name: 'Events',
        path: Path.SUBSCRIPTION_EVENTS,
        key: 'subscription_events',
        icon: null,
        pageName: pageNames.SUBSCRIPTION_EVENTS,
      },
      // {
      //   name: 'Projections',
      //   path: Path.PROJECTIONS,
      //   key: 'projections',
      //   icon: null,
      // },
      // {
      //   name: 'Management',
      //   path: Path.MANAGEMENT,
      //   key: 'management',
      //   icon: null,
      // },
    ],
  },
  {
    name: 'Reporting',
    key: 'reporting',
    icon: ReportingIcon,
    permissionKey: Permission.REPORTING,
    pageName: pageNames.REPORTING,
    children: [
      {
        name: 'Retention Report',
        path: Path.RETENTION_REPORT,
        key: 'retentionReport',
        icon: null,
        pageName: pageNames.RETENTION_REPORT,
      },
      {
        name: 'Subscription Salvage',
        path: Path.SUBSCRIPTION_SALVAGE,
        key: 'subscriptionSalvage',
        icon: null,
        pageName: pageNames.SUBSCRIPTION_SALVAGE,
      },
      {
        name: 'Risk',
        path: Path.RISK,
        key: 'risk',
        icon: null,
        pageName: pageNames.RISK,
      },
    ],
  },
  {
    name: 'Settings',
    path: Path.SETTING,
    key: 'newSettings',
    icon: SettingsIcon,
    permissionKey: Permission.SETTINGS,
    pageName: pageNames.SETTING,
  },
  {
    name: 'Admin',
    key: 'admin',
    icon: AdminIcon,
    permissionKey: Permission.EVENTS,
    pageName: pageNames.ADMIN_USERS,
    children: [
      {
        name: 'Events',
        path: Path.STORE_FRONT_EVENTS,
        key: 'events',
        icon: null,
        pageName: pageNames.STOREFRONT_EVENTS,
      },
      {
        name: 'Admins',
        path: Path.ADMIN_USERS,
        key: 'admins',
        icon: null,
        pageName: pageNames.ADMIN_USERS,
      },
    ],
  },
];

export const headerDetails = {
  [Path.DASHBOARD]: {
    display: 'Dashboard',
  },
  [Path.TRANSACTIONS]: {
    display: 'Transactions',
  },
  [Path.CUSTOMERS]: {
    display: 'Customers',
  },
  [Path.ORDERS]: {
    display: 'Orders List',
  },
  [Path.ABANDONED_CARTS]: {
    display: 'Abandoned Carts',
  },
  [Path.CUSTOMER_DETAILS]: {
    display: 'Customer Details',
    goBack: true,
  },
  [Path.RISK]: {
    display: 'Risks',
  },
  [Path.RETENTION_REPORT]: {
    display: 'Rentention Report',
  },
  [Path.CASCADING]: {
    display: 'Payment Routing',
  },
  [Path.STORE_FRONT_EVENTS]: {
    display: 'Events',
  },
  [Path.ADMIN_USERS]: {
    display: 'Admins',
  },
  // for future use
  // [Path.PROJECTIONS]: {
  //   display: 'Subscription Projections',
  // },
  [Path.MANAGEMENT]: {
    display: 'Subscription Management',
  },
  [Path.SETTINGS]: {
    display: 'Settings',
  },
  [Path.INVITES]: {
    display: 'Invites',
  },
  [Path.SUBSCRIPTION_SALVAGE]: {
    display: 'Subscription Salvage',
  },
  [Path.PROJECTIONS]: {
    display: 'Subscription Projections',
  },
  [Path.SHOPIFY]: {
    display: 'Shopify',
  },
  [Path.PAYMENT_GATEWAY]: {
    display: 'Payment Gateway',
  },
  [Path.STORE]: {
    display: 'Store',
  },
  [Path.SUBSCRIPTION_PLAN]: {
    display: 'Subscription Plan',
  },
  [Path.USERS_PERMISSION]: {
    display: 'User Permissions',
  },
  [Path.PAGES]: {
    display: 'Pages',
  },
  [Path.SHIPPING]: {
    display: 'Shipping',
  },
  [Path.PRE_UPSELL]: {
    display: 'Pre upsells',
  },
  [Path.POST_UPSELLS]: {
    display: 'Post upsells',
  },
  [Path.PAYMENT_ROUTING]: {
    display: 'Payment Routing',
  },
  [Path.SETTING]: {
    display: 'Stores',
  },
};

export const staticText = [
  `Welcome to the Phoenix CRM! We are excited to help you empower your
business.`,
  ` Decisions made and actions taken based on the use of Phoenix are your
sole responsibility. Our platform is designed to support your efforts,
but the achievement of your business goals depends on factors beyond
our control. By proceeding, you acknowledge and accept that Phoenix
and its team members bear no liability for direct or indirect
consequences arising from your use of our service.`,
  `It is your responsibility to ensure that you comply with all
applicable laws and regulations, understanding that it's your
responsibility to ensure your business practices are lawful and
ethical. For more information, please review the platform service
agreement you signed.`,
];
