import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ComponentConfig } from '@measured/puck';
import { ColorProps, colorField } from '../../puck/reusable-props/color';
import { fontField, FontProps } from '../../puck/reusable-props/font';
import { MarginProps, marginFields } from '../../puck/reusable-props/margin';
import { PaddingProps, paddingFields } from '../../puck/reusable-props/padding';
import { ReduxSelectorProps } from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';

export type AlwaysVipProps = ColorProps &
  FontProps &
  MarginProps &
  PaddingProps;

export const AlwaysVip = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<AlwaysVipProps> => {
  return {
    label: (
      <CustomTooltip title="AlwaysVip">
        <span>Always VIP</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...colorField,
      ...fontField,
      ...marginFields,
      ...paddingFields,
    },
    defaultProps: {
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      padding: {
        desktop: {
          top: 'pt-4',
          bottom: 'pb-4',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-4',
          bottom: 'max-md:pb-4',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-2',
          bottom: 'max-sm:pb-2',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      font: {
        size: 'text-md',
        weight: 'font-bold',
      },
      color: {
        backgroundColor: '#FFFFFF',
        textColor: '#111827',
        hoverColor: '#f3f4f6',
      },
    },
    render: ({ margin, color, font, padding }) => {
      const [isExpanded, setIsExpanded] = useState<boolean>(false);
      const storeData = useAppSelector(state => state.store.storeData);

      return (
        <>
          <div
            className={`relative text-gray-900 ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
          ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
          ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} border border-gray-800 rounded-lg overflow-hidden`}>
            <div
              className={`py-4 text-center ${font?.size} ${font?.weight} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}`}
              style={{
                backgroundColor: color?.backgroundColor,
                color: color?.textColor,
              }}
              onMouseOver={e =>
                (e.currentTarget.style.backgroundColor = color?.hoverColor)
              }
              onMouseOut={e =>
                (e.currentTarget.style.backgroundColor = color?.backgroundColor)
              }
              onClick={() => setIsExpanded(!isExpanded)}
              role="button"
              tabIndex={0}>
              VIP Club®
            </div>
            {isExpanded && (
              <div className="p-4">
                <p className="text-sm text-gray-500 leading-loose">
                  By proceeding, you consent and agree to all of our{' '}
                  <Link
                    to={`${storeData?.ShopURL}/policies/privacy-policy`}
                    className="primary-color text-blue-600">
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link
                    to={`${storeData?.ShopURL}/policies/terms-of-service`}
                    className="primary-color text-blue-600">
                    Terms of Service
                  </Link>{' '}
                  , inclusive of our arbitration provision. When you place your
                  discounted order, you are signing up for the {storeData?.Name}{' '}
                  membership, priced at $29.99 every 30 days (taxes may apply)
                  until you decide to terminate. You will receive an electronic
                  notification 5 to 7 days prior to your transaction and receipt
                  after each successful transaction.
                </p>
              </div>
            )}
          </div>

          <>
            <br />
            <div className="text-base border-t border-gray-200 ">
              <br />
              <dd className="text-sm font-medium">
                This offer is exclusively for paying members. Any orders from
                non-paying customers will be refunded and not fulfilled.
              </dd>
            </div>
          </>
        </>
      );
    },
  };
};
