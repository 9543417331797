import { AxiosError } from 'axios';
import { baseService } from 'services';
import { IReBilling } from 'interface/notesInterface';
import {
  commonError,
  getRetentionCancellationEp,
  getRetentionEP,
} from 'services/constants';
import { ISuccessResponse } from 'interface/apiInterface';
import { IReBillingFilterBody } from 'interface/retentioninterface';

class RetentionMockService {
  getReBilling = async () => {
    try {
      return await new Promise<ISuccessResponse<IReBilling[]>>(resolve => {
        setTimeout(() => {
          resolve({
            data: [],
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCancelling = async (payload: IReBillingFilterBody) => {
    try {
      const response = await baseService.post<IReBilling[]>(
        getRetentionCancellationEp(),
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class RetentionService {
  NotesMockService = new RetentionMockService();
  getReBilling = async (payload: IReBillingFilterBody) => {
    try {
      const response = await baseService.post<IReBilling[]>(
        getRetentionEP(),
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCancelling = async (payload: IReBillingFilterBody) => {
    try {
      const response = await baseService.post(
        getRetentionCancellationEp(),
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
const globalnotesService: RetentionService | RetentionMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new RetentionMockService()
    : new RetentionService();

export default globalnotesService;
