import { ReactComponent as SubscriptionRatio } from 'assets/icons/SubscriptionRatio.svg';
import { ReactComponent as StraightRatio } from 'assets/icons/StraightRatio.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import { formattedDate, percentage } from 'utils/helper';
import { FC, useMemo } from 'react';
import {
  IFilter,
  ISubscriptionStats,
  ITransactionStats,
  IUniqueTransactionStats,
  UniqueTransactionStats,
} from 'interface/dashboardInterface';
import { chargeType, transactionStatus } from 'components/dashboard/constant';
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';

interface IProps {
  uniqueTransactionStats: IUniqueTransactionStats | null;
  transactionStats: ITransactionStats | null;
  subscriptionStats: ISubscriptionStats | null;
  filter?: IFilter;
}

const placeholder = 0;

const percentagePlaceholder = (percentage: number, total: number) => {
  return percentage ? `${percentage}%` : total ? '0' : '-';
};

export const approvedTransactionStatus = [
  transactionStatus.captured,
  transactionStatus.authorized,
];

function numApproved(stat: UniqueTransactionStats) {
  if (approvedTransactionStatus.includes(stat.Status)) {
    return stat.Count;
  }
  return 0;
}

const StatsCards: FC<IProps> = ({
  uniqueTransactionStats,
  subscriptionStats,
  filter,
}) => {
  const cardStats = useMemo(() => {
    let saleApproved = 0;
    let saleTotal = 0;

    let subApproved = 0;
    let subTotal = 0;
    let subCycleInitial = 0;
    let subCycleRecurring = 0;
    let initialTotal = 0;
    let recurringTotal = 0;
    let purchaseTotal = 0;
    let purchaseApproved = 0;
    let upSellTotal = 0;
    let upSellApproved = 0;
    let totalPurchaseOrderCount = 0;
    const totalSubscriptionOrderCount = 0;
    if (!uniqueTransactionStats || !uniqueTransactionStats?.Stats?.length) {
      return {
        recurringTotal,
        initialTotal,
        purchaseTotal,
        upSellTotal,
        saleTotal,
        subTotal,
        totalPurchaseOrderCount,
        totalStraightApprovalPercentage: 0,
        totalSubscriptionApprovalPercentage: 0,
        totalSubCycleIntitalPercentage: 0,
        totalSubCycleRecurringPercentage: 0,
        totalSubscriptionOrderCount,
        totalPurchasePercentage: 0,
        totalUpSellPercentage: 0,
      };
    }
    uniqueTransactionStats?.Stats.forEach(stat => {
      const approvedCount = numApproved(stat);
      switch (stat.ChargeType) {
        case chargeType.subscription:
          subTotal += stat.Count;
          subApproved += approvedCount;
          if (stat.CycleNumber === 1) {
            subCycleInitial += approvedCount;
            initialTotal += stat.Count;
          } else {
            subCycleRecurring += approvedCount;
            recurringTotal += stat.Count;
          }
          break;
        case chargeType.purchase:
          saleTotal += stat.Count;
          saleApproved += approvedCount;
          purchaseTotal += stat.Count;
          purchaseApproved += approvedCount;
          if (approvedTransactionStatus.includes(stat.Status)) {
            totalPurchaseOrderCount += stat.Count;
          }
          break;
        case chargeType.upsell:
          saleTotal += stat.Count;
          saleApproved += approvedCount;
          upSellApproved += approvedCount;
          upSellTotal += stat.Count;
          break;
      }
    });
    return {
      recurringTotal,
      initialTotal,
      purchaseTotal,
      upSellTotal,
      saleTotal,
      subTotal,
      totalPurchaseOrderCount,
      totalStraightApprovalPercentage: percentage(
        saleApproved,
        saleTotal,
        true,
      ),
      totalSubscriptionApprovalPercentage: percentage(
        subApproved,
        subTotal,
        true,
      ),
      totalSubCycleIntitalPercentage: percentage(
        subCycleInitial,
        initialTotal,
        true,
      ),
      totalSubCycleRecurringPercentage: percentage(
        subCycleRecurring,
        recurringTotal,
        true,
      ),
      totalUpSellPercentage: percentage(upSellApproved, upSellTotal, true),
      totalPurchasePercentage: percentage(
        purchaseApproved,
        purchaseTotal,
        true,
      ),
      totalSubscriptionOrderCount: subApproved,
    };
  }, [uniqueTransactionStats]);

  return (
    <div className="statistics_cards row-full">
      <div className="card-wrapper">
        <p className="date">
          {formattedDate(dayjs(filter?.start_time).tz().format(), true) +
            ' - ' +
            formattedDate(dayjs(filter?.end_time).tz().format(), true)}
        </p>
        <div className="cards">
          <Tooltip
            placement="bottom"
            PopperProps={{ className: 'cycle-tooltip' }}
            title={
              <div className="cycle-wrapper">
                <div className="cycle divider">
                  <p className="title">Direct</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalPurchasePercentage,
                      cardStats.purchaseTotal,
                    )}
                  </p>
                </div>
                <div className="cycle">
                  <p className="title">UpSell</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalUpSellPercentage,
                      cardStats.upSellTotal,
                    )}
                  </p>
                </div>
              </div>
            }>
            <div className="card">
              <div className="icon_wrapper">
                <StraightRatio className="net_revenue" />
              </div>
              <div className="content_wrapper">
                <p className="label">Straight Unique Approval</p>
                <p className="sub_text">
                  {percentagePlaceholder(
                    cardStats.totalStraightApprovalPercentage,
                    cardStats.saleTotal,
                  )}
                </p>
              </div>
            </div>
          </Tooltip>

          <Tooltip
            placement="bottom"
            PopperProps={{ className: 'cycle-tooltip' }}
            title={
              <div className="cycle-wrapper">
                <div className="cycle divider">
                  <p className="title">Initial</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalSubCycleIntitalPercentage,
                      cardStats.initialTotal,
                    )}
                  </p>
                </div>
                <div className="cycle">
                  <p className="title">Recurring</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalSubCycleRecurringPercentage,
                      cardStats.recurringTotal,
                    )}
                  </p>
                </div>
              </div>
            }>
            <div className="card">
              <div className="icon_wrapper">
                <SubscriptionRatio className="revenue" />
              </div>
              <div className="content_wrapper">
                <p className="label">Subscription Unique Approval</p>
                <p className="sub_text">
                  {percentagePlaceholder(
                    cardStats.totalSubscriptionApprovalPercentage,
                    cardStats.subTotal,
                  )}
                </p>
              </div>
            </div>
          </Tooltip>

          <div className="card">
            <div className="icon_wrapper">
              <BoxIcon className="orders" />
            </div>
            <div className="content_wrapper">
              <p className="label">Purchase Orders</p>
              <p className="sub_text">{cardStats.totalPurchaseOrderCount}</p>
            </div>
          </div>

          <div className="card">
            <div className="icon_wrapper">
              <BoxIcon className="orders" />
            </div>
            <div className="content_wrapper">
              <p className="label">Subscription Orders</p>
              <p className="sub_text">
                {cardStats.totalSubscriptionOrderCount}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card-wrapper">
        <p className="date">Lifetime</p>
        <div className="cards">
          <div className="card">
            <div className="icon_wrapper">
              <UserIcon className="active_subscribers" />
            </div>
            <div className="content_wrapper">
              <p className="label">Active Subscribers</p>
              <p className="sub_text">
                {subscriptionStats
                  ? subscriptionStats.LifetimeSubscriptionStats
                      .ActiveSubscriptions
                  : placeholder}
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
          <div className="card">
            <div className="icon_wrapper">
              <UserIcon className="salvage" />
            </div>
            <div className="content_wrapper">
              <p className="label">Subscribers in Salvage</p>
              <p className="sub_text">
                {subscriptionStats
                  ? subscriptionStats.LifetimeSubscriptionStats.InSalvage
                  : placeholder}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="icon_wrapper">
              <UserIcon className="cancelled_subscribers" />
            </div>
            <div className="content_wrapper">
              <p className="label">Cancelled Subscribers</p>
              <p className="sub_text">
                {subscriptionStats
                  ? subscriptionStats.LifetimeSubscriptionStats
                      .CanceledSubscriptions
                  : placeholder}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCards;
