import { useMemo, FC } from 'react';
import { SubscriptionStats } from 'interface/dashboardInterface';
import LineChart from 'components/common/chart/lineChart';
import { ScriptableContext } from 'chart.js';

interface IProps {
  groupedSubscriptionStats: [string, SubscriptionStats[]][];
}
interface IDatasets {
  subscription: number[];
  newSubscription: number[];
  canceledSubscriptions: number[];
}

const NetSubscribers: FC<IProps> = ({ groupedSubscriptionStats }) => {
  const netSubscriberChartData = useMemo(() => {
    const values: IDatasets = {
      subscription: [],
      newSubscription: [],
      canceledSubscriptions: [],
    };
    groupedSubscriptionStats.forEach(([, statsData]) => {
      let cumulativeSubscription = 0;
      let canceledSubscriptions = 0;
      let newSubscription = 0;
      statsData.forEach(stat => {
        cumulativeSubscription +=
          stat.NewSubscriptions +
          stat.RenewedSubscriptions -
          stat.CanceledSubscriptions;
        newSubscription += stat.NewSubscriptions + stat.RenewedSubscriptions;
        canceledSubscriptions -= stat.CanceledSubscriptions;
      });
      values.subscription.push(cumulativeSubscription);
      values.newSubscription.push(newSubscription);
      values.canceledSubscriptions.push(canceledSubscriptions);
    });

    return {
      chart: {
        labels: groupedSubscriptionStats.map(([date]) => date),
        datasets: [
          {
            label: 'Net Subscribers',
            pointBorderColor: 'white',
            pointBackgroundColor: '#F90182',
            borderColor: '#F90182',
            backgroundColor: (context: ScriptableContext<'line'>) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(249, 1, 130, 0.55)');
              gradient.addColorStop(1, 'rgba(249, 1, 130, 0)');
              return gradient;
            },
            legend: 'Net Subscribers',
            data: values.subscription,
            total: values.subscription.reduce(
              (prev, current) => prev + current,
              0,
            ),
          },
          {
            label: 'New Subscribers',
            legend: 'New Subscribers',
            pointBorderColor: 'white',
            pointBackgroundColor: '#05CD99',
            borderColor: '#05CD99',
            backgroundColor: (context: ScriptableContext<'line'>) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(5, 190, 142, 0.5)');
              gradient.addColorStop(1, 'rgba(5, 190, 142, 0)');
              return gradient;
            },
            data: values.newSubscription,
            total: values.newSubscription.reduce(
              (prev, current) => prev + current,
              0,
            ),
          },
          {
            label: 'Cancel Subscribers',
            legend: 'Cancel Subscribers',
            pointBorderColor: 'white',
            pointBackgroundColor: '#04BEFE',
            borderColor: '#04BEFE',
            backgroundColor: (context: ScriptableContext<'line'>) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(190, 243, 255, 0.42)');
              gradient.addColorStop(1, 'rgba(66, 220, 254, 0.00)');
              return gradient;
            },
            data: values.canceledSubscriptions,
            total: values.canceledSubscriptions.reduce(
              (prev, current) => prev + current,
              0,
            ),
          },
        ],
      },
    };
  }, [groupedSubscriptionStats]);

  return (
    <div className="lg_card_container row-span-2">
      <div className="header">
        <p className="header_text">Net Subscribers</p>
      </div>
      <div className="body">
        <div className="left_side_details">
          <div className="stats_details">
            {netSubscriberChartData.chart.datasets.map(data => {
              return (
                <div key={data.label}>
                  <p className="head_text">
                    <span
                      className="indicator"
                      style={{ background: data.pointBackgroundColor }}
                    />
                    {data.legend}
                  </p>
                  <p className="sub_text">{data.total}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="chart_wrapper">
          <LineChart
            varient="filled"
            data={netSubscriberChartData.chart}
            className="chart"
          />
        </div>
      </div>
    </div>
  );
};

export default NetSubscribers;
