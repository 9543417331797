import { useMemo, FC } from 'react';
import { chargeType } from 'components/dashboard/constant';
import { UniqueAprrovalStats } from 'interface/dashboardNewIterface';
import { HorizontalBarChart } from 'components/common/chart/barChart';
import { getPercentageByValue } from 'utils/helper';

import { ChartDataset } from 'chart.js';
import { UnionToIntersection } from 'chart.js/dist/types/utils';

interface IProps {
  uniqueApprovalStats: UniqueAprrovalStats[];
}

export interface IDatasets extends UnionToIntersection<ChartDataset<'bar'>> {
  data: number[];
  count: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

const UniqueApprovalRatio: FC<IProps> = ({ uniqueApprovalStats }) => {
  const salesRevenueData = useMemo(() => {
    let straightSale = 0;
    let initialSubscription = 0;
    let recurringSubcription = 0;
    let straightSaleTotal = 0;
    let initialSubscriptionTotal = 0;
    let recurringSubcriptionTotal = 0;
    let upsellTotal = 0;
    let upsellApproved = 0;
    uniqueApprovalStats?.forEach(stat => {
      if (stat.ChargeType === chargeType.subscription) {
        if (stat.Initial) {
          initialSubscriptionTotal += stat.Count;
          initialSubscription += stat.Count;
          if (stat.Declined) initialSubscription -= stat.Count;
          return;
        }
        recurringSubcription += stat.Count;
        recurringSubcriptionTotal += stat.Count;
        if (stat.Declined) recurringSubcription -= stat.Count;
        return;
      } else if (stat.ChargeType === chargeType.purchase) {
        upsellApproved += stat.Count;
        upsellTotal += stat.Count;
        if (stat.Declined) upsellApproved -= stat.Count;
        return;
      }
      straightSale += stat.Count;
      straightSaleTotal += stat.Count;
      if (stat.Declined) straightSale -= stat.Count;
    });
    const totalofApproved =
      straightSale +
      initialSubscription +
      recurringSubcription +
      upsellApproved;
    const allTransactionTotal =
      straightSaleTotal +
      initialSubscriptionTotal +
      recurringSubcriptionTotal +
      upsellTotal;
    return {
      legend: [
        { name: 'Straight Sale', color: '#F90182' },
        { name: 'Initial Subscription', color: '#6AD2FF' },
        { name: 'Recurring Subscription', color: '#C237F3' },
        { name: 'UpSell', color: '#F36337' },
      ],
      chart: {
        labels: [
          'Straight Sale',
          'Initial Subscription',
          'Recurring Subscription',
          'UpSell',
        ],
        datasets: [
          {
            data: [
              getPercentageByValue(straightSale, straightSaleTotal),
              getPercentageByValue(
                initialSubscription,
                initialSubscriptionTotal,
              ),
              getPercentageByValue(
                recurringSubcription,
                recurringSubcriptionTotal,
              ),
              getPercentageByValue(upsellApproved, upsellTotal),
            ],
            count: [
              straightSale,
              initialSubscription,
              recurringSubcription,
              upsellApproved,
            ],
            backgroundColor: ['#F90182', '#6AD2FF', '#C237F3', '#F36337'],
            borderColor: ['#F90182', '#6AD2FF', '#C237F3', '#F36337'],
            borderWidth: 1,
          },
        ],
      },
      totalofApproved,
      allTransactionTotal,
      subscriptionTotal: initialSubscriptionTotal + recurringSubcriptionTotal,
      subscription: initialSubscription + recurringSubcription,
      purchases: upsellApproved + straightSale,
      purchasesTotal: upsellTotal + straightSaleTotal,
    };
  }, [uniqueApprovalStats]);

  return (
    <div className="lg_card_container">
      <div className="header">
        <p className="header_text">Unique Approval Ratio</p>
      </div>
      <div className="flex flex-row gap-5">
        <div className="flex flex-col sub_header gap-1">
          <p className="sub_header_1">All Transactions</p>
          <p className="sub_header_2">
            {' '}
            {getPercentageByValue(
              salesRevenueData.totalofApproved,
              salesRevenueData.allTransactionTotal,
            )}
            % ({salesRevenueData?.totalofApproved})
          </p>
        </div>
        <div className="flex flex-col sub_header gap-1">
          <p className="sub_header_1">Subscriptions</p>
          <p className="sub_header_2">
            {getPercentageByValue(
              salesRevenueData.subscription,
              salesRevenueData.subscriptionTotal,
            )}
            % ({salesRevenueData?.subscription})
          </p>
        </div>
        <div className="flex flex-col sub_header gap-1">
          <p className="sub_header_1">Purchases</p>
          <p className="sub_header_2">
            {getPercentageByValue(
              salesRevenueData.purchases,
              salesRevenueData.purchasesTotal,
            )}
            % ({salesRevenueData?.purchases}){' '}
          </p>
        </div>
      </div>
      <div className="chart_wrapper pl-1">
        <HorizontalBarChart data={salesRevenueData?.chart} />
      </div>
      <div className="footer">
        {salesRevenueData.legend.map(item => {
          return (
            <div className="footer_details" key={item?.name}>
              <div
                key={item?.name}
                className="indicator"
                style={{ backgroundColor: item.color }}></div>
              <span className="footer_text">{item?.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UniqueApprovalRatio;
