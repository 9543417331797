import React from 'react';
import { Phoenix_loader } from '../../../assets/icons/phoenix_loader';
import './style.scss';
import Stack from '@mui/material/Stack';
import { CircularProgress } from '@mui/material';

interface LoaderProps {
  loading: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ loading }) => {
  return loading ? (
    <div className="loader-container">
      <div className="loader">
        <div className="svg-img">
          <Phoenix_loader />
        </div>
        <>
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%">
                <stop offset="0%" stopColor="#f0b351" />
                <stop offset="100%" stopColor="#ff5acd" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{
              'svg circle': { stroke: 'url(#my_gradient)' },
              position: 'absolute',
              left: 5,
              right: -5,
              top: 5,
              bottom: -1,
            }}
            size={80}
            thickness={1}
          />
        </>
      </div>
    </div>
  ) : null;
};

export const Spinner: React.FC<LoaderProps> = ({ loading }) => {
  return loading ? (
    <div className="loader-container">
      <div className="loading-spinner">
        <Stack>
          <CircularProgress className="spinner" />
        </Stack>
      </div>
    </div>
  ) : null;
};
