import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import checkoutService from '../../page-builder/api/checkoutService';
import { ShippingMethod } from '../../page-builder/interface/checkoutInterface';
import { StartCheckoutPayload } from '../interface/apiServiceInterface';
import { useDispatch } from 'react-redux';
import { setAvailableShippingMethods } from '../../page-builder/redux/slices/checkoutSlice';
import { setCartData } from '../../page-builder/redux/slices/cartSlice';
import { setStoreData } from '../redux/slices/storeSlice';
import { ReduxSelectorProps } from '../services/constants';
import { ExtractPhxRequestId } from '../utils/extractPhoenixId';
import { EventPublisher } from '../utils/publishEvent';
import { AxiosError } from 'axios';
import { IErrorResponse } from '../interface/apiInterface';

export const usePreload = ({ useAppSelector }: ReduxSelectorProps) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const shopId = searchParams.get('store')?.split('.')?.[0];
  const cartId = searchParams.get('cart');
  const trafficSource = searchParams.get('trafficSource');

  const [checkoutStarted, setCheckoutStarted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(0);
  const [error, setError] = useState<string | null>(null);
  const storeData = useAppSelector(state => state.store.storeData);
  const publish = EventPublisher.getInstance().publish;

  function checkoutErrHelperFunc(usrMsg: string, sysMsg?: string) {
    setError(usrMsg);
    if (!sysMsg) {
      sysMsg = usrMsg;
    }
    console.log(sysMsg);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePreloadError = useCallback((error: any) => {
    if (error?.response?.status === 400 || error?.response?.status === 404) {
      checkoutErrHelperFunc(
        'There was a problem with your request. Please check your information and try again.',
        `Failed to fetch cart details. Status: ${error?.response?.status}`,
      );
    } else {
      checkoutErrHelperFunc(
        'Something went wrong! Try refreshing the page or try again later!',
        `Failed to fetch cart details. Status: ${error?.response?.status}`,
      );
    }
  }, []);

  const startCheckout = useCallback(async () => {
    if (!shopId || !cartId) {
      checkoutErrHelperFunc(
        'Something went wrong! Try refreshing the page or try again later!',
        'Failed to fetch cart details. Status: Invalid store or cart id',
      );
      return;
    }

    const input: StartCheckoutPayload = {
      CartID: cartId,
      ShopID: shopId,
      TrafficSource: trafficSource || '',
    };
    try {
      setIsLoading(pre => pre + 1);
      const res = await checkoutService.checkoutStart(input);
      publish('InitiateCheckout', {
        eventName: 'InitiateCheckout',
        eventId: ExtractPhxRequestId(res),
        shopId: storeData.ShopID,
        cart: res.data.Cart,
      });
      dispatch(setCartData(res.data.Cart));
      dispatch(
        setAvailableShippingMethods(
          res?.data?.Cart?.AvailableShippingMethods as ShippingMethod[],
        ),
      );
      dispatch(
        setStoreData({
          ...storeData,
          StoreID: res?.data?.Cart?.StoreID,
          ShopID: shopId,
        }),
      );
    } catch (error) {
      const errorResponse = error as IErrorResponse<AxiosError>;
      handlePreloadError(errorResponse.error as AxiosError);
    } finally {
      setIsLoading(pre => pre - 1);
    }
  }, [shopId, cartId, trafficSource, dispatch, handlePreloadError]);

  useEffect(() => {
    if (shopId && cartId && !checkoutStarted) {
      startCheckout();
      setCheckoutStarted(true);
    }
  }, [startCheckout, shopId, cartId, checkoutStarted]);

  return {
    isLoading: !!isLoading,
    error,
  };
};
