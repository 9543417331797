import { ReactNode, useEffect, useState } from 'react';
import 'components/rentention/style.scss';
import utc from 'dayjs/plugin/utc';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import CustomDatePicker from 'components/common/customDatePicker';
import rententionService from 'services/retentionService';
import {
  IReBilling,
  IRetentionFilter,
  IRetention,
} from 'interface/notesInterface';
import { get } from 'lodash';
import { IReBillingFilterBody } from 'interface/retentioninterface';
import dayjs from 'dayjs';
import { Loader } from 'components/common/loader';
import { useSearchParams } from 'react-router-dom';
import { demicalFormatter, percentage, UsDollarFormatter } from 'utils/helper';
import { useAppSelector } from 'hooks/reduxHooks';
import { ControlledRadioButton } from 'components/common/radioButton';

dayjs.extend(utc);

interface IRetentionList {
  CycleNumber: number;
  Salvage?: boolean;
  Total: number;
  TotalApproved: number;
  TotalDeclined: number;
  Amount: number;
  TotalCancelledBeforeBilling: number;
  Pending: number;
  Attempted: number;
  FailedBillingCanceled: number;
  overAllRetentionValue: number;
  AverageValue: number;
}
export interface HeadCell {
  id: string;
  label: string;
  className?: string;
  cellRender?: (_row: IRetentionList) => ReactNode;
  getWidth?: (_row: IRetentionList) => string;
  hide?: boolean;
  hasPercentage?: boolean;
  valueGetter?: (_row: IRetention) => string | number;
}

const retentionDate = [
  { label: 'Charge Date', value: 'chargeDate' },
  { label: 'Acquisition Date', value: 'acquisitionDate' },
];

export default function RetentionReport() {
  const headCells_rebill: readonly HeadCell[] = [
    {
      id: 'CycleNumber',
      label: 'Cycle Name',
      hide: false,
      cellRender: (row: IRetentionList) => {
        return `Cycle ${row?.CycleNumber} ${row?.Salvage ? '(Salvage)' : ''}`;
      },
    },
    {
      id: 'Total',
      label: 'Total',
      hide: false,
      cellRender: (row: IRetentionList) => {
        return <span>{row?.Total}</span>;
      },
    },
    {
      id: 'TotalCancelledBeforeBilling',
      label: 'Cancelled Before Billing',
      hide: false,
      cellRender: (row: IRetentionList) => {
        const billPrecent = percentage(
          row.TotalCancelledBeforeBilling,
          row.Total,
        );
        return (
          <span>
            {`${row.TotalCancelledBeforeBilling} (${billPrecent % 1 !== 0 ? parseFloat(billPrecent.toFixed(1)) : billPrecent}%)`}
          </span>
        );
      },
    },
    {
      id: 'Pendings',
      label: 'Pending',
      hide: false,
      hasPercentage: false,
      cellRender: (row: IRetentionList) => {
        const pendingPercentage = (100 * row.Pending) / row.Total;
        return (
          <span>{`${row.Pending} (${pendingPercentage % 1 !== 0 ? parseFloat(pendingPercentage.toFixed(1)) : pendingPercentage}%)`}</span>
        );
      },
    },
    {
      id: 'Attempted',
      label: 'Attempted',
      hide: false,
      hasPercentage: true,
      className: 'attempted',
      getWidth: (row: IRetentionList) => {
        return String((row.Attempted / row.Total) * 100);
      },
      cellRender: (row: IRetentionList) => {
        const attemptedPercentage = percentage(row.Attempted, row.Total, true);
        return (
          <div>
            <span>{`${row.Attempted} `}</span>
            <span>
              {`(${attemptedPercentage % 1 !== 0 ? parseFloat(attemptedPercentage.toFixed(1)) : attemptedPercentage}%)`}
            </span>
          </div>
        );
      },
    },
    {
      id: 'TotalApproved',
      label: 'Approved',
      hide: false,
      hasPercentage: true,
      getWidth: (row: IRetentionList) => {
        return String((row.TotalApproved / row.Attempted) * 100);
      },
      cellRender: (row: IRetentionList) => {
        const approvedPrecentage = percentage(row.TotalApproved, row.Attempted);
        return (
          <div>
            <span>{`${row.TotalApproved} `}</span>
            <span>
              (
              {String(
                approvedPrecentage % 1 !== 0
                  ? parseFloat(approvedPrecentage.toFixed(1))
                  : approvedPrecentage,
              )}
              %)
            </span>
          </div>
        );
      },
      className: 'approved',
    },
    {
      id: 'TotalDeclined',
      label: 'Declined',
      hide: false,
      className: 'decline',
      hasPercentage: true,
      getWidth: (row: IRetentionList) => {
        return String((row.TotalDeclined / row.Attempted) * 100);
      },
      cellRender: (row: IRetentionList) => {
        const declinedPrecentage = percentage(
          row.TotalDeclined,
          row.Attempted,
          true,
        );
        return (
          <div>
            <span>{`${row.TotalDeclined} `}</span>
            <span>
              (
              {String(
                declinedPrecentage % 1 !== 0
                  ? parseFloat(declinedPrecentage.toFixed(1))
                  : declinedPrecentage,
              )}
              %)
            </span>
          </div>
        );
      },
    },
    {
      id: 'Revenue',
      label: 'Revenue',
      hide: false,
      cellRender: (row: IRetentionList) => {
        const revenuePrecentage = row?.Amount;
        return (
          <div>
            <span>{`$${demicalFormatter(UsDollarFormatter(Number(revenuePrecentage)))}`}</span>
          </div>
        );
      },
    },
    {
      id: 'FailedBilling',
      label: 'Failed Billing Canceled',
      cellRender: (row: IRetentionList) => {
        const failedBillingPrecentage = percentage(
          row.FailedBillingCanceled,
          row.Total,
          true,
        );
        return (
          <div>
            <span>{`${row.FailedBillingCanceled} `}</span>
            <span>
              (
              {String(
                failedBillingPrecentage % 1 !== 0
                  ? parseFloat(failedBillingPrecentage.toFixed(1))
                  : failedBillingPrecentage,
              )}
              %)
            </span>
          </div>
        );
      },
    },
  ];

  const [retentionList, setRententionList] = useState<IRetentionList[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useForm<IRetentionFilter>({
    defaultValues: {
      StartTime: searchParams.get('StartTime')
        ? dayjs(searchParams.get('StartTime')).tz()
        : null,
      EndTime: searchParams.get('EndTime')
        ? dayjs(searchParams.get('EndTime')).tz()
        : null,
      RadioAcquisition:
        searchParams.get('RadioAcquisitionKey') === 'acquisitionDate'
          ? 'acquisitionDate'
          : 'chargeDate',
    },
  });
  const { watch, setValue } = methods;
  const { EndTime, StartTime, RadioAcquisition } = watch();
  const { storeIds } = useAppSelector(state => state.storeIds);

  const getReBilling = async () => {
    let payload: IReBillingFilterBody = {};
    const date = {
      StartTime: StartTime ? StartTime.tz().startOf('day').format() : null,
      EndTime: EndTime
        ? EndTime.tz().add(1, 'day').startOf('day').format()
        : null,
    };

    if (RadioAcquisition === 'acquisitionDate') {
      payload = {
        AcquisitionStartTime: date?.StartTime,
        AcquisitionEndTime: date?.EndTime,
      };
    } else {
      payload = {
        ...date,
      };
    }
    if (storeIds?.length > 0) {
      payload.StoreIDs = storeIds?.map(store => store.ID);
    }
    setSearchParams(
      {
        EndTime: EndTime ? dayjs(EndTime).tz().format() : '',
        StartTime: StartTime ? dayjs(StartTime).tz().format() : '',
        RadioAcquisitionKey: RadioAcquisition ?? '',
      },
      { replace: true },
    );
    setLoading(true);
    const [responseOfReBilling] = await Promise.all([
      rententionService.getReBilling(payload),
    ]);

    if (responseOfReBilling.status === 200) {
      getRetention(responseOfReBilling?.data?.Result);
    } else {
      setRententionList([]);
    }
    setLoading(false);
  };
  const getRetention = (billing: IReBilling[]) => {
    const retentionDats = billing
      ?.map((item: IReBilling) => ({
        Salvage: item.Salvage,
        AverageValue: item.AverageValue,
        CycleNumber: item.CycleNumber,
        Amount: item.TotalAmount,
        Total: item.Customers,
        TotalApproved: item.TotalApproved,
        TotalDeclined: item.TotalDeclined,
        Attempted: item.TotalApproved + item.TotalDeclined,
        FailedBillingCanceled: item.TotalCanceledBillingFailed,
        overAllRetentionValue: item.TotalApproved / item.Customers,
        Pending: item.TotalPending,
        TotalCancelledBeforeBilling: item.TotalCanceledBefore,
      }))
      .sort((a, b) => {
        if (a.CycleNumber !== b.CycleNumber) {
          return a.CycleNumber - b.CycleNumber;
        }
        return a.Salvage === b.Salvage ? 1 : a.Salvage ? 1 : -1;
      });

    setRententionList(retentionDats);
  };
  useEffect(() => {
    getReBilling();
  }, [EndTime, StartTime, storeIds, RadioAcquisition]);

  return (
    <div className="rention-report">
      <div className="retention-filters">
        <form className="filter-selection-form">
          <FormProvider {...methods}>
            <div className="flex gap-5">
              <div className="">
                <div className="common_label_text mb-1">Date filter by</div>
                <ControlledRadioButton
                  name="RadioAcquisition"
                  onChange={event => {
                    if (
                      event.target.value === 'acquisitionDate' &&
                      ((StartTime && StartTime > dayjs().tz()) ||
                        (EndTime && EndTime > dayjs().tz()))
                    ) {
                      setValue('StartTime', null);
                      setValue('EndTime', null);
                    }
                    setValue(
                      'RadioAcquisition',
                      event.target.value as 'chargeDate' | 'acquisitionDate',
                    );
                  }}
                  value={RadioAcquisition}
                  label=""
                  className="filter_form_input"
                  options={retentionDate}
                />
              </div>
              <div className="">
                <div className="common_label_text">Date Range</div>
                <CustomDatePicker
                  selectedRange={{
                    startDate: watch('StartTime'),
                    endDate: watch('EndTime'),
                  }}
                  key={RadioAcquisition}
                  dateValues={range => {
                    if (range.startDate && range.endDate) {
                      setValue('StartTime', range.startDate);
                      setValue('EndTime', range.endDate);
                    } else {
                      setValue('StartTime', null);
                      setValue('EndTime', null);
                    }
                  }}
                  maxDate={
                    RadioAcquisition === 'acquisitionDate'
                      ? dayjs().tz().toDate()
                      : null
                  }
                />
              </div>
            </div>
          </FormProvider>
        </form>
      </div>

      <div className="rention-first-table">
        <span className="heading">Rebill Summary </span>

        <div className="retention">
          <TableContainer className="Common_Table rentention_page_table">
            <Table
              className="rention_table"
              aria-labelledby="tableTitle"
              stickyHeader>
              <TableHead className="table_header">
                <TableRow>
                  {headCells_rebill?.map(headCell => {
                    if (headCell?.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        align="center"
                        className="table_header_cell header_text"
                        key={headCell.label}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="table_body">
                {retentionList?.length > 0 ? (
                  retentionList.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        className={`table_row ${row?.Salvage ? 'salvage' : ''}`}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}>
                        {headCells_rebill.map(headCell => {
                          if (headCell?.hide) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table_cell percentage_column"
                              align="center"
                              key={headCell?.label}
                              component="th"
                              id={headCell?.label}
                              scope="row">
                              <div
                                className={`percent-width ${headCell.className?.toLocaleLowerCase()}`}
                                style={
                                  headCell?.hasPercentage
                                    ? {
                                        width: `${headCell?.getWidth ? headCell?.getWidth(row) : null}%`,
                                      }
                                    : {}
                                }></div>
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <div className="no-data-row">No data found</div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Loader loading={loading} />
    </div>
  );
}
