import React, { useEffect, useMemo } from 'react';
import Tabs from 'components/common/Tabs';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Pages from 'components/pages';
import Shipping from 'components/store/storeDetails/shopify/shopifyDetails/Shipping';
import PostUpsells from 'components/store/storeDetails/shopify/shopifyDetails/postUpsells';
import './style.scss';
// import CheckoutFlow from 'components/checkoutFlow';
// import ConversionTracking from 'components/store/storeDetails/shopify/shopifyDetails/conversionTracking';
import PaymentRouting from 'components/PaymentRouting';
import PreUpSell from './preUpSell';
import CheckoutFlow from './checkoutFlow';

import Membership from './Membership';
import globalStoreFrontService from 'services/storeFrontService';
import { IStoreFront } from 'interface/settingsInterface';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setStoreFrontDetails } from 'redux-setup/slices/shopifySlice';
import { getShopifyeDetailsPath, PageNames, pageNames } from 'router/constant';
import TrackingSetting from './trackingSetting';
import { isFeatureEnabled } from 'utils/helper';

interface ITabsList {
  tabName: string;
  element: JSX.Element;
  pageName: PageNames;
}

export default function ShopifyDetails() {
  const [searchParams] = useSearchParams();
  const tabValue = searchParams.get('tab')
    ? String(JSON.parse(searchParams.get('tab') || '1'))
    : '1';
  const params = useParams();
  const storeFront = useAppSelector(state => state.shopify.storeFront);
  const navigate = useNavigate();
  const handleTabChange = (newValue: string) => {
    if (params?.storeId && params?.shopifyId && params?.shopId) {
      navigate(
        getShopifyeDetailsPath(
          params?.storeId,
          params?.shopifyId,
          params?.shopId,
          Number(newValue),
        ),
        {
          replace: true,
        },
      );
    }
  };

  const dispatch = useAppDispatch();

  const tabs: ITabsList[] = [
    {
      tabName: 'Pages',
      pageName: pageNames.PAGE,
      element: (
        <Pages
          storeId={params?.storeId ?? ''}
          shopifyId={params?.shopifyId ?? ''}
          shopId={storeFront?.ShopID ?? ''}
        />
      ),
    },
    {
      tabName: 'Shipping',
      pageName: pageNames.SHIPPING,
      element: (
        <Shipping
          storeId={params?.storeId ?? ''}
          shopifyId={params?.shopifyId ?? ''}
        />
      ),
    },
    {
      tabName: 'Pre UpSell',
      pageName: pageNames.PRE_UPSELL,
      element: (
        <PreUpSell
          channelId={params?.shopifyId ?? ''}
          storeId={params?.storeId ?? ''}
          shopId={params?.shopId ?? ''}
        />
      ),
    },
    {
      tabName: 'Post UpSell',
      pageName: pageNames.POST_UPSELL,
      element: (
        <PostUpsells
          channelId={params?.shopifyId ?? ''}
          storeId={params?.storeId ?? ''}
          shopId={params?.shopId ?? ''}
        />
      ),
    },
    {
      tabName: 'Checkout Flow',
      pageName: pageNames.CHECKOUT_FLOW,
      element: (
        <CheckoutFlow
          storeId={params?.storeId ?? ''}
          shopifyId={params?.shopifyId ?? ''}
        />
      ),
    },
    {
      tabName: 'Payment Routing',
      pageName: pageNames.PAYMENT_ROUTING,
      element: (
        <PaymentRouting
          channelId={params?.shopifyId ?? ''}
          storeId={params?.storeId ?? ''}
          shopId={params?.shopId ?? ''}
        />
      ),
    },
    {
      tabName: 'Conversion Tracking',
      pageName: pageNames.CONVERSION_TRACKING,
      element: (
        <TrackingSetting
          channelId={params?.shopifyId ?? ''}
          storeId={params?.storeId ?? ''}
          shopId={params?.shopId ?? ''}
        />
      ),
    },
    ...(storeFront?.Shopify?.MembershipType === 'selling_plan_one_membership'
      ? [
          {
            tabName: 'Membership Offers',
            pageName: pageNames.MEMBERSHIP_OFFERS,
            element: <Membership />,
          },
        ]
      : []),
  ];

  const labelsForTab = useMemo(() => {
    return tabs.filter(val => isFeatureEnabled(val.pageName));
  }, [tabs]);

  const getStoreFronts = async (storeID: string, shopifyId: string) => {
    const res = await globalStoreFrontService.getStoreFronts(storeID);
    if (res?.data && res.data?.Result) {
      const storeFronts = res.data.Result?.find(
        (data: IStoreFront) => data.ID === shopifyId,
      );
      dispatch(setStoreFrontDetails(storeFronts));
    } else {
      dispatch(setStoreFrontDetails([]));
    }
  };

  useEffect(() => {
    if (params?.storeId && params?.shopifyId)
      getStoreFronts(params?.storeId, params?.shopifyId);
  }, []);

  return (
    <div className="tab-content">
      <Tabs
        value={tabValue}
        labels={labelsForTab.map(val => val.tabName)}
        onChange={handleTabChange}
      />
      <div className="tab-content-wrapper relative">
        {labelsForTab.map((val, index) => {
          return <>{tabValue === `${index + 1}` && val.element}</>;
        })}
      </div>
    </div>
  );
}
