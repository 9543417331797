import 'components/orders/orderDetails/style.scss';
import Tabs from 'components/common/Tabs';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useMemo, useState } from 'react';
import Transactions from 'components/orders/orderDetails/transactionHistory';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';
import { useNavigate, useParams } from 'react-router-dom';
import orderService from 'services/orderService';
import {
  setOrderDetail,
  setOrderTransactions,
} from 'redux-setup/slices/OrderSlice';
import { RootState } from 'redux-setup/store';
import { Loader } from 'components/common/loader';
import SideFilter from 'components/orders/orderDetails/orderDetailsFilter/sideFilter';
import { ITransactionHistory } from 'interface/transactionInterface';
import { getCustomerDetailsPath } from 'router/constant';
import PlaceholderIcon from 'assets/icons/placeholder_image.svg';
import OrderNotes from 'components/orders/orderDetails/orderNotes';

const defaultAmount = '0.00';

export default function OrderDetails() {
  const [selectedTransaction, setSelectedTransaction] = useState<
    Map<number, ITransactionHistory>
  >(new Map());
  const dispatch = useAppDispatch();
  const { orderId, storeId } = useParams();
  const tabName = ['Transaction History', 'Notes'];
  const [tabValue, setTabValue] = useState('1');
  const ordersData = useAppSelector(
    (state: RootState) => state.order.orderDetail,
  );

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
  };
  const [loading, setLoading] = useState<number>(0);
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const getOrderDetails = async (
    order_id: string = '',
    store_id: string = '',
  ) => {
    setLoading(pre => pre + 1);
    const orderDetails = await orderService.getOrderById(store_id, order_id);
    if (orderDetails?.data) {
      dispatch(setOrderDetail(orderDetails?.data?.Order ?? null));
    }
    setLoading(pre => pre - 1);
  };

  const getOrderHistory = async (
    order_id: string = '',
    store_id: string = '',
  ) => {
    setHistoryLoading(true);
    const orderHistory = await orderService.getOrderHistoryById(
      store_id,
      order_id,
    );
    dispatch(setOrderTransactions(orderHistory?.data?.Result ?? []));
    setHistoryLoading(false);
  };

  const handleCustomerCellClick = (orderID: string) => {
    const customerDetailsPath = getCustomerDetailsPath(orderID, storeId || '');
    navigate(customerDetailsPath, {
      state: { customer_id: orderID, store_id: storeId },
    });
  };

  useEffect(() => {
    if (orderId && storeId) {
      getOrderDetails(orderId, storeId);
      getOrderHistory(orderId, storeId);
    }
    return () => {
      dispatch(setOrderDetail(null));
      dispatch(setOrderTransactions([]));
    };
  }, [orderId, storeId]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentFilter(''));
    };
  }, []);

  const { subTotal, vipSavings } = useMemo(() => {
    let subTotal = 0; // sum of item's total amount
    let vipSavings = 0; //  each item's unit_amount and original_unit_amount
    if (ordersData?.Items?.length) {
      ordersData?.Items.forEach(item => {
        subTotal += Number(
          Number(item?.UnitPrice) * item.Quantity ||
            Number(item?.SalePrice) * item.Quantity,
        );
        if (item?.OriginalPrice) {
          vipSavings +=
            (Number(item?.OriginalPrice) -
              Number(item?.UnitPrice || item?.SalePrice)) *
            item?.Quantity;
        }
      });
    }
    return { subTotal: subTotal.toFixed(2), vipSavings: vipSavings.toFixed(2) };
  }, [ordersData]);

  return (
    <div className="order-details-page">
      <div className="left-container">
        <div className="order-details-container">
          <div className="products-container">
            <div className="product-header">
              <p>Products</p>
            </div>
            <div className="products">
              {ordersData?.Items &&
                ordersData?.Items.map((item, key) => {
                  const totalSaving =
                    (Number(item?.OriginalPrice) -
                      Number(item?.UnitPrice || item?.SalePrice)) *
                    item?.Quantity;
                  return (
                    <>
                      <div className="item" key={key}>
                        <div className="product-wrap">
                          <img
                            src={item?.ImageURL || PlaceholderIcon}
                            alt="Product"
                            className="product-image"
                          />
                          <div className="item-content">
                            <p className="item-name">
                              {item ? item?.Name : ''}
                            </p>
                            <p className="qty-wrap">
                              Quantity: {item?.Quantity}&nbsp;
                              <CloseIcon fontSize="small" /> &nbsp;
                              <span>
                                $
                                {item?.UnitPrice ||
                                  item?.SalePrice ||
                                  defaultAmount}
                              </span>
                            </p>
                            <p className="price-varient">
                              {item.OriginalPrice ? (
                                <span>
                                  <span className="offer">Retail Price:</span>
                                  <span className="offer">
                                    ${item?.OriginalPrice || defaultAmount}
                                  </span>
                                </span>
                              ) : (
                                ''
                              )}
                              {totalSaving > 0 && (
                                <span>
                                  <span className="vip-price">
                                    Total Savings:
                                  </span>
                                  <span className="final-amt">
                                    ${totalSaving.toFixed(2)}
                                  </span>
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div>
                          $
                          {(
                            Number(item?.UnitPrice) * item?.Quantity ||
                            Number(item?.SalePrice) * item?.Quantity ||
                            0
                          ).toFixed(2) || defaultAmount}
                        </div>
                      </div>
                      {key !== ordersData?.Items.length - 1 && (
                        <hr className="divider" />
                      )}
                    </>
                  );
                })}
            </div>
          </div>
          <div className="summary-content">
            <div className="summary-container">
              <div className="sum-header">Order Summary</div>
              <div className="sum-content">
                <p>
                  Subtotal <span>${subTotal || defaultAmount}</span>
                </p>
                {/* <p>
                  Discount <span>-${orderSummary.discount}</span>
                </p>
                <p>
                  Shipping <span>${orderSummary.shipping}</span>
                </p> */}
                {Number(vipSavings) > 0 && (
                  <p className="vip_savings">
                    VIP Savings
                    <span>
                      ${Math.abs(Number(vipSavings || defaultAmount))}
                    </span>
                  </p>
                )}
                <p className="total">
                  Total
                  <span>${ordersData?.TotalAmount || defaultAmount}</span>
                </p>
                <p>
                  Paid <span>${ordersData?.TotalAmount || defaultAmount}</span>
                </p>
              </div>
            </div>
            {Number(vipSavings) > 0 && (
              <div className="saved-amt">
                Saved&nbsp;
                <span>${Math.abs(Number(vipSavings || defaultAmount))}</span>
                &nbsp;by using Subscription
              </div>
            )}
          </div>
          <div className="tab-content">
            <div>
              <Tabs
                value={tabValue}
                labels={tabName}
                onChange={handleTabChange}
              />
            </div>
            {tabValue === '1' && (
              <div>
                <Transactions
                  historyLoading={historyLoading}
                  setSelectedTransaction={setSelectedTransaction}
                  selectedTransaction={selectedTransaction}
                />
              </div>
            )}
            {tabValue === '2' && (
              <div className="notes-customer">
                <OrderNotes orderId={orderId} storeId={storeId} />
              </div>
            )}
          </div>
        </div>
        <div className="customer-details-container">
          <div className="customer">
            <div className="cus-title">Customer</div>
            <div className="cus-body">
              <p
                className="name-wrap"
                onClick={() =>
                  handleCustomerCellClick(ordersData?.Customer?.ID || '')
                }>
                {ordersData?.PaymentProfile?.FirstName}{' '}
                {ordersData?.PaymentProfile?.LastName}
              </p>
              <p>{ordersData?.Customer?.Email}</p>
              <p>{ordersData?.Customer?.PhoneNumber}</p>
            </div>
          </div>
          <div className="customer">
            <div className="cus-title">Shipping Address</div>
            <div className="cus-body">
              <p>
                {ordersData?.ShippingAddress?.Address1} &nbsp;
                {ordersData?.ShippingAddress?.Address2}
              </p>
              <p>
                {ordersData?.ShippingAddress?.City} &nbsp;
                {ordersData?.ShippingAddress?.Zip}
              </p>
              <p>{ordersData?.ShippingAddress?.CountryCode || null}</p>
            </div>
          </div>
          <div className="customer">
            <div className="cus-title">Billing Address</div>
            <div className="cus-body">
              <p>
                {ordersData?.PaymentProfile?.Address1} &nbsp;
                {ordersData?.PaymentProfile?.Address2}
              </p>
              <p>
                {ordersData?.PaymentProfile?.City} &nbsp;
                {ordersData?.PaymentProfile?.Zip}
              </p>
              <p>{ordersData?.PaymentProfile?.CountryCode || null}</p>
            </div>
          </div>
        </div>
      </div>
      <SideFilter
        selectedTransaction={selectedTransaction}
        refetch={() => {
          getOrderDetails(orderId, storeId);
          getOrderHistory(orderId, storeId);
          setSelectedTransaction(new Map());
        }}
      />
      <Loader loading={!!loading} />
    </div>
  );
}
