import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import './style.scss';
import { Loader } from 'components/common/loader';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import { get } from 'lodash';
import { IPaymentGateway } from 'interface/settingsInterface';
import globalStoresService from 'services/storeService';
import { showNotification } from 'helper/common/commonFunctions';
import AddGateWay from './addGateway';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IPaymentGateway) => ReactNode;
  hide?: boolean;
}
interface IProps {
  storeID: string;
}
export default function Gateway({ storeID }: IProps) {
  const defaultPaymentGateway = {
    Secret: '',
    StoreID: storeID,
  };
  const [paymentGatewayLoading, setPaymentGatewayLoading] =
    useState<boolean>(true);
  const [paymentGateways, setPaymentGateways] =
    useState<IPaymentGateway | null>(null);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<IPaymentGateway>(
    defaultPaymentGateway,
  );

  const headCellList: HeadCell[] = [
    {
      id: 'Secret',
      label: 'Secret',
      hide: false,
      cellRender: row => {
        return <>{row.Secret}</>;
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <div className="flex gap-3 items-center justify-end">
            <button
              className="create_store"
              onClick={() => {
                setIsUpdate(true);
                setSelectedStore(row);
              }}>
              <CreateIcon />
            </button>{' '}
          </div>
        );
      },
    },
  ];
  const getPaymentGateways = async () => {
    setPaymentGatewayLoading(true);
    const res = await globalStoresService.getPaymentGateways(storeID);
    setPaymentGatewayLoading(false);
    if (res && res?.data) {
      setPaymentGateways(res?.data);
    } else {
      setPaymentGateways(null);
    }
  };

  const successCallback = (message: string) => {
    showNotification('success', message);
    setIsUpdate(false);
    getPaymentGateways();
  };

  const addPaymentGateway = async (data: IPaymentGateway) => {
    setPaymentGatewayLoading(true);
    const res = await globalStoresService.createPaymentGateway(data);
    setPaymentGatewayLoading(false);
    if (res.status === 200) {
      successCallback('Payment Gateway Added Successfully');
    }
  };

  const updatePaymentGateway = async (data: IPaymentGateway) => {
    setPaymentGatewayLoading(true);
    const res = await globalStoresService.updatePaymentGateway(data);
    setPaymentGatewayLoading(false);
    if (res.status === 200) {
      successCallback('Payment Gateway Updated Successfully');
    }
  };

  const onSubmit = async (data: IPaymentGateway) => {
    if (data.ID) {
      updatePaymentGateway(data);
    } else {
      addPaymentGateway(data);
    }
  };

  useEffect(() => {
    if (storeID) getPaymentGateways();
  }, [storeID]);

  return (
    <div className="pre-upsell">
      <div className="h-full">
        <div className="h-full">
          <TableContainer className="Common_Table !h-fit max-h-full">
            <Table aria-labelledby="tableTitle" className="table" stickyHeader>
              <TableHead>
                <TableRow>
                  {headCellList.map(headCell => {
                    if (headCell.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        className={
                          headCell.label == 'Actions' ? '!text-right' : ''
                        }
                        key={headCell.label}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <>
                <TableBody className="table-body">
                  {paymentGateways ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      {headCellList.map(headCell => {
                        if (headCell.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            key={headCell.label}
                            component="th"
                            id={headCell.id}
                            scope="row">
                            <>
                              {headCell?.cellRender
                                ? headCell.cellRender(paymentGateways)
                                : get(paymentGateways, headCell.id)}
                            </>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell className="!py-20" colSpan={15}>
                        <div className="no-data-row">No data found</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {!paymentGateways && (
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={15}>
                        <button
                          className="sticky-button flex items-center"
                          onClick={() => setIsUpdate(true)}>
                          <AddIcon /> Add Payment Gateway
                        </button>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                )}
              </>
            </Table>
          </TableContainer>
        </div>
        <Loader loading={paymentGatewayLoading} />

        {isUpdate && (
          <AddGateWay
            selectedProduct={selectedStore}
            handleClose={() => setIsUpdate(false)}
            action={data => onSubmit(data)}
          />
        )}
      </div>
    </div>
  );
}
