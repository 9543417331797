import { FieldLabel, Fields } from '@measured/puck';
import { Select } from '../../../page-builder/components/form-fields/Select';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CustomTooltip from '../../../components/common/tooltip';

export type paddingSubProps = {
  top: string;
  bottom: string;
  left: string;
  right: string;
};
export type PaddingType = {
  desktop: paddingSubProps;
  tablet: paddingSubProps;
  mobile: paddingSubProps;
};
export type PaddingProps = {
  padding: PaddingType;
};
const paddingTopOptions = {
  desktop: [
    { label: '0', value: 'pt-0' },
    { label: '1', value: 'pt-1' },
    { label: '2', value: 'pt-2' },
    { label: '4', value: 'pt-4' },
    { label: '6', value: 'pt-6' },
    { label: '8', value: 'pt-8' },
    { label: '10', value: 'pt-10' },
    { label: '12', value: 'pt-12' },
    { label: '14', value: 'pt-14' },
    { label: '16', value: 'pt-16' },
    { label: '20', value: 'pt-20' },
    { label: '24', value: 'pt-24' },
    { label: '28', value: 'pt-28' },
    { label: '32', value: 'pt-32' },
  ],
  tablet: [
    { label: '0', value: 'max-md:pt-0' },
    { label: '1', value: 'max-md:pt-1' },
    { label: '2', value: 'max-md:pt-2' },
    { label: '4', value: 'max-md:pt-4' },
    { label: '6', value: 'max-md:pt-6' },
    { label: '8', value: 'max-md:pt-8' },
    { label: '10', value: 'max-md:pt-10' },
    { label: '12', value: 'max-md:pt-12' },
    { label: '14', value: 'max-md:pt-14' },
    { label: '16', value: 'max-md:pt-16' },
    { label: '20', value: 'max-md:pt-20' },
    { label: '24', value: 'max-md:pt-24' },
    { label: '28', value: 'max-md:pt-28' },
    { label: '32', value: 'max-md:pt-32' },
  ],
  mobile: [
    { label: '0', value: 'max-sm:pt-0' },
    { label: '1', value: 'max-sm:pt-1' },
    { label: '2', value: 'max-sm:pt-2' },
    { label: '4', value: 'max-sm:pt-4' },
    { label: '6', value: 'max-sm:pt-6' },
    { label: '8', value: 'max-sm:pt-8' },
    { label: '10', value: 'max-sm:pt-10' },
    { label: '12', value: 'max-sm:pt-12' },
    { label: '14', value: 'max-sm:pt-14' },
    { label: '16', value: 'max-sm:pt-16' },
    { label: '20', value: 'max-sm:pt-20' },
    { label: '24', value: 'max-sm:pt-24' },
    { label: '28', value: 'max-sm:pt-28' },
    { label: '32', value: 'max-sm:pt-32' },
  ],
};
const paddingBottomOptions = {
  desktop: [
    { label: '0', value: 'pb-0' },
    { label: '1', value: 'pb-1' },
    { label: '2', value: 'pb-2' },
    { label: '4', value: 'pb-4' },
    { label: '6', value: 'pb-6' },
    { label: '8', value: 'pb-8' },
    { label: '10', value: 'pb-10' },
    { label: '12', value: 'pb-12' },
    { label: '14', value: 'pb-14' },
    { label: '16', value: 'pb-16' },
    { label: '20', value: 'pb-20' },
    { label: '24', value: 'pb-24' },
    { label: '28', value: 'pb-28' },
    { label: '32', value: 'pb-32' },
  ],
  tablet: [
    { label: '0', value: 'max-md:pb-0' },
    { label: '1', value: 'max-md:pb-1' },
    { label: '2', value: 'max-md:pb-2' },
    { label: '4', value: 'max-md:pb-4' },
    { label: '6', value: 'max-md:pb-6' },
    { label: '8', value: 'max-md:pb-8' },
    { label: '10', value: 'max-md:pb-10' },
    { label: '12', value: 'max-md:pb-12' },
    { label: '14', value: 'max-md:pb-14' },
    { label: '16', value: 'max-md:pb-16' },
    { label: '20', value: 'max-md:pb-20' },
    { label: '24', value: 'max-md:pb-24' },
    { label: '28', value: 'max-md:pb-28' },
    { label: '32', value: 'max-md:pb-32' },
  ],
  mobile: [
    { label: '0', value: 'max-sm:pb-0' },
    { label: '1', value: 'max-sm:pb-1' },
    { label: '2', value: 'max-sm:pb-2' },
    { label: '4', value: 'max-sm:pb-4' },
    { label: '6', value: 'max-sm:pb-6' },
    { label: '8', value: 'max-sm:pb-8' },
    { label: '10', value: 'max-sm:pb-10' },
    { label: '12', value: 'max-sm:pb-12' },
    { label: '14', value: 'max-sm:pb-14' },
    { label: '16', value: 'max-sm:pb-16' },
    { label: '20', value: 'max-sm:pb-20' },
    { label: '24', value: 'max-sm:pb-24' },
    { label: '28', value: 'max-sm:pb-28' },
    { label: '32', value: 'max-sm:pb-32' },
  ],
};
const paddingLeftOptions = {
  desktop: [
    { label: '0', value: 'pl-0' },
    { label: '1', value: 'pl-1' },
    { label: '2', value: 'pl-2' },
    { label: '4', value: 'pl-4' },
    { label: '6', value: 'pl-6' },
    { label: '8', value: 'pl-8' },
    { label: '10', value: 'pl-10' },
    { label: '12', value: 'pl-12' },
    { label: '14', value: 'pl-14' },
    { label: '16', value: 'pl-16' },
    { label: '20', value: 'pl-20' },
    { label: '24', value: 'pl-24' },
    { label: '28', value: 'pl-28' },
    { label: '32', value: 'pl-32' },
  ],
  tablet: [
    { label: '0', value: 'max-md:pl-0' },
    { label: '1', value: 'max-md:pl-1' },
    { label: '2', value: 'max-md:pl-2' },
    { label: '4', value: 'max-md:pl-4' },
    { label: '6', value: 'max-md:pl-6' },
    { label: '8', value: 'max-md:pl-8' },
    { label: '10', value: 'max-md:pl-10' },
    { label: '12', value: 'max-md:pl-12' },
    { label: '14', value: 'max-md:pl-14' },
    { label: '16', value: 'max-md:pl-16' },
    { label: '20', value: 'max-md:pl-20' },
    { label: '24', value: 'max-md:pl-24' },
    { label: '28', value: 'max-md:pl-28' },
    { label: '32', value: 'max-md:pl-32' },
  ],
  mobile: [
    { label: '0', value: 'max-sm:pl-0' },
    { label: '1', value: 'max-sm:pl-1' },
    { label: '2', value: 'max-sm:pl-2' },
    { label: '4', value: 'max-sm:pl-4' },
    { label: '6', value: 'max-sm:pl-6' },
    { label: '8', value: 'max-sm:pl-8' },
    { label: '10', value: 'max-sm:pl-10' },
    { label: '12', value: 'max-sm:pl-12' },
    { label: '14', value: 'max-sm:pl-14' },
    { label: '16', value: 'max-sm:pl-16' },
    { label: '20', value: 'max-sm:pl-20' },
    { label: '24', value: 'max-sm:pl-24' },
    { label: '28', value: 'max-sm:pl-28' },
    { label: '32', value: 'max-sm:pl-32' },
  ],
};
const paddingRightOptions = {
  desktop: [
    { label: '0', value: 'pr-0' },
    { label: '1', value: 'pr-1' },
    { label: '2', value: 'pr-2' },
    { label: '4', value: 'pr-4' },
    { label: '8', value: 'pr-8' },
    { label: '10', value: 'pr-10' },
    { label: '12', value: 'pr-12' },
    { label: '14', value: 'pr-14' },
    { label: '16', value: 'pr-16' },
    { label: '20', value: 'pr-20' },
    { label: '24', value: 'pr-24' },
    { label: '28', value: 'pr-28' },
    { label: '32', value: 'pr-32' },
  ],
  tablet: [
    { label: '0', value: 'max-md:pr-0' },
    { label: '1', value: 'max-md:pr-1' },
    { label: '2', value: 'max-md:pr-2' },
    { label: '4', value: 'max-md:pr-4' },
    { label: '8', value: 'max-md:pr-8' },
    { label: '10', value: 'max-md:pr-10' },
    { label: '12', value: 'max-md:pr-12' },
    { label: '14', value: 'max-md:pr-14' },
    { label: '16', value: 'max-md:pr-16' },
    { label: '20', value: 'max-md:pr-20' },
    { label: '24', value: 'max-md:pr-24' },
    { label: '28', value: 'max-md:pr-28' },
    { label: '32', value: 'max-md:pr-32' },
  ],
  mobile: [
    { label: '0', value: 'max-sm:pr-0' },
    { label: '1', value: 'max-sm:pr-1' },
    { label: '2', value: 'max-sm:pr-2' },
    { label: '4', value: 'max-sm:pr-4' },
    { label: '8', value: 'max-sm:pr-8' },
    { label: '10', value: 'max-sm:pr-10' },
    { label: '12', value: 'max-sm:pr-12' },
    { label: '14', value: 'max-sm:pr-14' },
    { label: '16', value: 'max-sm:pr-16' },
    { label: '20', value: 'max-sm:pr-20' },
    { label: '24', value: 'max-sm:pr-24' },
    { label: '28', value: 'max-sm:pr-28' },
    { label: '32', value: 'max-sm:pr-32' },
  ],
};

export const paddingFields: Fields<PaddingProps> = {
  padding: {
    type: 'custom',
    label: 'Padding',
    render: ({ value, onChange }) => {
      const [isOpen, setIsOpen] = useState(false);

      const toggleAccordion = () => {
        setIsOpen(!isOpen);
      };

      const handleChange = (
        platform: string,
        key: string,
        option: React.ChangeEvent<HTMLSelectElement>,
      ) => {
        onChange({
          ...value,
          [platform]: {
            ...value[platform as keyof PaddingType],
            [key]: option.target.value,
          },
        });
      };
      return (
        <div>
          <div
            className=" flex justify-between font-extrabold cursor-pointer p-3 bg-slate-100 rounded-md pagebuilder-theme"
            onClick={toggleAccordion}>
            <h3>Customize Padding</h3>
            {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </div>
          {isOpen && (
            <div className="flex flex-col gap-4 mt-3  items-center border  border-gray-300 rounded  p-2 w-full pagebuilder-theme">
              <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="desktopPadding">
                        <span>Desktop</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <div className="grid grid-cols-[1fr_1fr] gap-2 border-t-1">
                    <Select
                      value={value.desktop.top}
                      onChange={e => {
                        handleChange('desktop', 'top', e);
                      }}
                      options={paddingTopOptions.desktop}
                      label="Top"
                    />
                    <Select
                      value={value.desktop.bottom}
                      onChange={e => {
                        handleChange('desktop', 'bottom', e);
                      }}
                      options={paddingBottomOptions.desktop}
                      label="Bottom"
                    />
                    <Select
                      value={value.desktop.left}
                      onChange={e => {
                        handleChange('desktop', 'left', e);
                      }}
                      options={paddingLeftOptions.desktop}
                      label="Left"
                    />
                    <Select
                      value={value.desktop.right}
                      onChange={e => {
                        handleChange('desktop', 'right', e);
                      }}
                      options={paddingRightOptions.desktop}
                      label="Right"
                    />
                  </div>
                </FieldLabel>
              </div>
              <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="tabletPadding">
                        <span>Tablet</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <div className="grid grid-cols-[1fr_1fr] gap-2 ">
                    <Select
                      value={value.tablet.top}
                      onChange={e => {
                        handleChange('tablet', 'top', e);
                      }}
                      options={paddingTopOptions.tablet}
                      label="Top"
                    />
                    <Select
                      value={value.tablet.bottom}
                      onChange={e => {
                        handleChange('tablet', 'bottom', e);
                      }}
                      options={paddingBottomOptions.tablet}
                      label="Bottom"
                    />
                    <Select
                      value={value.tablet.left}
                      onChange={e => {
                        handleChange('tablet', 'left', e);
                      }}
                      options={paddingLeftOptions.tablet}
                      label="Left"
                    />
                    <Select
                      value={value.tablet.right}
                      onChange={e => {
                        handleChange('tablet', 'right', e);
                      }}
                      options={paddingRightOptions.tablet}
                      label="Right"
                    />
                  </div>
                </FieldLabel>
              </div>
              <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="mobilePadding">
                        <span>Mobile</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <div className="grid grid-cols-[1fr_1fr] gap-2">
                    <Select
                      value={value.mobile.top}
                      onChange={e => {
                        handleChange('mobile', 'top', e);
                      }}
                      options={paddingTopOptions.mobile}
                      label="Top"
                    />
                    <Select
                      value={value.mobile.bottom}
                      onChange={e => {
                        handleChange('mobile', 'bottom', e);
                      }}
                      options={paddingBottomOptions.mobile}
                      label="Bottom"
                    />
                    <Select
                      value={value.mobile.left}
                      onChange={e => {
                        handleChange('mobile', 'left', e);
                      }}
                      options={paddingLeftOptions.mobile}
                      label="Left"
                    />
                    <Select
                      value={value.mobile.right}
                      onChange={e => {
                        handleChange('mobile', 'right', e);
                      }}
                      options={paddingRightOptions.mobile}
                      label="Right"
                    />
                  </div>
                </FieldLabel>
              </div>
            </div>
          )}
        </div>
      );
    },
  },
};
