import Dashboard from 'components/dashboard';
import Tabs from 'components/common/Tabs';
import NewDashboard from 'components/dashboard-2';
import { useState } from 'react';
import { isFeatureEnabled } from 'utils/helper';
import { pageNames } from 'router/constant';

const labelsForTab = ['Dashboard', 'New Dashboard'];

const DashboardPage = () => {
  const [tabIndex, setTabIndex] = useState<string>('1'); // Assuming this is the active tab index, it might come from state in a real scenario
  const handleTabChange = (newValue: string) => {
    setTabIndex(newValue);
  };
  return (
    <>
      {!isFeatureEnabled(pageNames.NEW_DASHBOARD) ? (
        <Dashboard />
      ) : (
        <div className="tab-content h-full">
          <Tabs
            value={tabIndex}
            labels={labelsForTab}
            onChange={handleTabChange}
          />
          <div className="tab-content-wrapper h-fit">
            {tabIndex === '1' ? <Dashboard /> : <NewDashboard />}
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardPage;
