import { useEffect, useCallback } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { V2ShippingMethod } from '../../interface/checkoutInterface';
import { formatPrice } from '../../utils/formatPrice';
import LoadingSpinner from '../shared/LoadingSpinner';
import { setSelectedShippingMethod } from '../../redux/slices/checkoutSlice';
import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';

export type ShippingMethodSelectProps = MarginProps;

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const ShippingMethodSelect = ({
  useAppDispatch,
  useAppSelector,
}: ReduxDispatchProps &
  ReduxSelectorProps): ComponentConfig<ShippingMethodSelectProps> => {
  return {
    label: (
      <CustomTooltip title="ShippingMethodSelect">
        <span>Shipping Method Select</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...marginFields,
    },
    defaultProps: {
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-1',
          bottom: 'max-md:mb-1',
          right: 'max-md:mr-1',
          left: 'max-md:ml-1',
        },
        mobile: {
          top: 'max-sm:mt-1',
          bottom: 'max-sm:mb-1',
          right: 'max-sm:mr-1',
          left: 'max-sm:ml-1',
        },
      },
    },
    render: ({ margin }) => {
      const dispatch = useAppDispatch();
      const availableShippingMethods = useAppSelector(
        state => state.checkout.availableShippingMethods,
      );
      const isApiLoading = useAppSelector(state => state.checkout.isApiLoading);
      const selectedShippingMethod = useAppSelector(
        state => state.checkout.selectedShippingMethod,
      );

      useEffect(() => {
        if (availableShippingMethods?.length) {
          const initialShippingMethod = availableShippingMethods[0];
          dispatch(setSelectedShippingMethod(initialShippingMethod));
        }
      }, [dispatch, availableShippingMethods]);

      const handleShippingMethodChange = useCallback(
        (shippingMethod: V2ShippingMethod) => {
          dispatch(setSelectedShippingMethod(shippingMethod));
        },
        [dispatch],
      );

      return (
        <>
          <div
            className={`flex-col justify-between 
              ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
              ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
              ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
            <RadioGroup
              value={selectedShippingMethod}
              onChange={handleShippingMethodChange}>
              <div className={`relative grid gap-x-4 gap-y-3 grid-cols-1`}>
                {isApiLoading ? (
                  <div className="flex justify-center item-center py-8">
                    <LoadingSpinner />
                  </div>
                ) : (
                  availableShippingMethods?.map(
                    (
                      shippingMethod: V2ShippingMethod,
                      i: number,
                      arr: V2ShippingMethod[],
                    ) => (
                      <RadioGroup.Option
                        key={shippingMethod.ID}
                        value={shippingMethod}
                        className={({ active }) =>
                          classNames(
                            active
                              ? `border-indigo-600 ring-2 ring-indigo-600`
                              : 'border-gray-300',
                            'flex relative cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none ',
                            arr.length === 1 ? 'w-full' : '',
                          )
                        }
                        disabled={arr.length === 1}>
                        {({ checked, active }) => (
                          <>
                            <CheckCircleIcon
                              className={classNames(
                                !checked ? 'invisible' : '',
                                `h-5 w-5 mr-4 text-page_builder_theme`,
                              )}
                              aria-hidden="true"
                            />
                            <span className="flex flex-1 ">
                              <span className="flex flex-col justify-between h-full">
                                <RadioGroup.Label
                                  as="span"
                                  className="block text-sm font-medium text-gray-900">
                                  {shippingMethod.Name}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                  as="span"
                                  className="mt-1 flex items-center text-sm text-gray-500">
                                  {shippingMethod.Description}
                                </RadioGroup.Description>
                              </span>
                            </span>
                            <RadioGroup.Description
                              as="span"
                              className="text-sm font-medium text-gray-900">
                              ${formatPrice(shippingMethod.Price)}
                            </RadioGroup.Description>
                            <span
                              className={classNames(
                                active ? 'border' : 'border-2',
                                checked
                                  ? `border-page_builder_theme`
                                  : 'border-transparent',
                                'pointer-events-none absolute -inset-px rounded-lg',
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ),
                  )
                )}
              </div>
            </RadioGroup>
          </div>
        </>
      );
    },
  };
};
