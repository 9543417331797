import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export type QuillTextEditorProps = {
  content: string;
};

const modules = {
  toolbar: [
    [{ header: [] }, 'bold', 'italic', 'underline', 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }, 'clean'],
    [{ color: [] }, { background: [] }],
  ],
};

const formats = [
  'header',
  'list',
  'bullet',
  'bold',
  'italic',
  'underline',
  'color',
  'background',
  'link',
];
type QuillEditorProps = {
  value: string;
  onChange: (_value: string) => void;
};

export const QuillEditor = ({ value, onChange }: QuillEditorProps) => {
  return (
    <ReactQuill
      className="pagebuilder-theme"
      theme={'snow'}
      onChange={onChange}
      value={value}
      modules={modules}
      formats={formats}
      placeholder={'create content here...'}
    />
  );
};
