import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { ISubscriptionManagementUpdateBody } from 'interface/subscriptionManagementInterface';
import globalSubscriptionManagementService from 'services/subscriptionManagementService';
import { showNotification } from 'helper/common/commonFunctions';
import TextField from 'components/common/textField';
import { Controller, useForm } from 'react-hook-form';
import { SubscriptionStatus } from 'components/SubscriptionManagement/constant';

interface IProps {
  payload: ISubscriptionManagementUpdateBody | null;
  handleClose: () => void;
  onApiResponse: (_isSuccess: boolean) => void;
}

interface ISubscriptionValue {
  subscriptionAmount: string;
}

const ChangeSubscriptionValue: FC<IProps> = ({
  payload,
  handleClose,
  onApiResponse,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<ISubscriptionValue>({
    defaultValues: { subscriptionAmount: payload?.Amount || '' },
  });

  const changeSubscription = async (data: ISubscriptionValue) => {
    if (!payload) return;
    setLoading(true);
    payload.Amount = data.subscriptionAmount;
    payload.Status = SubscriptionStatus.subscriptionStatusActive;
    const response =
      await globalSubscriptionManagementService.updateSubscription(payload);
    if (response?.status === 200) {
      showNotification('success', 'Subscription Value updated successfully');
      onApiResponse(true);
      handleClose();
    } else {
      onApiResponse(false);
    }
    setLoading(false);
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'positive',
            buttonLabel: 'Confirm',
            form: 'ChangeSubscriptionValue',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={'Change Subscription Value'}
        className="flex-container">
        <div className="popup-section">
          <form
            id="ChangeSubscriptionValue"
            onSubmit={handleSubmit(changeSubscription)}
            className="order-detail_refund">
            <Controller
              name="subscriptionAmount"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    fixedSize="md"
                    label="Subscription Value"
                    value={value}
                    onChange={onChange}
                    className="filter_form_input"
                    placeholder="Enter Subscription Value"
                    errorString={error?.message}
                  />
                );
              }}
              rules={{
                required: 'Subscription value is required',
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default ChangeSubscriptionValue;
