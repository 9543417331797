import { useMemo, FC } from 'react';
import { UniqueTransactionStats } from 'interface/dashboardInterface';
import { GroupedBarChart } from 'components/common/chart/barChart';
import { chargeType } from 'components/dashboard/constant';
import { percentage } from 'utils/helper';
import { approvedTransactionStatus } from 'components/dashboard/StatsCards';

interface IProps {
  groupedTransactionStats: [string, UniqueTransactionStats[]][];
  // groupedTransactionStats is a nested array which has date(as Xaxis label) in 0th index and transaction stats array in 1st index.
}

export interface Ratio {
  value: number;
  total: number;
  valueAttempted?: number;
  tooltip?: string;
}

interface IDatasets {
  all: Ratio[];
  straight: Ratio[];
  initialSubscription: Ratio[];
  recurringSubscription: Ratio[];
  upSell: Ratio[];
}

function numApproved(stat: UniqueTransactionStats) {
  if (approvedTransactionStatus.includes(stat.Status)) {
    return stat.Count;
  }
  return 0;
}

function tooltipFunction(total: number, value: number, label: string) {
  return `${label} : ${value}/${total} - ${percentage(value, total, true) || 0}%`;
}

function totalTextGenerateFunction(data: Ratio[]) {
  let totalValue = 0;
  let overAllTotal = 0;
  data.forEach(data => {
    totalValue += data.value;
    overAllTotal += data.total;
  });
  return `${totalValue}/${overAllTotal} - ${percentage(totalValue, overAllTotal, true) || 0}%`;
}

const ApprovalRatio: FC<IProps> = ({ groupedTransactionStats }) => {
  const approvalRatioData = useMemo(() => {
    // Count of approvals, keyed by the group by key, mapping to a list for the percentage in that group per date.
    // The length of each value must correspond to the length of the dates (the x-axis)
    const values: IDatasets = {
      all: [],
      straight: [],
      initialSubscription: [],
      recurringSubscription: [],
      upSell: [],
    };

    groupedTransactionStats.forEach(([, statsData]) => {
      let total = 0;
      let totalApproved = 0;
      let saleApproved = 0;
      let saleTotal = 0;
      let subInitialApproved = 0;
      let subInitialTotal = 0;
      let subRecurringApproved = 0;
      let subRecurringTotal = 0;
      let upsellTotal = 0;
      let upsellApproved = 0;
      statsData.forEach(stat => {
        total += stat.Count;
        totalApproved += numApproved(stat);

        switch (stat.ChargeType) {
          case chargeType.subscription:
            if (stat?.CycleNumber === 1) {
              subInitialTotal += stat.Count;
              subInitialApproved += numApproved(stat);
              return;
            }
            subRecurringTotal += stat.Count;
            subRecurringApproved += numApproved(stat);
            return;
          case chargeType.purchase:
            saleTotal += stat.Count;
            saleApproved += numApproved(stat);
            return;
          case chargeType.upsell:
            upsellTotal += stat.Count;
            upsellApproved += numApproved(stat);
            return;
        }
      });

      values.all.push({
        total: total,
        value: totalApproved,
        tooltip: tooltipFunction(total, totalApproved, 'All Transactions'),
      });
      values.straight.push({
        total: saleTotal,
        value: saleApproved,
        tooltip: tooltipFunction(saleTotal, saleApproved, 'Direct Sale'),
      });
      values.initialSubscription.push({
        total: subInitialTotal,
        value: subInitialApproved,
        tooltip: tooltipFunction(
          subInitialTotal,
          subInitialApproved,
          'Initial Subscription',
        ),
      });
      values.recurringSubscription.push({
        total: subRecurringTotal,
        value: subRecurringApproved,
        tooltip: tooltipFunction(
          subRecurringTotal,
          subRecurringApproved,
          'Recurring Subscription',
        ),
      });
      values.upSell.push({
        total: upsellTotal,
        value: upsellApproved,
        tooltip: tooltipFunction(upsellTotal, upsellApproved, 'UpSell'),
      });
    });

    return {
      chart: {
        labels: groupedTransactionStats.map(([date]) => date),
        datasets: [
          {
            label: 'All Transactions',
            borderColor: '#C237F3',
            backgroundColor: '#C237F3',
            legend: 'All Transactions',
            data: values.all.map(({ value, total }) =>
              percentage(value, total, true),
            ),
            rawData: values.all,
            tooltip: values.initialSubscription.map(({ tooltip }) => tooltip),
          },
          {
            label: 'Direct',
            borderColor: '#F90182',
            backgroundColor: '#F90182',
            legend: 'Direct Sale',
            data: values.straight.map(({ value, total }) =>
              percentage(value, total, true),
            ),
            rawData: values.straight,
            tooltip: values.initialSubscription.map(({ tooltip }) => tooltip),
          },
          {
            label: 'Upsell',
            borderColor: '#1243d8',
            backgroundColor: '#1243d8',
            legend: 'UpSell',
            data: values.upSell.map(({ value, total }) =>
              percentage(value, total, true),
            ),
            rawData: values.upSell,
            tooltip: values.upSell.map(({ tooltip }) => tooltip),
          },
          {
            label: 'Initial Subscription',
            borderColor: '#F36337',
            backgroundColor: '#F36337',
            legend: 'Initial Subscription',
            data: values.initialSubscription.map(({ value, total }) =>
              percentage(value, total, true),
            ),
            rawData: values.initialSubscription,
            tooltip: values.initialSubscription.map(({ tooltip }) => tooltip),
          },
          {
            label: 'Recurring Subscription',
            borderColor: '#6AD2FF',
            backgroundColor: '#6AD2FF',
            legend: 'Recurring Subscription',
            data: values.recurringSubscription.map(({ value, total }) =>
              percentage(value, total, true),
            ),
            rawData: values.recurringSubscription,
            tooltip: values.initialSubscription.map(({ tooltip }) => tooltip),
          },
        ],
      },
    };
  }, [groupedTransactionStats]);

  return (
    <div className="lg_card_container row-span-2">
      <div className="header">
        <p className="header_text">Unique Approval Ratio</p>
      </div>
      <div className="body">
        <div className="left_side_details">
          <div className="stats_details">
            {approvalRatioData.chart.datasets.map(data => {
              return (
                <div key={data.label}>
                  <p className="head_text">
                    <span
                      className="indicator"
                      style={{ background: data.backgroundColor }}
                    />
                    {data.legend}
                  </p>
                  <p className="sub_text">
                    <span className="font-16">
                      {totalTextGenerateFunction(data.rawData)}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="chart_wrapper approval_chat">
          <GroupedBarChart
            data={approvalRatioData.chart}
            suffix="%"
            className="chart"
            isApprovalRatio={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovalRatio;
