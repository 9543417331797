import { AxiosError } from 'axios';
import { baseService } from './index';
import { commonError } from './constant';
import {
  StartCheckoutPayload,
  CheckoutDataPayload,
  GetPreCheckoutUpSellPayload,
  GetPreCheckoutUpSellResponse,
  GetPostCheckoutUpSellPayload,
  GetPostCheckoutUpSellResponse,
  CheckoutUpdateItemsPayload,
  CheckoutCompletePayload,
  CheckoutCompleteResponse,
  AddItemPayload,
  SetOrderDetailsResponse,
  SetContactInfoPayload,
  SetShippingAddressPayload,
  PageDataResponse,
} from '../interface/apiServiceInterface';
import { ApplyDiscountCodePayload } from '../interface/checkoutInterface';
import { postUpsellPurchasePayload } from '../interface/postUpsellInterface';
import { Shop } from '../../page-builder/interface/storeInterface';

class CheckoutService {
  setContactInfo = async (payload: SetContactInfoPayload) => {
    try {
      const response = await baseService.post<SetOrderDetailsResponse>(
        '/checkout/set_contact_info',
        payload,
      );
      return response;
    } catch (error) {
      // here we can do the common error handling for this API
      throw commonError(error as AxiosError, true);
    }
  };
  setShippingAddress = async (payload: SetShippingAddressPayload) => {
    try {
      const response = await baseService.post<SetOrderDetailsResponse>(
        '/checkout/set_shipping_address',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutStart = async (payload: StartCheckoutPayload) => {
    try {
      const response = await baseService.post<CheckoutDataPayload>(
        '/checkout/start',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutComplete = async (payload: CheckoutCompletePayload) => {
    try {
      const response = await baseService.post<CheckoutCompleteResponse>(
        '/checkout/complete',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  getPreCheckoutUpSells = async (payload: GetPreCheckoutUpSellPayload) => {
    try {
      const response = await baseService.post<GetPreCheckoutUpSellResponse>(
        '/checkout/upsells/pre_checkout',
        payload,
        { timeout: 3000 },
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  getPostCheckoutUpSells = async (payload: GetPostCheckoutUpSellPayload) => {
    try {
      const response = await baseService.post<GetPostCheckoutUpSellResponse>(
        '/checkout/upsells/post_checkout',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutUpdateItems = async (payload: CheckoutUpdateItemsPayload) => {
    try {
      const response = await baseService.post<CheckoutDataPayload>(
        '/checkout/update_items',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutAddItem = async (payload: AddItemPayload) => {
    try {
      const response = await baseService.post<CheckoutDataPayload>(
        '/checkout/add_items',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };

  postUpsellPurchase = async (payload: postUpsellPurchasePayload) => {
    try {
      const response = await baseService.post(
        '/checkout/upsell/complete',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutApplyDiscountCode = async (payload: ApplyDiscountCodePayload) => {
    try {
      const response = await baseService.post<CheckoutDataPayload>(
        '/checkout/apply_discount_code',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };

  getStoreDetails = async (shopId: string) => {
    try {
      const response = await baseService.get<Shop>(`/store/details/${shopId}`);
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };

  getCheckout = async (payload: StartCheckoutPayload) => {
    try {
      const response = await baseService.post<PageDataResponse>(
        `/checkout/start_v2`,
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
}

const checkoutService = new CheckoutService();
export default checkoutService;
