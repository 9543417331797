// termsAndSubSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TermsState {
  isTermsAccepted: boolean;
}

const initialState: TermsState = {
  isTermsAccepted: false,
};

const termsAndSubSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setIsTermsAccepted: (state, action: PayloadAction<boolean>) => {
      state.isTermsAccepted = action.payload;
    },
  },
});

export const { setIsTermsAccepted } = termsAndSubSlice.actions;

export default termsAndSubSlice.reducer;
