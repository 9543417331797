import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  ArcElement,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  doughnutBarChart,
  groupedBarChartoptions,
  horizontalBarChart,
  overlapedBarChart,
  stackedBarChart,
} from 'components/common/chart/barChart/constant';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

interface IDoughnutprops {
  data: ChartData<'doughnut', (number | [number, number] | null)[]>;
  prefix?: string;
  suffix?: string;
  labelPrefix?: string;
  labelSuffix?: string;
  hasPercentageValue?: boolean;
  isPercentagedata?: boolean;
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
  [_props: string]: unknown;
  options?: ChartOptions<'doughnut'>;
  isApprovalRatio?: boolean;
}

interface Iprops {
  data: ChartData<'bar', (number | [number, number] | null)[]>;
  maxStepSize?: number;
  prefix?: string;
  suffix?: string;
  labelPrefix?: string;
  labelSuffix?: string;
  hasPercentageValue?: boolean;
  isPercentagedata?: boolean;
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
  [_props: string]: unknown;
  options?: ChartOptions<'bar'>;
  isApprovalRatio?: boolean;
}

export const GroupedBarChart = ({
  data,
  prefix = '',
  suffix = '',
  hasPercentageValue = false,
  options = {},
  isPercentagedata = false,
  customPrefixSuffix,
  isApprovalRatio = false,
  ...rest
}: Iprops) => {
  return (
    <Bar
      options={{
        ...groupedBarChartoptions({
          prefix,
          suffix,
          hasPercentageValue,
          isPercentagedata,
          customPrefixSuffix,
          isApprovalRatio,
        }),
        ...options,
      }}
      data={data}
      {...rest}
    />
  );
};

export const StackedBarChart = ({
  data,
  suffix = '',
  prefix = '',
  ...rest
}: Iprops) => {
  return (
    <Bar
      options={{ ...stackedBarChart({ prefix, suffix }) }}
      data={data}
      {...rest}
    />
  );
};

export const HorizontalBarChart = ({ data, ...rest }: Iprops) => {
  return (
    <Bar
      className={'!w-full'}
      options={{ ...horizontalBarChart() }}
      data={data}
      {...rest}
    />
  );
};

export const OverlapedBarChart = ({
  data,
  suffix = '',
  prefix = '',
  labelPrefix = '',
  labelSuffix = '',
  hasPercentageValue = false,
  options = {},
  isPercentagedata = false,
  customPrefixSuffix,
  ...rest
}: Iprops) => {
  return (
    <Bar
      options={{
        ...overlapedBarChart({
          prefix,
          suffix,
          labelPrefix,
          labelSuffix,
          hasPercentageValue,
          isPercentagedata,
          customPrefixSuffix,
        }),
        ...options,
      }}
      data={data}
      {...rest}
    />
  );
};

export const DoughnutBarChart = ({ data, ...rest }: IDoughnutprops) => {
  return <Doughnut options={{ ...doughnutBarChart() }} data={data} {...rest} />;
};
