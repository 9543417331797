import React, { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import { IPaymentGateway } from 'interface/settingsInterface';

interface IProps {
  selectedProduct: IPaymentGateway;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  action: (_: IPaymentGateway) => void;
}

const AddGateWay: FC<IProps> = ({ selectedProduct, handleClose, action }) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IPaymentGateway>({
    defaultValues: selectedProduct,
  });

  const onSubmit = async (data: IPaymentGateway) => {
    setLoading(true);
    action(data);
    setLoading(false);
    handleClose(false);
  };

  return (
    <PopUpModal
      open={true}
      handleClose={() => handleClose(false)}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => {
            handleClose(false);
          },
        },
        {
          buttonType: 'positive',
          buttonLabel: selectedProduct?.ID ? 'Update' : 'Add',
          form: 'AddPaymentGatewayForm',
          type: 'submit',
          disabled: loading,
          loading: loading,
        },
      ]}
      heading={
        selectedProduct?.ID ? 'Update Payment Gateway' : 'Add Payment Gateway'
      }
      className="flex-container">
      <div className="popup-section">
        <form id="AddPaymentGatewayForm" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="Secret"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={value => onChange(value)}
                label="Phoenix Secret Token"
                className="filter_form_input"
                errorString={error?.message}
                fixedSize="md"
                placeholder="Enter Token"
              />
            )}
          />
        </form>
      </div>
    </PopUpModal>
  );
};

export default AddGateWay;
