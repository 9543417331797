import React, { useState, useEffect } from 'react';
import { FormWrapper } from '../form-wrapper';
import { TypedUseSelectorHook } from 'react-redux';
import { StoreProps } from '../../services/constants';

interface CssData {
  id: string;
  name?: string;
  link: string;
  added?: boolean;
  codeEditor?: boolean;
}

interface ScriptData {
  id: string;
  name?: string;
  link: string;
  added?: boolean;
  codeEditor?: boolean;
}

type RenderPageProps = {
  children: React.ReactNode;
  fontFamily: string;
  errorColor: string;
  customCss: Array<CssData>;
  customScripts: Array<ScriptData>;
  useAppSelector: TypedUseSelectorHook<StoreProps>;
};

const PuckRenderPage: React.FC<RenderPageProps> = ({
  children,
  fontFamily,
  errorColor = '#FF5A50',
  customCss,
  customScripts,
  useAppSelector,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    if (customCss) {
      customCss.map((css: CssData) => {
        if (css.name) {
          if (document.getElementById(`custom-css-${css.id}`)) {
            document.getElementById(`custom-css-${css.id}`)?.remove();
          }
          const cssElement = document.createElement('style');
          cssElement.id = `custom-css-${css.id}`;
          cssElement.textContent = `.puck-page{
          ${css.link}
        }`;
          document.head.appendChild(cssElement);
        }
      });
    }
  }, [customCss]);

  useEffect(() => {
    if (customScripts) {
      customScripts.map((script: ScriptData) => {
        if (script.name === 'Manual Script') {
          document.getElementById(`custom-script-${script.id}`)?.remove();
        }
        if (
          script.added &&
          !document.getElementById(`custom-script-${script.id}`)
        ) {
          const scriptElement = document.createElement('script');
          scriptElement.id = `custom-script-${script.id}`;
          !script?.codeEditor
            ? (scriptElement.src = script.link)
            : (scriptElement.textContent = script.link);
          document.body.appendChild(scriptElement);
        }
      });
    }
  }, [customScripts]);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <FormWrapper useAppSelector={useAppSelector}>
      <div
        className={`puck-page ${width <= 640 ? 'responsive-font' : ''}`}
        style={
          {
            height: 'calc(100vh - 0px)',
            overflow: 'auto',
            '--error-color': errorColor ?? '#FF5A50',
            fontFamily: fontFamily,
          } as React.CSSProperties
        }>
        {customCss &&
          customCss.map(
            (css: CssData, index: number) =>
              !css.name && (
                <link
                  key={index}
                  rel="stylesheet"
                  type="text/css"
                  href={css.link}></link>
              ),
          )}
        {children}
      </div>
    </FormWrapper>
  );
};

export default PuckRenderPage;
