import { DoughnutBarChart } from 'components/common/chart/barChart';
import { LifeTimetransactionStats } from 'interface/dashboardNewIterface';
import { FC, useMemo } from 'react';
import { getPercentageByValue } from 'utils/helper';

interface IProps {
  cardBreakDownStats: LifeTimetransactionStats[];
}
const CardBreakDown: FC<IProps> = ({ cardBreakDownStats }) => {
  const cardBreakDownData = useMemo(() => {
    let visa = 0;
    let masterCard = 0;
    let others = 0;
    cardBreakDownStats?.forEach(stat => {
      if (stat.CardBrand === 'visa') {
        visa += stat?.Total;
        return;
      }
      if (stat.CardBrand === 'mastercard') {
        masterCard += stat?.Total;
        return;
      }
      others += stat?.Total;
    });
    const total = visa + masterCard + others;
    return {
      legend: [
        { name: 'Master Card', color: '#F90182' },
        { name: 'VISA', color: '#6AD2FF' },
        { name: 'Others', color: '#C237F3' },
      ],
      chart: {
        labels: ['Master Card', 'VISA', 'Others'],
        datasets: [
          {
            data: [
              getPercentageByValue(masterCard, total),
              getPercentageByValue(visa, total),
              getPercentageByValue(others, total),
            ],
            count: [masterCard, visa, others],
            fill: false,
            backgroundColor: ['#F90182', '#6AD2FF', '#C237F3'],
            borderColor: ['#F90182', '#6AD2FF', '#C237F3'],
            borderWidth: 1,
          },
        ],
      },
      total,
    };
  }, [cardBreakDownStats]);

  return (
    <div className="lg_card_container">
      <div className="header">
        <p className="header_text">Card Breakdown</p>
      </div>
      <div className="footer p-2">
        {cardBreakDownData.legend.map(item => {
          return (
            <div className="footer_details" key={item?.name}>
              <div
                key={item?.name}
                className="indicator"
                style={{ backgroundColor: item?.color }}></div>
              <span className="footer_text">{item?.name}</span>
            </div>
          );
        })}
      </div>
      <div className="chart_wrapper">
        {cardBreakDownData?.chart?.datasets?.[0]?.count?.every(
          item => item === 0,
        ) ? (
          <div className="no_data">No Data</div>
        ) : (
          <DoughnutBarChart data={cardBreakDownData?.chart} className="chart" />
        )}
      </div>
    </div>
  );
};

export default CardBreakDown;
