import { useEffect, useState } from 'react';
import { ComponentConfig, FieldLabel } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { SketchPicker } from 'react-color';
import CustomTooltip from '../../../components/common/tooltip';
import InputField from '../../../components/common/input-field';

export type TimerProps = {
  backgroundColor: string;
  interval: number;
  saleText: string;
  saleTextColor: string;
  bodyText: string;
  bodyTextColor: string;
} & MarginProps;

export const Timer: ComponentConfig<TimerProps> = {
  label: (
    <CustomTooltip title="Timer">
      <span>Timer</span>
    </CustomTooltip>
  ) as React.ReactNode as string,
  fields: {
    backgroundColor: {
      type: 'custom',
      label: 'Background Color',
      render: ({ value, onChange }) => (
        <FieldLabel
          label={
            (
              <CustomTooltip title="backgroundColor">
                <span>Background Color</span>
              </CustomTooltip>
            ) as React.ReactNode as string
          }>
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
    interval: { type: 'number', label: 'Interval' },
    saleText: {
      type: 'custom',
      label: 'Sale Text',
      render: ({ value, onChange, field }) => (
        <InputField value={value} field={field} onChange={onChange} />
      ),
    },
    saleTextColor: {
      type: 'custom',
      label: 'Background Color',
      render: ({ value, onChange }) => (
        <FieldLabel
          label={
            (
              <CustomTooltip title="Sale Text Color">
                <span>Sale Text Color</span>
              </CustomTooltip>
            ) as React.ReactNode as string
          }>
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
    bodyText: {
      type: 'custom',
      label: 'Body Text',
      render: ({ value, onChange, field }) => (
        <InputField value={value} field={field} onChange={onChange} />
      ),
    },
    bodyTextColor: {
      type: 'custom',
      label: 'Background Color',
      render: ({ value, onChange }) => (
        <FieldLabel
          label={
            (
              <CustomTooltip title="Body Text Color">
                <span>Body Text Color</span>
              </CustomTooltip>
            ) as React.ReactNode as string
          }>
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
    ...marginFields,
  },
  defaultProps: {
    backgroundColor: '#ECFEFF',
    interval: 10 * 60,
    saleText: '50% OFF SALE ENDS TODAY!',
    saleTextColor: '#FF0000',
    bodyText:
      'Items in your cart are in high demand. No Worries, we reserved your order for',
    bodyTextColor: '#000000',
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({
    backgroundColor,
    interval,
    saleText,
    saleTextColor,
    bodyText,
    bodyTextColor,
    margin,
  }) => {
    const [time, setTime] = useState(interval);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      return () => clearInterval(intervalId);
    }, []);

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return (
      <div
        style={{ backgroundColor }}
        className={`rounded flex py-1 ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
        <p className="bg-red-700 text-white rounded-full w-3 h-3 flex items-center justify-center text-xxs flex-shrink-0 mt-3 mx-2">
          %
        </p>
        <div>
          <b className="text-lg font-bold" style={{ color: saleTextColor }}>
            {saleText}{' '}
          </b>
          <span className="text-lg" style={{ color: bodyTextColor }}>
            {bodyText}{' '}
          </span>
          <span className="text-red-500 font-bold text-lg text-center">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        </div>
      </div>
    );
  },
};
