import React from 'react';
import CreateIcon from '@mui/icons-material/Create';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import './style.scss';
import { Loader } from 'components/common/loader';
import { get } from 'lodash';
import { IStoreFrontEventsFilter } from 'interface/storeInterface';

import {
  IDeleteMerchantAccount,
  IMerchantAccountBody,
  IMerchantResponse,
} from 'interface/paymentGatewayInterface';
import globalPaymentGateWayService from '../../../services/paymentGatewayService';
import AddMerchant from './AddMerchant';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';
import { formattedDate } from 'utils/helper';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IMerchantResponse) => ReactNode;
  hide?: boolean;
  key: string;
  showInSearch?: boolean;
  showSortIcon?: boolean;
}

export const defaultStoreFrontEventValues: IStoreFrontEventsFilter = {
  eventName: '',
  time: '',
  resolved: 'no',
  storeFilter: {
    StoreIDs: [],
  },
  tags: [],
  ipAddress: '',
  StartTime: null,
  EndTime: null,
};

interface IProps {
  storeID: string;
}

export default function MerchantAccount({ storeID }: IProps) {
  const defaultValue: IMerchantResponse = {
    AcquiringBankName: '',
    DBAName: '',
    IssuedDate: '',
    MerchantID: '',
    Name: '',
    ProcessorName: '',
    ServiceProviderName: '',
    GatewayID: '',
    GatewayKind: ' ',
    ID: '',
    MCC: 0,
    StoreID: '',
    MonthlyLimitCents: 0,
    MonthlyTransactionLimit: 0,
    Version: 0,
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedData, setSelectedData] =
    useState<IMerchantResponse>(defaultValue);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [merchantDeatails, setMerchantDeatils] = useState<IMerchantResponse[]>(
    [],
  );
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const headCellList: readonly HeadCell[] = [
    {
      id: 'Name',
      label: 'Name',
      key: 'name',
      hide: false,
      showSortIcon: true,
      cellRender: row => {
        return row?.Name;
      },
    },
    {
      id: 'Name',
      label: 'Gateway',
      key: 'gateway',
      hide: false,
      showSortIcon: true,
      cellRender: row => {
        return row?.GatewayKind;
      },
    },
    {
      id: 'IssuedDate',
      label: 'Issued Date',
      key: 'issuedDate',
      hide: false,
      cellRender: row => {
        return formattedDate(row?.IssuedDate, true);
      },
    },
    {
      id: 'ProcessorName',
      label: 'Merchant Processor',
      key: 'merchantProcessor',
      hide: false,
      cellRender: row => {
        return row?.ProcessorName;
      },
    },
    {
      id: 'ServiceProviderName',
      label: 'Service Provider',
      key: 'service',
      hide: false,
      cellRender: row => {
        return row?.ServiceProviderName;
      },
    },
    {
      id: 'AcquiringBankName',
      label: 'Acquiring Bank',
      key: 'acqiringBank',
      hide: false,
      cellRender: row => {
        return row?.AcquiringBankName;
      },
    },
    {
      id: 'DBAName',
      label: 'DBA',
      key: 'dba',
      hide: false,
      cellRender: row => {
        return row?.DBAName;
      },
    },
    {
      id: 'MerchantID',
      label: 'Merchant ID',
      key: 'merchantId',
      hide: false,
      cellRender: row => {
        return row?.MerchantID;
      },
    },
    {
      id: 'MCC',
      label: 'MCC',
      key: 'mcc',
      hide: false,
      cellRender: row => {
        return row?.MerchantID;
      },
    },
    {
      id: 'RevenueLimit',
      label: 'Revenue Limit',
      key: 'revenueLimit',
      hide: false,
      cellRender: row => {
        return row?.MonthlyLimitCents ?? '-';
      },
    },
    {
      id: 'transactionLimit',
      label: 'Transaction Limit',
      key: 'transacrionLimit',
      hide: false,
      cellRender: row => {
        return row?.MonthlyTransactionLimit ?? '-';
      },
    },
    {
      id: 'Actions',
      label: 'Actions',
      key: 'action',
      hide: false,
      cellRender: row => {
        return (
          <div className="icons">
            <CreateIcon
              className="create"
              onClick={() => {
                setShowPopup(true);
                setSelectedData(row);
              }}
            />
            <DeleteIcon
              className="delete"
              onClick={() => {
                setIsDelete(true);
                setSelectedData(row);
              }}
            />{' '}
          </div>
        );
      },
    },
  ];

  const deleteMerchantProcessor = async () => {
    const payload: IDeleteMerchantAccount = {
      ProcessorAccountIDs: [selectedData?.ID ?? ''],
      StoreID: selectedData.StoreID,
    };
    const res =
      await globalPaymentGateWayService.deleteProcessorAccounts(payload);
    if (res.status === 200) {
      searchStoreFrontEvents();
      setIsDelete(false);
    }
  };

  const searchStoreFrontEvents = async () => {
    const payload: IMerchantAccountBody = {
      Descending: false,
      OrderBy: 'Name',
      Limit: 25,
      Page: 0,
      StoreIDs: [storeID],
    };

    setLoading(true);
    const res = await globalPaymentGateWayService.getProcessorAccounts(payload);
    setLoading(false);
    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        setMerchantDeatils(res?.data?.Result);
      } else {
        setMerchantDeatils([]);
      }
    } else {
      setMerchantDeatils([]);
    }
  };

  useEffect(() => {
    searchStoreFrontEvents();
  }, []);

  return (
    <>
      <div className="proccessor">
        <div className="h-full">
          <TableContainer className="Common_Table !h-fit max-h-full">
            <Table className="table" aria-labelledby="tableTitle" stickyHeader>
              <TableHead className="table_header">
                <TableRow>
                  <TableCell className="table_header_cell" />
                  {headCellList.map(headCell => {
                    if (headCell.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        className="table_header_cell header_text"
                        key={headCell.label}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="table_body">
                {merchantDeatails?.length > 0 ? (
                  merchantDeatails.map((row, index) => {
                    return (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}>
                          <TableCell />

                          {headCellList.map(headCell => {
                            if (headCell.hide) {
                              return null;
                            }
                            return (
                              <TableCell
                                className="table_cell"
                                key={headCell.label}
                                component="th"
                                id={headCell.id}
                                scope="row">
                                <>
                                  {headCell?.cellRender
                                    ? headCell.cellRender(row)
                                    : get(row, headCell.id)}
                                </>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className="!py-20" colSpan={15}>
                      <div className="no-data-row">No data found</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={15}>
                    <button
                      className="sticky-button flex items-center"
                      onClick={() => {
                        setShowPopup(true);
                        setSelectedData(defaultValue);
                      }}>
                      <AddIcon /> Add Processor
                    </button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Loader loading={loading} />

      {showPopup && (
        <AddMerchant
          defaultStoreValues={selectedData}
          handleClose={() => setShowPopup(false)}
          onSuccess={() => searchStoreFrontEvents()}
        />
      )}
      <ConfirmationDialog
        open={isDelete}
        handleClose={() => setIsDelete(false)}
        message="delete merchant processor"
        onSubmit={() => deleteMerchantProcessor()}
      />
    </>
  );
}
