import Dropdown from 'react-multilevel-dropdown';
import './style.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IGetStores, IStore } from 'interface/storeInterface';
import { getStores } from 'services/dashboardService';
import globalStoreFrontService from 'services/storeFrontService';
import { IStores, setStore } from 'redux-setup/slices/StoreIdSlice';

import { IStoreFront } from 'interface/settingsInterface';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/reduxHooks';
import { debounce } from 'lodash';
import { MultipleInputStoreSearch } from 'components/common/multiInputStoreSearch';
interface IMultiLevelField {
  toggle: () => void;
}

const defaultValue: IGetStores = {
  Descending: false,
  Limit: 25,
  OrderBy: 'Name',
  Page: 0,
};

export default function CustomMultiSelectComponent() {
  const inputValue = useRef<string>('');
  const storeIdfromRedux = useAppSelector(root => root.storeIds.storeIds);
  const [stores, setStores] = useState<IStore[]>([]);
  const [value, setValue] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [storeFronts, setStoreFront] = useState<IStoreFront[]>([]);
  const multiLevelField = useRef<IMultiLevelField>(null);
  const storeListElement = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isNextAPIDisabled, setIsNextAPIDisabled] = useState<boolean>(false);

  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !isNextAPIDisabled) {
          defaultValue.Page = defaultValue.Page + 1;
          searchStoreList();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isNextAPIDisabled],
  );

  const searchStoreList = async () => {
    const res = await getStores(defaultValue);
    if (res.status !== 200) {
      setStores([]);
      return;
    }

    if (res?.data && res?.data?.Result) {
      setIsNextAPIDisabled(res?.data?.Result?.length < defaultValue.Limit);
      if (defaultValue.Page === 0) {
        setStores(res.data.Result);
      } else {
        setStores(prevData => [...prevData, ...res.data.Result]);
      }
    } else setStores([]);
  };

  const getStoreFronts = async (id: string) => {
    setStoreFront([]);
    setLoading(true);
    const res = await globalStoreFrontService.getStoreFronts(id);
    setLoading(false);
    if (res?.data && res.data?.Result) {
      setStoreFront(res.data?.Result);
    } else setStoreFront([]);
  };

  useEffect(() => {
    searchStoreList();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleStoreMouseMove = (e: any) => {
      const targetElement = e.target as Element;
      if (
        (targetElement && targetElement.localName === '.nested-submenu') ||
        targetElement.closest('.nested-submenu')
      )
        return;
      const valueY =
        e.layerY /
        (storeListElement?.current?.children[1]?.getBoundingClientRect()
          .height || 1);
      storeListElement.current?.style.setProperty(
        '--mouse-y',
        valueY * 100 + '%',
      );
    };

    storeListElement.current?.addEventListener('mousemove', e =>
      handleStoreMouseMove(e),
    );

    return () => {
      storeListElement.current?.removeEventListener('mousemove', e =>
        handleStoreMouseMove(e),
      );
    };
  }, []);

  useEffect(() => {
    if (storeIdfromRedux?.length) getTagsValue();
    else {
      setValue([]);
    }
  }, [storeIdfromRedux]);

  const getTagsValue = () => {
    const valuesTag: string[] = [];
    storeIdfromRedux?.forEach(store => {
      let val = store.Name;
      store?.storeFronts?.forEach((value, index) => {
        index === 0
          ? (val += ' > ' + value?.Name)
          : (val += ', ' + value?.Name);
      });
      valuesTag.push(val);
    });
    setValue(valuesTag);
  };

  const handleChangeWithDebounce = useCallback(
    debounce(searchText => {
      if (!isNextAPIDisabled) {
        defaultValue.Page = 0;
        defaultValue.Name = searchText;
        searchStoreList();
      }
    }, 500),
    [],
  );

  const handleChangeDropdownWithDebounce = useCallback(
    debounce(id => {
      getStoreFronts(id);
    }, 500),
    [],
  );

  const resetStores = () => {
    defaultValue.Page = 0;
    defaultValue.Name = '';
    searchStoreList();
  };

  return (
    <div className="userPermissionss">
      <div
        className="grid multiLevelSelect store_multiLevelSelect"
        ref={storeListElement}>
        <MultipleInputStoreSearch
          onKeyDown={false}
          ishandleClickOutside={false}
          onInputChange={searchText => {
            handleChangeWithDebounce(searchText);
          }}
          limits={3}
          tagsValue={value || []}
          label=""
          refForClearInput={inputValue}
          onClick={() => {
            multiLevelField.current?.toggle();
          }}
          placeholder="Select Store"
          onChange={(e: string[]) => setValue(e)}
          onDelete={index => {
            multiLevelField.current?.toggle();
            dispatch(setStore(storeIdfromRedux?.filter((_, i) => i !== index)));
          }}
        />
        <Dropdown
          ref={multiLevelField}
          buttonClassName="dropdown-input w-full !justify-start"
          menuClassName="dropdown-list stores-dropdown-list">
          <>
            <span className="dropdown-title">Stores</span>
            {stores?.length > 0 ? (
              stores?.map((store, index) => (
                <Dropdown.Item
                  key={store.ID}
                  className={`${storeIdfromRedux?.find(val => val.ID === store.ID) ? 'active' : ''} !p-0`}
                  onClick={() => {
                    resetStores();
                    inputValue.current = 'clear';
                    if (storeIdfromRedux?.find(val => val.ID === store.ID)) {
                      dispatch(
                        setStore(
                          storeIdfromRedux?.filter(
                            value => value.ID !== store.ID,
                          ),
                        ),
                      );
                    } else {
                      dispatch(setStore([...storeIdfromRedux, store]));
                    }
                  }}>
                  <div
                    {...(stores?.length === index + 1
                      ? { ref: lastElementRef }
                      : {})}
                    className={`w-full p-2.5`}
                    onMouseEnter={async () => {
                      setLoading(true);
                      handleChangeDropdownWithDebounce(store.ID);
                    }}>
                    {store.Name}
                  </div>

                  <Dropdown.Submenu
                    className="nested-submenu main-submenu"
                    position="right">
                    <>
                      <span
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        className="dropdown-title">
                        Store Fronts
                      </span>
                      {!loading ? (
                        storeFronts?.length > 0 ? (
                          storeFronts?.map(value => (
                            <Dropdown.Item
                              className={`${
                                storeIdfromRedux?.length &&
                                storeIdfromRedux?.some(
                                  store =>
                                    store.ID === store.ID &&
                                    store?.storeFronts?.length &&
                                    store.storeFronts?.some(
                                      item => item.ID === value.ID,
                                    ),
                                )
                                  ? 'active'
                                  : ''
                              } nested-menu-item`}
                              key={value.ID}
                              onClick={e => {
                                e.stopPropagation();
                                inputValue.current = 'clear';
                                resetStores();
                                const stores: IStores | undefined =
                                  storeIdfromRedux?.find(
                                    val => val.ID === store.ID,
                                  );
                                if (stores) {
                                  if (
                                    stores.storeFronts?.length &&
                                    stores.storeFronts?.some(
                                      item => item.ID === value.ID,
                                    )
                                  ) {
                                    dispatch(
                                      setStore(
                                        storeIdfromRedux?.map(stores => {
                                          if (stores.ID === store.ID) {
                                            return {
                                              ...stores,
                                              storeFronts: [
                                                ...(stores?.storeFronts
                                                  ?.length > 0
                                                  ? stores.storeFronts
                                                  : []
                                                ).filter(
                                                  item => item.ID !== value.ID,
                                                ),
                                              ],
                                            };
                                          }
                                          return stores;
                                        }),
                                      ),
                                    );
                                  } else
                                    dispatch(
                                      setStore(
                                        storeIdfromRedux?.map(stores => {
                                          if (stores?.ID === store?.ID) {
                                            return {
                                              ...stores,
                                              storeFronts: [
                                                ...(stores?.storeFronts
                                                  ?.length > 0
                                                  ? stores.storeFronts
                                                  : []),
                                                value,
                                              ],
                                            };
                                          }
                                          return stores;
                                        }),
                                      ),
                                    );
                                } else {
                                  dispatch(
                                    setStore([
                                      ...storeIdfromRedux,
                                      { ...store, storeFronts: [value] },
                                    ]),
                                  );
                                }
                              }}>
                              {value?.Name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <div
                            className="p-2.5"
                            onClick={e => {
                              e.stopPropagation();
                            }}>
                            No Data
                          </div>
                        )
                      ) : (
                        <div
                          className="p-2.5 color-"
                          onClick={e => {
                            e.stopPropagation();
                          }}>
                          Loading...
                        </div>
                      )}
                    </>
                  </Dropdown.Submenu>
                </Dropdown.Item>
              ))
            ) : (
              <div className="flex align-center justify-center py-5">
                No Data
              </div>
            )}
          </>
        </Dropdown>
      </div>
    </div>
  );
}
