import { useMemo, FC } from 'react';
import { SubscriptionStats } from 'interface/dashboardInterface';
import { GroupedLineChart } from './LineChart';

interface IProps {
  groupedSubscriptionStats: [string, SubscriptionStats[]][];
}
interface IDatasets {
  subscription: number[];
  newSubscription: number[];
  canceledSubscriptions: number[];
}

const NetSubscribers: FC<IProps> = ({ groupedSubscriptionStats }) => {
  const netSubscriberChartData = useMemo(() => {
    const values: IDatasets = {
      subscription: [],
      newSubscription: [],
      canceledSubscriptions: [],
    };
    groupedSubscriptionStats.forEach(([, statsData]) => {
      let cumulativeSubscription = 0;
      let canceledSubscriptions = 0;
      let newSubscription = 0;
      statsData.forEach(stat => {
        cumulativeSubscription +=
          stat.NewSubscriptions +
          stat.RenewedSubscriptions -
          stat.CanceledSubscriptions;
        newSubscription += stat?.NewSubscriptions + stat?.RenewedSubscriptions;
        canceledSubscriptions -= stat?.CanceledSubscriptions;
      });
      values.subscription.push(cumulativeSubscription);
      values.newSubscription.push(newSubscription);
      values.canceledSubscriptions.push(canceledSubscriptions);
    });

    return {
      chart: {
        labels: groupedSubscriptionStats.map(([date]) => date),
        datasets: [
          {
            label: 'Net Subscribers',
            pointBorderColor: 'white',
            pointBackgroundColor: '#F90182',
            borderColor: '#F90182',
            legend: 'Net',
            data: values?.subscription,
            total: values?.subscription.reduce(
              (prev, current) => prev + current,
              0,
            ),
          },
          {
            label: 'New Subscribers',
            legend: 'New',
            pointBorderColor: 'white',
            pointBackgroundColor: '#05CD99',
            borderColor: '#05CD99',
            data: values?.newSubscription,
            total: values?.newSubscription?.reduce(
              (prev, current) => prev + current,
              0,
            ),
          },
          {
            label: 'Cancel Subscribers',
            legend: 'Cancel',
            pointBorderColor: 'white',
            pointBackgroundColor: '#04BEFE',
            borderColor: '#04BEFE',
            data: values?.canceledSubscriptions,
            total: values?.canceledSubscriptions?.reduce(
              (prev, current) => prev + current,
              0,
            ),
          },
        ],
      },
    };
  }, [groupedSubscriptionStats]);

  return (
    <div className="lg_card_container ">
      <div className="header">
        <p className="header_text">Net Subscribers</p>
      </div>
      <div className="body">
        <div className="left_side_details">
          <div className="stats_details">
            {netSubscriberChartData?.chart?.datasets?.map(data => {
              return (
                <div key={data?.label}>
                  <p className="head_text">
                    <span
                      className="indicator"
                      style={{ background: data?.pointBackgroundColor }}
                    />
                    {data?.legend}
                  </p>
                  <p className="sub_text">{data?.total}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="chart_wrapper">
          <GroupedLineChart
            data={netSubscriberChartData?.chart}
            className="chart"
            isLabelRequired={true}
            labels={netSubscriberChartData?.chart?.labels}
          />
        </div>
      </div>
    </div>
  );
};

export default NetSubscribers;
