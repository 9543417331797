import { ComponentConfig } from '@measured/puck';
import 'react-quill/dist/quill.snow.css';
import { QuillTextEditor } from '../../../page-builder/puck/reusable-props/quill-editor';
import CustomTooltip from '../../../components/common/tooltip';

export type TextEditorProps = {
  content: string;
};

export const TextEditor: ComponentConfig<TextEditorProps> = {
  label: (
    <CustomTooltip title="TextEditor">
      <span>Text Editor</span>
    </CustomTooltip>
  ) as React.ReactNode as string,
  fields: {
    content: {
      ...QuillTextEditor('Content').content,
    },
  },
  defaultProps: {
    content: '',
  },
  render: ({ content }) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  },
};
