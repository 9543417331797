/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { InputHTMLAttributes, useMemo } from 'react';
import { ReactComponent as ActionIcon } from 'assets/icons/action-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';
import { ReactComponent as ColoumnIcon } from 'assets/icons/columnFilter-icon.svg';
import FilterForm from 'components/customers/customersFilter/filterForm';
import ColumnFilter from 'components/customers/customersFilter/columnFilter';
import ActionFilter from 'components/customers/customersFilter/actionFilter';
import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';

import { HeadCell } from 'components/customers';
import { ICustomerDetails, IFormFilter } from 'interface/customerInterface';
import ExportFilter from 'components/customers/customersFilter/exportFilter';
import FilterAction from 'components/common/filterAction';
import { setMyColoumnDetails } from 'redux-setup/slices/ColoumnFilterSlice';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
import { userPermissionKeys } from 'utils/constants';

interface ISideFilter extends InputHTMLAttributes<HTMLInputElement> {
  headCellList: HeadCell[];
  setHeadCellList: (headCell: HeadCell[]) => void;
  selected: Map<string, ICustomerDetails>;
  sideFormFilter: IFormFilter;
  filterSubmission: (_formData: IFormFilter) => void;
  searchCustomerList: () => void;
  setCustomersList: React.Dispatch<React.SetStateAction<ICustomerDetails[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  resetSelected: () => void;
  downloadCsvFile: () => void;
}
const filterActionList = ['Change Subscription', 'Blacklist', 'Tag'];

const SideFilter: React.FC<ISideFilter> = ({
  headCellList,
  setHeadCellList,
  selected,
  sideFormFilter,
  filterSubmission,
  searchCustomerList,
  setCustomersList,
  setLoading,
  resetSelected,
  downloadCsvFile,
}) => {
  const dispatch = useAppDispatch();
  const { filterSource, currentFilter } = useAppSelector(
    state => state.pathConfig,
  );
  const { customer } = useAppSelector(state => state.permission);
  const { customerColumnFilter } = useAppSelector(state => state.coloumnFilter);
  const filterAction = [
    { key: 'Filters', icon: FilterIcon, className: 'margin_filter' },
    { key: 'Actions', icon: ActionIcon, permission: userPermissionKeys.manage },
    { key: 'Column Filters', icon: ColoumnIcon },
    { key: 'Export as', icon: DownloadIcon },
  ];

  const setSelectedFilter = (filter: string) => {
    dispatch(
      setPageConfig({ filterSource: 'customers', currentFilter: filter }),
    );
  };

  const handleChange = (val: string) => {
    if (customerColumnFilter.indexOf(val) === -1) {
      dispatch(
        setMyColoumnDetails({
          customerColumnFilter: [...customerColumnFilter, val],
        }),
      );
    } else {
      dispatch(
        setMyColoumnDetails({
          customerColumnFilter: [
            ...customerColumnFilter.filter(each => each !== val),
          ],
        }),
      );
    }
    setHeadCellList(
      headCellList.map((each: HeadCell) =>
        each.label === val ? { ...each, hide: !each.hide } : each,
      ),
    );
  };

  const filteredActions = useMemo(() => {
    let isBlackList = false;
    let noSubscription = false;
    let list: string[] = [];

    if (selected) {
      [...selected.values()].every(value => {
        if (value.IsBlacklisted) isBlackList = true;
        if (!value?.Subscription?.Status) noSubscription = true;
      });

      list = filterActionList;
      if (noSubscription)
        list = list.filter(value => value !== 'Change Subscription');
      return isBlackList ? list.filter(value => value !== 'Blacklist') : list;
    } else {
      return filterActionList;
    }
  }, [selected]);

  const renderComponent = () => {
    switch (currentFilter) {
      case 'Filters':
        return (
          <FilterForm
            sideFormFilter={sideFormFilter}
            filterSubmission={filterSubmission}
          />
        );
      case 'Column Filters':
        return (
          <ColumnFilter
            filterList={headCellList}
            setSelectedColumns={handleChange}
          />
        );
      case 'Actions':
        return (
          <ActionFilter
            filterList={filteredActions}
            selected={selected}
            searchCustomerList={searchCustomerList}
            setLoading={setLoading}
            setCustomersList={setCustomersList}
            resetSelected={resetSelected}
          />
        );
      case 'Export as':
        return <ExportFilter downloadCsvFile={downloadCsvFile} />;
      default:
        break;
    }
  };

  return (
    <div className="side_filter_container">
      <FilterAction
        className="transaction_side_filtericon"
        filterAction={filterAction}
        permissionOfPage={customer}
        setSelectedFilter={setSelectedFilter}
      />
      {!!currentFilter && filterSource === 'customers' && (
        <div className="filter_selected_wrapper">
          <div className="filter_header">
            <p className="filter_section_Header">{currentFilter}</p>
            <CloseIcon
              className="close_ison"
              onClick={() => dispatch(setCurrentFilter(''))}
            />
          </div>
          <div className="filter_container">{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default SideFilter;
