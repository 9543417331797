import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from 'components/common/loader';
import { get } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import WebIcon from '@mui/icons-material/Web';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import CreateIcon from '@mui/icons-material/Create';
import storeService from 'services/storeService';
import AddPages from 'components/Actions/addPages';
import { PopUpModal } from 'shared-components';
import { ICheckoutPage } from 'interface/checkoutPagesInterface';
import { getPagePath } from 'router/constant';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: ICheckoutPage) => ReactNode;
  hide?: boolean;
}
export interface IPagesProps {
  storeId?: string;
  shopifyId?: string;
  shopId?: string;
}

export default function Pages({ storeId, shopifyId, shopId }: IPagesProps) {
  const storeDetails: { StoreID: string; ChannelID: string } = {
    ChannelID: shopifyId ? shopifyId : '',
    StoreID: storeId ? storeId : '',
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<ICheckoutPage[]>([]);
  const search = {
    StoreID: storeDetails.StoreID,
    Descending: false,
    Limit: 25,
    OrderBy: 'Name',
    Page: 0,
    SearchFields: {},
  };
  const [pagesSearch, setPagesSearch] = useState(search);
  const [configurePage, setConfigurePage] = useState<{
    open: boolean;
    currentRow?: ICheckoutPage;
  }>({
    open: false,
  });
  const [deletePopup, setDeletePopup] = useState<{
    open: boolean;
    currentRow?: ICheckoutPage;
  }>({ open: false });

  const [isNextAPIDisabled, setIsNextAPIDisabled] = useState<boolean>(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !isNextAPIDisabled) {
          setPagesSearch(prevPage => ({
            ...prevPage,
            Page: prevPage.Page + 1,
          }));
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isNextAPIDisabled],
  );

  const openEditor = (row: ICheckoutPage) => {
    const query = new URLSearchParams(location.search);
    query.set('pageID', row?.ID || '');
    query.set('storeID', storeId || '');
    query.set('store', shopId || '');
    query.set('cart', 'PHOENIX_CART_PREVIEW');
    query.set('template', row?.PageType);
    window.open(getPagePath(query));
  };

  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Name',
      hide: false,
      cellRender: row => {
        return <>{row.Name}</>;
      },
    },
    {
      id: 'PageType',
      label: 'PageType',
      hide: false,
      cellRender: row => {
        return <>{row.PageType}</>;
      },
    },
    //
    // {
    //   id: 'LastUpdate',
    //   label: 'Last Update',
    //   hide: false,
    //   cellRender: row => {
    //     return <>{row.Status}</>;
    //   },
    // },
    {
      id: 'tags',
      label: 'Tags',
      hide: false,
      cellRender: row => {
        return <>{row.Tags}</>;
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <div className="flex items-center justify-end gap-3">
            <WebIcon
              className="add_store cursor-pointer"
              onClick={() => {
                openEditor(row);
              }}
            />
            <CreateIcon
              className="add_store cursor-pointer"
              onClick={() => {
                setConfigurePage({ open: true, currentRow: row });
              }}
            />
            <DeleteIcon
              className="add_store cursor-pointer"
              onClick={() => {
                setDeletePopup({ open: true, currentRow: row });
              }}
            />
          </div>
        );
      },
    },
  ];

  const getPages = async () => {
    setLoading(true);
    const res = await storeService.getCheckoutPage(pagesSearch);
    setLoading(false);
    if (res?.data && res?.data?.Result) {
      const pagesResult =
        pagesSearch.Page === 0
          ? res.data.Result
          : [...pages, ...res.data.Result];

      setPages(pagesResult);
      setIsNextAPIDisabled(
        res.data.TotalCount === pagesResult.length ||
          res.data.Result.length < pagesSearch.Limit,
      );
    } else {
      setPages([]);
    }
  };

  const deletePage = async () => {
    const res = await storeService.deleteCheckoutPage({
      PageID: deletePopup.currentRow?.ID || '',
      StoreID: deletePopup.currentRow?.StoreID || '',
    });
    if (res?.status === 200) {
      setPagesSearch({ ...search });
      setDeletePopup({ open: false });
    }
  };

  useEffect(() => {
    getPages();
  }, [pagesSearch]);

  // useEffect(() => {
  //   if (storeId && shopifyId) {
  //     setPagesSearch({ ...search });
  //   } else {
  //     setPages([]);
  //   }
  // }, [storeId, shopifyId]);
  return (
    <>
      <div className="h-full">
        <TableContainer className="Common_Table !h-fit max-h-full">
          <Table aria-labelledby="tableTitle" stickyHeader>
            <TableHead>
              <TableRow>
                {headCellList.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell
                      className={
                        headCell.label == 'Actions' ? '!text-right' : ''
                      }
                      key={headCell.label}>
                      {headCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {pages && pages?.length > 0 ? (
                pages?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      ref={index === pages.length - 1 ? lastElementRef : null}>
                      {headCellList.map(headCell => {
                        if (headCell.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            key={headCell.label}
                            component="th"
                            id={headCell.id}
                            scope="row">
                            <>
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="!py-20" colSpan={15}>
                    <div className="no-data-row">No data found</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={10}>
                  <button
                    className="sticky-button flex items-center"
                    onClick={() => setConfigurePage({ open: true })}>
                    <AddIcon /> Add Page
                  </button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <Loader loading={loading} />
      {configurePage.open && (
        <AddPages
          storeDetails={storeDetails}
          currentRow={configurePage.currentRow}
          handleClose={refetch => {
            if (refetch) setPagesSearch({ ...search });
            setConfigurePage({ open: false });
          }}
          isAdd={!configurePage.currentRow?.ID}
        />
      )}
      <PopUpModal
        heading={`Delete Page`}
        open={deletePopup.open}
        handleClose={() => setDeletePopup({ open: false })}
        buttons={[
          {
            buttonLabel: 'Cancel',
            buttonType: 'negative',
            buttonFunction: () => setDeletePopup({ open: false }),
          },
          {
            buttonLabel: 'Delete page',
            buttonType: 'positive',
            buttonFunction: deletePage,
          },
        ]}>
        <p className="text-white">
          Are you sure you want to delete the page{' '}
          <span className="font-medium">{deletePopup?.currentRow?.Name}</span>?
        </p>
      </PopUpModal>
    </>
  );
}
