import { ComponentConfig, FieldLabel } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import {
  PaddingProps,
  paddingFields,
} from '../../../page-builder/puck/reusable-props/padding';
import {
  JustifyContentField,
  JustifyContentProps,
} from '../../../page-builder/puck/reusable-props/justify-content';
import {
  fontField,
  FontProps,
} from '../../../page-builder/puck/reusable-props/font';
import {
  AlignItemsProps,
  alignItemsField,
} from '../../../page-builder/puck/reusable-props/align-items';
import { Vip } from './vip';
import { QuillTextEditor } from '../../../page-builder/puck/reusable-props/quill-editor';
import { ReduxSelectorProps } from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import InputField from '../../../components/common/input-field';

export type VipProps = {
  title: string;
  description: string;
  vipClubBenefitTitle: string;
  vipClubBenefits: string;
  content: string;
};

export type VipComponentProps = VipProps &
  MarginProps &
  PaddingProps &
  JustifyContentProps &
  FontProps &
  AlignItemsProps;

export const VipComponent = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<VipComponentProps> => {
  return {
    label: (
      <CustomTooltip title="VipComponent">
        <span>VIP Component</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      title: {
        type: 'custom',
        label: 'Title',
        render: ({ value, onChange, field }) => (
          <InputField value={value} field={field} onChange={onChange} />
        ),
      },
      description: {
        type: 'custom',
        label: 'Description',
        render: ({ value, onChange, field }) => (
          <InputField value={value} field={field} onChange={onChange} />
        ),
      },
      vipClubBenefitTitle: {
        type: 'custom',
        label: 'Benefits Title',
        render: ({ value, onChange, field }) => (
          <InputField value={value} field={field} onChange={onChange} />
        ),
      },
      vipClubBenefits: {
        type: 'custom',
        render: ({ value, name, onChange }) => {
          const handleChange = async (
            e: React.ChangeEvent<HTMLTextAreaElement>,
          ) => {
            onChange(e.target.value);
          };
          return (
            <FieldLabel label="Benefits Description">
              <textarea
                className="w-full rounded-md border-gray-300 shadow-sm   focus:ring outline-[#3479be] focus:ring-indigo-200 focus:ring-opacity-50 pagebuilder-theme"
                id={name}
                name={name}
                rows={5}
                value={value}
                placeholder="Enter benefits description by comma separated values"
                onChange={handleChange}
              />
            </FieldLabel>
          );
        },
      },
      content: {
        ...QuillTextEditor('Cancellation Description').content,
      },
      ...marginFields,
      ...paddingFields,
      ...JustifyContentField,
      ...fontField,
      ...alignItemsField,
    },
    defaultProps: {
      title: 'VIP Club',
      description:
        'By taking advantage of this offer, you will be signed up for our exclusive VIP Club program.  ',
      vipClubBenefitTitle:
        'The VIP Club is an exclusive program that provides you with',
      vipClubBenefits: `Access to VIP pricing on all website products.,
    Access to hundreds of trending products through the VIP Portal.,
    Lowest price guarantee on all VIP Portal orders.,
    Priority 24/7 customer support.`,
      content: '',
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      padding: {
        desktop: {
          top: 'pt-4',
          bottom: 'pb-4',
          right: 'pr-4',
          left: 'pl-4',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },

      justifyContent: 'justify-center',
      font: {
        size: 'text-2xl',
        weight: 'font-normal',
      },
      alignItems: 'items-center',
    },
    render: ({
      margin,
      padding,
      justifyContent,
      alignItems,
      title,
      description,
      vipClubBenefitTitle,
      vipClubBenefits,
      content,
    }) => {
      return (
        <div
          className={`flex  w-full ${justifyContent} ${alignItems}
        ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
        ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
        ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}
        ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
        ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
        ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}`}>
          <Vip
            title={title}
            description={description}
            vipClubBenefitTitle={vipClubBenefitTitle}
            vipClubBenefits={vipClubBenefits}
            content={content}
            useAppSelector={useAppSelector}
          />
        </div>
      );
    },
  };
};
