import { Navigate, Outlet, matchPath, useLocation } from 'react-router-dom';
import { Path } from 'router/constant';

type Props = {
  auth: {
    isAuthenticated: string;
  };
};

export const PrivateWrapper = ({ auth: { isAuthenticated } }: Props) => {
  const location = useLocation();

  if (!isAuthenticated) {
    localStorage.setItem('navigate_url', location.pathname + location.search);
  } else {
    localStorage.removeItem('navigate_url');
  }

  if (!isAuthenticated && matchPath(Path.INVITE_USER, location.pathname)) {
    localStorage.setItem('invite_user_url', location.pathname);
  }
  return isAuthenticated ? <Outlet /> : <Navigate to={Path.LOGIN} />;
};
