import { DoughnutBarChart } from 'components/common/chart/barChart';
import { LifeTimetransactionStats } from 'interface/dashboardNewIterface';
import { FC, useMemo } from 'react';
import { getPercentageByValue } from 'utils/helper';

interface IProps {
  aggregatedTransactionStats: LifeTimetransactionStats[];
}
const TransactionStatus: FC<IProps> = ({ aggregatedTransactionStats }) => {
  const transactionStatusData = useMemo(() => {
    let declines = 0;
    let chargebacks = 0;
    let approvals = 0;
    let refunds = 0;
    aggregatedTransactionStats?.length &&
      aggregatedTransactionStats?.forEach(stat => {
        declines += stat.Declined;
        chargebacks += stat.Chargebacks;
        approvals += stat.Approved;
        refunds += stat.Refunds;
      });
    const total = declines + chargebacks + approvals + refunds;
    return {
      legend: [
        { name: 'Declines', color: '#F90182' },
        { name: 'Chargebacks', color: '#6AD2FF' },
        { name: 'Approvals', color: '#C237F3' },
        { name: 'Refunds', color: '#F36337' },
      ],
      chart: {
        labels: ['Declines', 'Chargebacks', 'Approvals', 'Refunds'],
        datasets: [
          {
            data: [
              getPercentageByValue(declines, total),
              getPercentageByValue(chargebacks, total),
              getPercentageByValue(approvals, total),
              getPercentageByValue(refunds, total),
            ],
            count: [declines, chargebacks, approvals, refunds],
            fill: false,
            backgroundColor: ['#F90182', '#6AD2FF', '#C237F3', '#F36337'],
            borderColor: ['#F90182', '#6AD2FF', '#C237F3', '#F36337'],
          },
        ],
      },
      total,
    };
  }, [aggregatedTransactionStats]);

  return (
    <div className="lg_card_container">
      <div className="header">
        <p className="header_text">Transaction Status</p>
      </div>
      <div className="footer">
        {transactionStatusData.legend.map((item, index) => {
          return (
            <div
              className="flex flex-col justify-center align-middle gap-0.5"
              key={item.name}>
              <div className="footer_details">
                {' '}
                <div
                  key={item.name}
                  className="indicator"
                  style={{ backgroundColor: item.color }}></div>
                <span className="footer_text">{item.name}</span>
              </div>

              <div className="transaction_values">{`${transactionStatusData?.chart?.datasets?.[0]?.count?.[index]} (${transactionStatusData?.chart?.datasets?.[0]?.data?.[index]}%)`}</div>
            </div>
          );
        })}
      </div>
      <div className="chart_wrapper min-h-52">
        {transactionStatusData.chart.datasets?.[0]?.count?.every(
          count => count === 0,
        ) ? (
          <div className="no_data">No Data</div>
        ) : (
          <DoughnutBarChart
            data={transactionStatusData.chart}
            className="chart"
          />
        )}
      </div>
    </div>
  );
};

export default TransactionStatus;
