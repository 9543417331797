import { FC } from 'react';
import { ISubscriptionEventsFilter } from 'interface/subscriptionInterface';
import { Controller, useForm } from 'react-hook-form';
import { Button, Select } from 'shared-components';
import TextField from 'components/common/textField';
import { IDefaultOption } from 'interface/customerInterface';
import { MultipleInputTag } from 'components/common/multiInput';
import 'components/subscriptionEvents/filter/FilterForm/style.scss';
import dayjs from 'dayjs';
import { defaultSubscriptionEventsFiltersValues } from 'components/subscriptionEvents/';
import MultiSelect from 'components/common/multiSelect';
import { emailRegex } from 'utils/constants';
import DatePicker from 'components/common/datePicker';

interface IProps {
  sideFilter: ISubscriptionEventsFilter;
  filterSubmission: (_data: ISubscriptionEventsFilter) => void;
  setPage: (_page: number) => void;
}

const FilterForm: FC<IProps> = ({ sideFilter, filterSubmission, setPage }) => {
  const {
    handleSubmit,
    reset,
    control,
    setError,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ISubscriptionEventsFilter>({
    defaultValues: sideFilter,
  });

  const onSubmit = (data: ISubscriptionEventsFilter) => {
    filterSubmission(data);
    setPage(1);
  };

  const EventTypes: IDefaultOption[] = [
    {
      label: 'created',
      value: 'created',
    },
    {
      label: 'salvaging',
      value: 'salvaging',
    },
    {
      label: 'canceled',
      value: 'canceled',
    },
    {
      label: 'recovered',
      value: 'recovered',
    },
    {
      label: 'renewed',
      value: 'renewed',
    },
    {
      label: 'updated',
      value: 'updated',
    },
    {
      label: 'paused',
      value: 'paused',
    },
  ];

  const Status: IDefaultOption[] = [
    { label: 'Active', value: 'active' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Salvage', value: 'salvage' },
    { label: 'Paused', value: 'paused' },
  ];

  const Reason: IDefaultOption[] = [
    { label: 'Support', value: 'support' },
    { label: 'Dispute', value: 'dispute' },
    { label: 'Salvage Exceeded', value: 'salvage exceeded' },
    { label: 'No Retry', value: 'noRetry' },
  ];
  return (
    <div className="subscription-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <Controller
          name="CustomerEmails"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <MultipleInputTag
                tagsValue={value ? value : []}
                label="Customer Emails"
                placeholder="Type Emails"
                isLowerCase={true}
                onChange={onChange}
                validation={[
                  {
                    validationRule: emailRegex,
                    validationMessage: 'Enter a valid email address',
                  },
                ]}
                errorString={error?.message}
                setError={(value: string) => {
                  setError('CustomerEmails', {
                    message: value,
                  });
                }}
              />
            );
          }}
        />
        <Controller
          name="PhoneNumbers"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <MultipleInputTag
                tagsValue={value ? value : []}
                label="Phone Numbers"
                placeholder="Type Phone Numbers"
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          name="FirstNames"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <MultipleInputTag
                tagsValue={value ? value : []}
                label="First Names"
                placeholder="Type First Names"
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          name="LastNames"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <MultipleInputTag
                tagsValue={value ? value : []}
                label="Last Names"
                placeholder="Type Last Names"
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          name="EventTypes"
          control={control}
          render={({ field: { ref, value, onChange } }) => {
            return (
              <MultiSelect<IDefaultOption>
                value={value}
                options={EventTypes}
                labelKey="label"
                valueKey="value"
                label="Event Type"
                placeholder="Select Event Type"
                ref={ref}
                limitTags={1}
                onChange={(newValue: IDefaultOption[]) => {
                  onChange(newValue);
                }}
                error={errors?.EventTypes?.message}
              />
            );
          }}
        />
        <Controller
          name="Status"
          control={control}
          render={({ field: { value, ref, onChange } }) => {
            return (
              <MultiSelect<IDefaultOption>
                value={value ? value : []}
                options={Status}
                labelKey="label"
                valueKey="value"
                label="Current Status"
                placeholder="Select Current Status"
                ref={ref}
                limitTags={1}
                onChange={(newValue: IDefaultOption[]) => {
                  onChange(newValue);
                }}
                error={errors?.Status?.message}
              />
            );
          }}
        />
        <Controller
          name="Reason"
          control={control}
          render={({ field: { ref, value, onChange } }) => {
            return (
              <MultiSelect<IDefaultOption>
                value={value}
                options={Reason}
                labelKey="label"
                valueKey="value"
                label="Reason"
                placeholder="Select Reason"
                ref={ref}
                limitTags={1}
                onChange={(newValue: IDefaultOption[]) => {
                  onChange(newValue);
                }}
                error={errors?.Reason?.message}
              />
            );
          }}
        />
        <div className="common_box">
          <p className="common_input_label">Cycles Completed</p>
          <div className="flexContainer">
            <Controller
              name="MinCycle"
              control={control}
              render={({ field: { ref, onChange, ...rest } }) => {
                return (
                  <TextField
                    {...rest}
                    className="filter_form_input"
                    placeholder="Min value"
                    fixedSize="md"
                    onChange={e =>
                      onChange(e.target.value.replace(/[^0-9]/g, ''))
                    }
                    ref={ref}
                  />
                );
              }}
            />
            <Controller
              name="MaxCycle"
              control={control}
              render={({ field: { ref, onChange, ...rest } }) => {
                return (
                  <TextField
                    {...rest}
                    className="filter_form_input"
                    placeholder="Max Value"
                    fixedSize="md"
                    onChange={e =>
                      onChange(e.target.value.replace(/[^0-9]/g, ''))
                    }
                    ref={ref}
                  />
                );
              }}
            />
          </div>
        </div>
        <Controller
          name="selectedDate"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                onChange={onChange}
                value={value}
                label="Select Date"
                options={[
                  { label: 'Acquisition Date', value: 'acquisitionDate' },
                  { label: 'Events Date', value: 'eventsDate' },
                ]}
              />
            );
          }}
        />
        <div>
          <p className="mb-1 text-xs">{`${watch('selectedDate') === 'acquisitionDate' ? 'Acquisition' : 'Events'} Date`}</p>
          <div className="flex gap-2">
            <Controller
              name="selectedStartDate"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <DatePicker
                    maxDate={dayjs(watch('selectedEndDate')).tz()}
                    disableFuture={true}
                    error={errors?.selectedStartDate?.message}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
            <Controller
              name="selectedEndDate"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <DatePicker
                    error={errors?.selectedEndDate?.message}
                    minDate={dayjs(watch('selectedStartDate')).tz()}
                    disableFuture={true}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="button_wrapper">
          <Button
            className="subscription_for_submit"
            type="submit"
            label="Apply Filter"
          />
          <Button
            variant="secondary"
            className="subscription_for_submit clear_button"
            onClick={() => {
              setValue('CustomerEmails', []);
              reset(defaultSubscriptionEventsFiltersValues);
            }}
            label="Clear Filter"
          />
        </div>
      </form>
    </div>
  );
};
export default FilterForm;
