import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import storeService from 'services/storeService';
import { IPaymentGateway } from 'interface/settingsInterface';
import { showNotification } from 'helper/common/commonFunctions';
dayjs.extend(utc);

interface IProps {
  handleClose: () => void;
  isAdd: boolean;
  defaultStoreValues: IPaymentGateway;
}

const AddPaymentGateway: FC<IProps> = ({
  handleClose,
  isAdd,
  defaultStoreValues,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IPaymentGateway>({
    defaultValues: defaultStoreValues,
  });
  const addPaymentGateway = async (data: IPaymentGateway) => {
    setLoading(true);
    const res = await storeService.createPaymentGateway(data);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Payment Gateway Added Successfully');
    }
  };

  const updatePaymentGateway = async (data: IPaymentGateway) => {
    setLoading(true);
    const res = await storeService.updatePaymentGateway(data);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Payment Gateway Updated Successfully');
    }
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: isAdd ? 'Add' : 'Update',
            form: 'AddPaymentGateway',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={'Add Store'}
        className="flex-container">
        <div className="popup-section">
          <form
            className="shopify-collapsed"
            id="AddPaymentGateway"
            onSubmit={
              isAdd
                ? handleSubmit(addPaymentGateway)
                : handleSubmit(updatePaymentGateway)
            }>
            <Controller
              name="Secret"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    errorString={error?.message}
                    label="Phoenix Secret Token"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter Token"
                    ref={ref}
                  />
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default AddPaymentGateway;
