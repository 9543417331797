import { useForm, Controller } from 'react-hook-form';
import TextField from 'components/common/textField';
import { Button } from 'shared-components';
import 'components/orders/ordersFilter/filterForm/style.scss';
import DatePicker from 'components/common/datePicker';
import MultiSelect from 'components/common/multiSelect';
import { IDefaultOption } from 'interface/customerInterface';
import { IOrderFormFilter } from 'interface/orderInterface';
import { FC } from 'react';
import { defaultOrderFilterValues } from 'components/orders';
import { MultipleInputTag } from 'components/common/multiInput';
import { emailRegex, trafficChannelData } from 'utils/constants';
import dayjs from 'dayjs';

interface IProps {
  sideFormFilter: IOrderFormFilter;
  filterSubmission: (_data: IOrderFormFilter) => void;
}

const FilterForm: FC<IProps> = ({ sideFormFilter, filterSubmission }) => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm<IOrderFormFilter>({
    defaultValues: sideFormFilter,
  });

  const onSubmit = (data: IOrderFormFilter) => {
    filterSubmission(data);
  };

  const subscriptionStatus: IDefaultOption[] = [
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Voided', value: 'voided' },
    { label: 'Refunded', value: 'refunded' },
  ];

  return (
    <div className="order-filter-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <Controller
          name="customerEmails"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <MultipleInputTag
                tagsValue={value}
                label="Customer Emails"
                isLowerCase={true}
                placeholder="Type Emails"
                onChange={onChange}
                validation={[
                  {
                    validationRule: emailRegex,
                    validationMessage: 'Enter a valid email address',
                  },
                ]}
                setError={(value: string) => {
                  setError('customerEmails', {
                    type: 'custom',
                    message: value,
                  });
                }}
                errorString={error?.message}
              />
            );
          }}
        />
        <Controller
          name="PhoneNumbers"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <MultipleInputTag
                tagsValue={value}
                label="Customer Phone Numbers"
                placeholder="Type Phone Numbers"
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          name="FirstName"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextField
                label="First Name"
                placeholder="Type First Name"
                value={value}
                fixedSize="md"
                onChange={e => {
                  onChange(
                    e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                  );
                }}
              />
            );
          }}
        />
        <Controller
          name="LastName"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextField
                label="Last Name"
                placeholder="Type Last Name"
                value={value}
                fixedSize="md"
                onChange={e => {
                  onChange(
                    e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                  );
                }}
              />
            );
          }}
        />
        <Controller
          name="status"
          control={control}
          render={({ field: { ref, value, onChange } }) => {
            return (
              <MultiSelect<IDefaultOption>
                value={value}
                options={subscriptionStatus}
                labelKey="label"
                valueKey="value"
                label="Payment Status"
                placeholder="Select Payment Status"
                ref={ref}
                limitTags={1}
                onChange={(newValue: IDefaultOption[]) => {
                  onChange(newValue);
                }}
              />
            );
          }}
        />
        <Controller
          name="externalOrderID"
          control={control}
          render={({ field: { ...rest } }) => {
            return (
              <TextField
                {...rest}
                label="Enter Order #"
                className="filter_form_input"
                placeholder="Enter Order #"
                fixedSize="md"
              />
            );
          }}
        />
        <Controller
          name="channels"
          control={control}
          render={({ field: { ref, value, onChange } }) => {
            return (
              <MultiSelect<IDefaultOption>
                value={value}
                options={trafficChannelData}
                labelKey="label"
                valueKey="value"
                label="Traffic Channel"
                placeholder="Select Traffic Channel"
                ref={ref}
                limitTags={1}
                onChange={(newValue: IDefaultOption[]) => {
                  onChange(newValue);
                }}
                error={errors?.channels?.message}
              />
            );
          }}
        />
        <Controller
          name="startTime"
          control={control}
          render={({ field: { ref, ...rest } }) => {
            return (
              <DatePicker
                label="Order Start Date"
                maxDate={dayjs(watch('endTime')).tz()}
                disableFuture={true}
                error={errors?.startTime?.message}
                ref={ref}
                {...rest}
              />
            );
          }}
        />
        <Controller
          name="endTime"
          control={control}
          render={({ field: { ref, ...rest } }) => {
            return (
              <DatePicker
                label="Order End Date"
                minDate={dayjs(watch('startTime')).tz()}
                disableFuture={true}
                error={errors?.endTime?.message}
                ref={ref}
                {...rest}
              />
            );
          }}
        />
        <div className="direct_sale_box">
          <p className="common_input_label">Total</p>
          <div className="flexContainer">
            <Controller
              name="minAmount"
              control={control}
              render={({ field: { ref, onChange, ...rest } }) => {
                return (
                  <TextField
                    {...rest}
                    isPrefixText="$"
                    className="filter_form_input"
                    placeholder="Min value"
                    fixedSize="md"
                    ref={ref}
                    onChange={e =>
                      onChange(
                        e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*?)\..*/g, '$1'),
                      )
                    }></TextField>
                );
              }}
            />
            <Controller
              name="maxAmount"
              control={control}
              render={({ field: { ref, onChange, ...rest } }) => {
                return (
                  <TextField
                    {...rest}
                    isPrefixText="$"
                    className="filter_form_input"
                    placeholder="Max Value"
                    fixedSize="md"
                    onChange={e =>
                      onChange(
                        e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*?)\..*/g, '$1'),
                      )
                    }
                    ref={ref}></TextField>
                );
              }}
            />
          </div>
        </div>
        <div className="button_wrapper">
          <Button
            className="transaction_for_submit"
            type="submit"
            label="Apply Filter"
          />
          <Button
            variant="secondary"
            className="transaction_for_submit clear_button"
            onClick={() => {
              reset(defaultOrderFilterValues);
            }}
            label="Clear Filter"
          />
        </div>
      </form>
    </div>
  );
};
export default FilterForm;
