import React from 'react';

interface ReturnButtonProps {
  shopUrl?: string;
}

export const ReturnButton: React.FC<ReturnButtonProps> = ({ shopUrl }) => {
  return (
    <a
      href={`${shopUrl}`}
      type="button"
      className="text-xs text-page_builder_theme mt-4 sm:mt-0 sm:text-left text-center hover:text-indigo-500">
      Return to Store
    </a>
  );
};
