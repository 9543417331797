import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IAllUsers } from 'interface/settingsInterface';
import globalUserService from 'services/userService';
import userService from 'services/userService';
import { PopUpModal, showNotification } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';
import AutoCompleteSelect from 'components/common/selectAutoComplete';

interface AddAdminProps {
  setShowAddEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
}

export default function AddAdmin({
  setShowAddEditMode,
  onSuccess,
}: AddAdminProps) {
  const position = useRef<number>();
  const [users, setUsers] = useState<IAllUsers[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [userSearch, setUserSearch] = useState<IAllUsers>({
    Descending: true,
    Limit: 25,
    OrderBy: 'CreatedAt',
    Page: 0,
    SuperAdmin: false,
  });

  const { handleSubmit, control } = useForm<{ email: string }>();
  const observerRef = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      if (loading || !hasNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setUserSearch(prev => ({ ...prev, Page: prev?.Page + 1 }));
          getUsers();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [loading, hasNextPage],
  );

  const onSubmit = async (data: { email: string }) => {
    setLoading(true);
    try {
      const response = await globalUserService.grantSuperAdmin(data?.email);

      if (response?.status === 200) {
        const addedEmail =
          users?.find(user => user?.ID === data?.email)?.Email || 'Email ID';
        showNotification('success', `${addedEmail} Added as Super Admin`);
        onSuccess();
        setUsers([]);
        setUserSearch(prev => ({ ...prev, Page: 0 }));
        setShowAddEditMode(false);
      }
    } catch (error) {
      showNotification('error', 'Failed to add Super Admin');
    } finally {
      setLoading(false);
    }
  };

  const getUsers = async () => {
    setLoading(true);
    const res = await userService.getAllUsers(userSearch);
    setLoading(false);

    if (res?.status === 200) {
      setUsers(prevUsers => [...prevUsers, ...(res?.data?.Result ?? [])]);
      setHasNextPage(res?.data?.Result?.length >= userSearch?.Limit);
    } else {
      setUsers([]);
      setHasNextPage(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [userSearch]);

  const setRef = (value: number) => {
    position.current = value;
  };

  useEffect(() => {
    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, []);

  return (
    <PopUpModal
      open={true}
      handleClose={() => setShowAddEditMode(false)}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => setShowAddEditMode(false),
        },
        {
          buttonType: 'positive',
          buttonLabel: 'Confirm',
          form: 'AddAdmin',
          type: 'submit',
          disabled: loading,
          loading,
        },
      ]}
      heading={'Add to Admin'}>
      <div className="popup-section m-auto">
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="AddAdmin"
          className="subscription-filter-selection-form">
          <Controller
            name="email"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <AutoCompleteSelect
                  lastElementRef={lastElementRef}
                  position={position.current}
                  setPostionRef={setRef}
                  loading={loading}
                  options={users?.map(user => ({
                    label: user?.Email,
                    value: user?.ID,
                  }))}
                  onChange={newValue => {
                    onChange(newValue?.value);
                  }}
                  labelKey="label"
                  onOpen={() => {
                    setUserSearch({ ...userSearch, Page: 0 });
                    setUsers([]);
                    getUsers();
                  }}
                  onInputChange={() => {
                    setUserSearch(prev => ({ ...prev, Page: 0 }));
                    setUsers([]);
                  }}
                  placeholder="Select user email"
                  value={
                    value
                      ? {
                          label:
                            users?.find(user => user?.ID === value)?.Email ||
                            '',
                          value,
                        }
                      : null
                  }
                  className="w-full dropdown-field"
                  label="User Email"
                />
              );
            }}
          />
        </form>
      </div>
    </PopUpModal>
  );
}
