export const convertBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = err => {
      reject(err);
    };
  });
};

export const viewPorts = {
  mobile: 360,
  tablet: 768,
  desktop: 1280,
};
