import {
  Chart,
  ChartDatasetProperties,
  ChartOptions,
  ChartTypeRegistry,
  LineControllerDatasetOptions,
  ScatterDataPoint,
  TooltipCallbacks,
  TooltipItem,
  TooltipModel,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import formatNumber, { UsDollarFormatter } from 'utils/helper';

interface TooltipProps {
  chart: Chart;
  tooltip: TooltipModel<keyof ChartTypeRegistry>;
}
type CustomBarDataset = _DeepPartialObject<
  {
    type: 'line';
    amount: (number | [number, number] | null)[];
  } & LineControllerDatasetOptions
> &
  ChartDatasetProperties<'line', (ScatterDataPoint | number | null)[]>;

const tooltip = ({
  prefix = '',
  suffix = '',
  hasPercentageValue = false,
  isPercentagedata = false,
  isLabelRequired = false,
  customPrefixSuffix,
}: {
  prefix?: string;
  suffix?: string;
  hasPercentageValue?: boolean;
  isPercentagedata?: boolean;
  isLabelRequired?: boolean;
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
} = {}) => {
  return {
    intersect: false,
    padding: 8,
    callbacks: {
      title: () => '',
      label: context => {
        const dataSets = context?.chart?.config?.data?.datasets;
        if (hasPercentageValue && dataSets?.length > 0) {
          let percentage: string = '0';

          if (dataSets.length > 0) {
            const data = dataSets.reduce((value, eachValue) => {
              const eachData = eachValue?.data?.[context?.dataIndex] || 0;
              if (typeof eachData === 'number') {
                return value + eachData;
              }
              return value;
            }, 0);
            percentage =
              (+context.formattedValue / +data) * 100
                ? Number((+context.formattedValue / +data) * 100).toFixed(2)
                : '0';
          }

          return `${context.dataset.label + ': ' + prefix + context.formattedValue + suffix + ' / ' + percentage + '%'}`;
        }

        if (isPercentagedata && dataSets?.length > 0) {
          const label = context.dataset.label || '';
          const currentDataset: CustomBarDataset = context.dataset;
          const value =
            (currentDataset?.amount
              ? currentDataset?.amount[context?.dataIndex]
              : currentDataset?.data[context?.dataIndex]) || 0;
          return `${label + ' : ' + prefix + value + '/' + context.raw + '%'}`;
        }

        if (customPrefixSuffix && dataSets?.length > 0) {
          const label = context.dataset.label || '';
          const value = context.dataset.data[context?.dataIndex] || 0;
          let pre = '';
          let suf = '';
          if (customPrefixSuffix?.[label]) {
            pre = customPrefixSuffix?.[label].prefix ?? '';
            suf = customPrefixSuffix?.[label].suffix ?? '';
          }
          return `${isLabelRequired ? `${label}: ` : ''}${pre + value.toLocaleString() + suf}`;
        }

        const label = context.dataset.label || '';
        const value = context.parsed.y || 0;
        const labelText = `${isLabelRequired ? `${label}: ` : ''}${prefix + formatNumber(value) + suffix};${context?.label.split(':')[1]}`;
        return labelText;
      },
      beforeTitle: () => '',
      afterTitle: () => '',
      beforeBody: () => '',
      afterBody: () => '',
      beforeLabel: () => '',
      afterLabel: () => '',
      labelTextColor: () => '',
      beforeFooter: () => '',
      footer: () => '',
      afterFooter: () => '',
      labelColor: () => {},
      labelPointStyle: () => {},
    } as TooltipCallbacks<'line'>,
    displayColors: false,
    bodyColor: '#A3AED0',
    bodyFont: {
      size: 14,
      family: 'work-sans',
      weight: 500,
    },
    bodySpacing: 8,
    boxPadding: 4,
    backgroundColor: '#141E2D',
    borderColor: '#192739',
    borderWidth: 2,
    enabled: false,
  };
};

export const groupedLineChartoptions = ({
  prefix,
  suffix,
  labelPrefix,
  labelSuffix,
  isLabelRequired,
  hasPercentageValue,
  isPercentagedata,
  customPrefixSuffix,
  labels,
}: {
  prefix: string;
  suffix: string;
  labelPrefix: string;
  labelSuffix: string;
  isLabelRequired?: boolean;
  hasPercentageValue: boolean;
  isPercentagedata: boolean;
  labels?: string[];
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
}): ChartOptions<'line'> => {
  return {
    interaction: {
      mode: 'nearest' as const,
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          callback: (value: string | number) => {
            if (labels) {
              return `${labels[value as number]?.split(':')[0]}`;
            }
          },
          autoSkip: true,
          autoSkipPadding: 12,
          maxRotation: 0,
          minRotation: 0,
          align: 'inner' as const,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ...(isPercentagedata ? { suggestedMax: 100 } : {}),
        ticks: {
          callback: (value: number | string) => {
            return `${labelPrefix + formatNumber(Number(value)) + labelSuffix}`;
          },
          align: 'center' as const,
          padding: 8,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          color: '#a3aed026',
          drawTicks: false,
        },
        border: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.5,
        borderWidth: 4,
        borderCapStyle: 'round' as const,
      },
      point: {
        radius: 0,
        hoverRadius: 8,
        hoverBorderWidth: 4,
        hoverBackgroundColor: 'white',
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        ...tooltip({
          prefix,
          suffix,
          hasPercentageValue,
          isPercentagedata,
          customPrefixSuffix,
          isLabelRequired,
        }),
        mode: 'index' as const,
        position: 'nearest' as const,
        external: (e: TooltipProps) => {
          externalTooltipHandler(e, isLabelRequired ? 'LineChart' : '');
        },
      },
    },
  };
};

const lineChartTooltip = {
  mode: 'index' as const,
  position: 'nearest' as const,
  intersect: false,
  padding: 8,
  callbacks: {
    title: () => '',
    label: (context: TooltipItem<'line'>) => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const total = (context?.chart?.config?.data?.datasets?.[0] as any)
        .OverallTotal[context?.dataIndex];
      const label = context?.dataset?.label || '';
      const value = context?.parsed?.y || 0;
      const labelText = `${label}:${UsDollarFormatter(value)};${context?.label.split(':')[1]};${UsDollarFormatter(total)}`;
      return labelText;
    },
  },
  bodyColor: '#A3AED0',
  displayColors: false,
  bodyFont: {
    size: 14,
    family: 'work-sans',
    weight: 500,
  },
  bodySpacing: 8,
  boxPadding: 4,
  backgroundColor: '#141E2D',
  borderColor: '#192739',
  borderWidth: 2,
  enabled: false,
};

export const filledLineChartoptions = (
  labels: string[],
  prefix?: string,
  isStacked = false,
) => {
  return {
    interaction: {
      mode: 'nearest' as const,
      intersect: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        ticks: {
          offset: true,
          callback: (value: string | number) => {
            let label = '';
            if (labels) {
              label = labels[value as number]?.split(':')[0];
            }
            const words = label.split(' ');
            if (words.length <= 2) {
              return label;
            }
            const lines: string[] = [];
            words.forEach((word: string) => {
              lines.push(word.trim());
            });
            return lines;
          },
          autoSkip: true,
          maxTicksLimit: 12,
          autoSkipPadding: 12,
          maxRotation: 0,
          minRotation: 0,
          align: 'inner' as const,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ...(isStacked ? { stacked: true } : {}),
        ticks: {
          callback: (value: number | string) => {
            return formatNumber(Number(value));
          },
          steps: 6,
          align: 'center' as const,
          padding: 8,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          color: '#a3aed026',
          drawTicks: false,
        },
        border: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.5,
        borderWidth: 2.5,
        borderCapStyle: 'round' as const,
      },
      point: {
        radius: 0,
        hoverRadius: 8,
        hoverBorderWidth: 4,
        borderColor: 'white',
      },
    },
    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        ...lineChartTooltip,
        external: (e: TooltipProps) => {
          externalTooltipHandler(e, 'filledLine', prefix);
        },
      },
    },
  };
};

export const externalTooltipHandler = (
  context: TooltipProps,
  type: string,
  prefix = '',
) => {
  const { chart, tooltip } = context;
  let tooltipEl = chart?.canvas?.parentNode?.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('tooltip-container');
    const tooltipElement = document.createElement('div');
    tooltipElement.classList.add('tooltip-wrapper');
    tooltipEl.appendChild(tooltipElement);
    chart?.canvas?.parentNode?.appendChild(tooltipEl);
  }

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  if (tooltip?.body) {
    const isTooltipBelow = tooltip.caretY > chart.height / 2;
    if (tooltip.x < 80) {
      tooltipEl.className = `tooltip-container pointer-left`;
      tooltipEl.style.transform = `translate(20px, -50%)`;
    } else if (chart.width - tooltip.x - tooltip.width < 80) {
      tooltipEl.className = `tooltip-container pointer-right`;
      tooltipEl.style.transform = `translate(calc(-100% - 20px), -50%)`;
    } else {
      tooltipEl.className = `tooltip-container ${type && 'lineCharts'} ${isTooltipBelow && 'pointer-bottom'}`;
      tooltipEl.style.transform = `translate(-50%, ${isTooltipBelow ? 'calc(-100% - 20px)' : '18px'})`;
    }
    const tableBody = document.createElement('div');
    if (type === 'filledLine') {
      const tooltipRow = document.createElement('div');
      tooltipRow.classList.add('tooltip-row');
      tooltipRow.innerHTML = `Total <span class='tooltip-data Total'>${prefix}${tooltip?.body?.[0]?.lines?.[0].split(';')[2]}</span>`;
      tableBody.appendChild(tooltipRow);
    }
    tooltip.body.forEach((body: { lines: string[] }, index: number) => {
      const tooltipRow = document.createElement('div');
      tooltipRow.classList.add('tooltip-row');
      if (tooltip.labelColors[index].backgroundColor) {
        tooltipRow.style.color = `${tooltip.labelColors[index].backgroundColor}`;
      }
      const tooltipData = body?.lines?.[0].split(';')[0].split(':');
      tooltipRow.innerHTML = `${tooltipData?.[0] ? `<span>${tooltipData?.[0]}</span>` : ''}
        ${
          tooltipData?.[1]
            ? `<span class='tooltip-data ${tooltipData?.[0]}'>${prefix}${tooltipData?.[1]}</span>`
            : ''
        }`;
      tableBody.appendChild(tooltipRow);
    });

    const labelShow = document.createElement('div');
    labelShow.classList.add('tooltip-xaxis-label');
    labelShow.innerHTML = `${tooltip?.body[0]?.lines[0]?.split(';')[1]?.replace(':', ' ')}`;
    tableBody.appendChild(labelShow);

    const tableRoot = tooltipEl?.querySelector('.tooltip-wrapper');
    while (tableRoot?.firstChild) {
      tableRoot?.firstChild.remove();
    }
    tableRoot?.appendChild(tableBody);
  }
  const { offsetLeft, offsetTop } = chart.canvas;
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = offsetLeft + tooltip.caretX + 'px';
  tooltipEl.style.top = offsetTop + tooltip.caretY + 'px';
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
