import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import globalTransactionService from 'services/transactionService';
import { IVoidTransaction } from 'interface/transactionInterface';
import { Checkbox } from 'shared-components';

interface IProps {
  payload: IVoidTransaction;
  handleClose: () => void;
  onApiResponse: (_: boolean) => void;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const VoidTransaction: FC<IProps> = ({
  payload,
  handleClose,
  onApiResponse,
  setLoading,
}) => {
  const [cancelSubscription, setCancelSubscription] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState(false);

  const voidTransactions = async () => {
    setActionLoading(true);
    payload.CancelSubscription = cancelSubscription;
    const resp = await globalTransactionService.voidTransaction(payload);
    if (resp.status === 200) {
      onApiResponse(true);
    } else {
      onApiResponse(false);
    }
    handleClose();
    setLoading && setLoading(false);
    setActionLoading(false);
  };

  return (
    <PopUpModal
      open={true}
      handleClose={handleClose}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: handleClose,
        },
        {
          buttonType: 'positive',
          buttonLabel: 'Submit',
          buttonFunction: voidTransactions,
          disabled: actionLoading,
          loading: actionLoading,
        },
      ]}
      heading={'Confirm'}>
      <div className="popup-section">
        <p className="popup-question">
          Are you sure you want to Void this order?
        </p>
        <div className="checkbox_wrapper">
          <Checkbox
            className="subscription_checkbox"
            checked={cancelSubscription}
            onChange={() => setCancelSubscription(!cancelSubscription)}
          />
          <span className="checkbox_label">Cancel Subscription</span>
        </div>
      </div>
    </PopUpModal>
  );
};

export default VoidTransaction;
