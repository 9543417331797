import { useMemo, FC } from 'react';
import { TransactionStats } from 'interface/dashboardInterface';
import { chargeType, transactionStatus } from 'components/dashboard/constant';
import { OverlapedBarChart } from 'components/common/chart/barChart';
import { percentage, UsDollarFormatter } from 'utils/helper';
import { ScriptableContext } from 'chart.js';

interface IProps {
  groupedTransactionStats: [string, TransactionStats[]][];
}
interface IDatasets {
  revenuePercentageByAmount: number[];
  refundPercentageByAmount: number[];
  revenueAmount: string[];
  refundAmount: string[];
}

const StraightSaleRefundRatio: FC<IProps> = ({ groupedTransactionStats }) => {
  const straightSalesRefundRatioData = useMemo(() => {
    const values: IDatasets = {
      revenuePercentageByAmount: [],
      refundPercentageByAmount: [],
      revenueAmount: [],
      refundAmount: [],
    };
    let totalAmount = 0;

    // cumulative total of amount and count for each dataset
    let totalCapturedAmount = 0;
    let totalRefundAmount = 0;

    groupedTransactionStats.forEach(([, statsData]) => {
      let refundAmount = 0;
      let capturedAmount = 0;
      let totalAmountPerDay = 0;

      statsData.forEach(stat => {
        // don't count failed transactions
        if (
          stat.ChargeType !== chargeType.purchase ||
          stat.Status === transactionStatus.failcapture ||
          stat.Status === transactionStatus.failAuth
        ) {
          return;
        }
        totalAmountPerDay += stat.Amount;
        totalAmount += stat.Amount;
        if (
          stat.Status === transactionStatus.refunded ||
          stat.Status === transactionStatus.partialRefund
        ) {
          totalRefundAmount += stat?.Amount;
          refundAmount += stat?.Amount;
        }

        if (stat.Status !== transactionStatus.voided) {
          totalCapturedAmount += stat?.Amount;
          capturedAmount += stat?.Amount;
        }
      });

      // for revenue, refun, void percentage calculation
      values.revenuePercentageByAmount.push(
        percentage(capturedAmount, totalAmountPerDay, true),
      );
      values.refundPercentageByAmount.push(
        percentage(refundAmount, totalAmountPerDay, true),
      );
      // For amount calculation
      values.revenueAmount.push(UsDollarFormatter(capturedAmount));
      values.refundAmount.push(UsDollarFormatter(refundAmount));
    });

    return {
      labels: groupedTransactionStats.map(([date]) => date),
      datasets: [
        {
          label: 'Revenue',
          borderColor: '#F90182',
          backgroundColor: (context: ScriptableContext<'bar'>) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, 'rgba(249, 1, 130, 1)');
            gradient.addColorStop(1, 'rgba(20, 30, 45, 1)');
            return gradient;
          },
          legend: 'Revenue',
          order: 2,
          data: values.revenuePercentageByAmount,
          amount: values.revenueAmount, // to show amount on tooltip
          percentage: percentage(totalCapturedAmount, totalAmount).toFixed(1), // to show percentage on legend
          total: UsDollarFormatter(totalCapturedAmount),
        },
        {
          label: 'Refund',
          borderColor: '#6AD2FF',
          backgroundColor: (context: ScriptableContext<'bar'>) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, 'rgba(106, 210, 255, 1)');
            gradient.addColorStop(1, 'rgba(20, 30, 45, 1)');
            return gradient;
          },
          legend: 'Refund',
          order: 1,
          data: values.refundPercentageByAmount,
          amount: values.refundAmount,
          percentage: percentage(totalRefundAmount, totalAmount, true),
          total: UsDollarFormatter(totalRefundAmount),
        },
      ],
    };
  }, [groupedTransactionStats]);
  return (
    <div className="md_card_container row-span-2">
      <div className="header">
        <p className="header_text extend_sm">Straight Sale Refund Ratio</p>
      </div>
      <div className="chart_wrapper">
        <OverlapedBarChart
          data={straightSalesRefundRatioData}
          isPercentagedata={true}
          prefix="$"
          labelSuffix="%"
          className="chart"
        />
      </div>
      <div className="refund_legends">
        {straightSalesRefundRatioData?.datasets?.map(data => {
          return (
            <div className="sections" key={data?.legend}>
              <div className="legend">
                <span
                  className="indicator"
                  style={{ background: data?.borderColor }}
                />
                <div>
                  <div>{data?.legend}</div>
                  <div className="percentage">
                    ${data.total + '/' + data?.percentage || 0 + '%'}%
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default StraightSaleRefundRatio;
