import { FieldLabel, Fields } from '@measured/puck';
import { QuillEditor } from '../../../components/common/quill-editor/quill-editor';
import CustomTooltip from '../../../components/common/tooltip';

export type QuillTextEditorProps = {
  content: string;
};

export const QuillTextEditor = (type: string): Fields<QuillTextEditorProps> => {
  return {
    content: {
      type: 'custom',
      render: ({ value, onChange }) => {
        return (
          <FieldLabel
            label={
              (
                <CustomTooltip title={'QuillEditor'}>
                  <span>{type}</span>
                </CustomTooltip>
              ) as React.ReactNode as string
            }>
            <QuillEditor value={value} onChange={onChange} />
          </FieldLabel>
        );
      },
    },
  };
};
