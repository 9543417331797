import React, { ChangeEvent } from 'react';
import CreditCardLogos from '../../../assets/svg/credit-logos';
import { TextFieldControl } from '../form-fields/TextField';
import DeclinePopup from '../../../components/common/decline-popup/DeclinePopup';
import { PaymentProps as PaymentFieldProps } from './Payment';
import { AddressForm } from '../address/AddressForm';
import { useForm } from 'react-hook-form';
import { ReduxSelectorProps } from '../../services/constants';

interface PaymentProps {
  handleReturnToPayment: () => void;
}

type CustomInputEvent = React.ChangeEvent<HTMLInputElement> & {
  nativeEvent: {
    inputType: string;
  };
};

interface PaymentControllerProps {
  fields: PaymentFieldProps;
  fromRadio: boolean;
}

export const PaymentFields: React.FC<
  PaymentProps & PaymentControllerProps & ReduxSelectorProps
> = ({ handleReturnToPayment, fields, fromRadio, useAppSelector }) => {
  const { watch, setValue } = useForm<{ billing_same_as_shipping: boolean }>({
    defaultValues: {
      billing_same_as_shipping: false,
    },
  });
  const { margin, isShowBillingAddress, subText } = fields;
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) =>
    setValue('billing_same_as_shipping', event.target.checked);
  const isDeclinePopup = useAppSelector(state => state.checkout.isDeclinePopup);
  const declineReasonCode = useAppSelector(
    state => state.checkout.declineReasonCode,
  );

  return (
    <section
      aria-labelledby="payment-heading"
      className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
      {isDeclinePopup && (
        <DeclinePopup
          onClose={handleReturnToPayment}
          declineReasonCode={declineReasonCode}
          useAppSelector={useAppSelector}
        />
      )}

      {!fromRadio && <p className="text-sm text-gray-500 mb-2">{subText}</p>}
      <div className="rounded-md">
        {!fromRadio && (
          <div className="border border-page_builder_theme  bg-page_builder_theme bg-opacity-5 shipping-border secondary-background-color px-5 py-5 rounded-t-md">
            <div className="flex items-center justify-between w-full  ">
              <p className="text-sm items-center text-black">Credit card</p>
              <CreditCardLogos className="h-8" />
            </div>
          </div>
        )}
        <div
          className={`p-5 bg-custom-gray ${fromRadio ? 'border-t' : ''} border-b border-l border-r rounded-b-md`}>
          <div className="grid grid-cols-3 gap-x-4 gap-y-[10px] sm:grid-cols-4">
            <div className="col-span-4">
              <TextFieldControl
                name={'CardInfo.Number'}
                defaultValue=""
                maxLength={23} // max length of 19 digits + 4 spaces
                placeholder="Credit Card Number"
                valueFormatter={cardNumber => {
                  // This function formats the card number to have spaces every 4 digits
                  const rawText = cardNumber.split('');
                  const result: string[] = [];
                  rawText.forEach((t, i) => {
                    if (i % 4 === 0 && i !== 0) result.push(' ');
                    result.push(t);
                  });
                  return result.join('');
                }}
                onChangeHandler={e => {
                  return e.target.value.replace(/[^0-9]/g, '');
                }}
                rules={{
                  required: 'Card number is required',
                  validate: value => {
                    const cardNumberWithoutSpace = value.replace(/\s/g, '');
                    if (
                      cardNumberWithoutSpace.length < 13 ||
                      cardNumberWithoutSpace.length > 19
                    ) {
                      return 'Card number must be 13-19 digits and a recognizable card format';
                    }
                  },
                }}
              />
            </div>
            <div className="2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-2 sm:col-span-4 col-span-4">
              <TextFieldControl
                name={'CardInfo.Exp'}
                defaultValue=""
                maxLength={7}
                placeholder="MM/YY"
                onChangeHandler={event => {
                  const e = event as CustomInputEvent;
                  const value = e.target.value
                    .replace(/[^0-9/]/g, '')
                    .replace(/(\/.*?)\/.*/g, '$1');
                  if (
                    value.length === 2 &&
                    e?.nativeEvent?.inputType !== 'deleteContentBackward' &&
                    !value.endsWith('/')
                  ) {
                    return value + '/';
                  }
                  return value;
                }}
                rules={{
                  required: 'Expiration date is required',
                  validate: value => {
                    const expDate = value.split('/');
                    if (
                      expDate.length !== 2 ||
                      expDate[0].length !== 2 ||
                      expDate[1].length < 2
                    ) {
                      return 'Expiration date must be in MM/YY format';
                    }
                    const [exMonth, exYear] = expDate;
                    const today = new Date();
                    const someday = new Date();
                    someday.setFullYear(
                      exYear.length === 2
                        ? 2000 + Number(exYear)
                        : Number(exYear),
                      Number(exMonth) - 1,
                      today.getDate(),
                    );
                    if (exMonth > 12 || exMonth < 0 || someday < today) {
                      return 'Expiration date must be a present or future month and year';
                    }
                  },
                }}
              />
            </div>
            <div className="2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-2 sm:col-span-4 col-span-4">
              <TextFieldControl
                name={'CardInfo.CVV'}
                defaultValue=""
                placeholder="CVV"
                maxLength={4}
                onChangeHandler={e => {
                  return e.target.value.replace(/[^0-9]/g, '');
                }}
                rules={{
                  required: 'CVV is required',
                  validate: value => {
                    if (value.length < 3 || value.length > 4) {
                      return 'CVV must be 3 or 4 digits';
                    }
                  },
                }}
              />
            </div>
          </div>
          {isShowBillingAddress && (
            <section
              aria-labelledby="billing-heading"
              className="col-span-full pt-4">
              <div className="checkbox-control">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={watch('billing_same_as_shipping')}
                    className="check-box-billing focus:ring-0 focus:ring-offset-0 h-4 w-4 rounded border-gray-300  focus:outline-none"
                  />
                  <div className="ml-2 text-gray-500 check-box-same-shipping-address-text">
                    Use shipping address as billing address
                  </div>
                </label>
              </div>
            </section>
          )}
          {!watch('billing_same_as_shipping') && isShowBillingAddress && (
            <div className="mt-4">
              <h1 className="font-semibold text-black text-lg mb-4">
                Billing address
              </h1>
              <AddressForm fieldPrefix="BillingAddress" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
