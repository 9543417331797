import React, { useState } from 'react';
import { setIsTermsAccepted } from '../../redux/slices/termsAndSubSlice';
import { EventPublisher } from '../../utils/publishEvent';
import { v4 as uuidv4 } from 'uuid';
import { ExtraTermProps } from './index';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';

export type TermsAndSubscriptionProps = {
  acknowledgeContent: string;
  extraTerms: ExtraTermProps;
  isEditModeOn: boolean;
};
export const TermsAndSubscription = ({
  acknowledgeContent,
  extraTerms,
  isEditModeOn,
  useAppDispatch,
  useAppSelector,
}: TermsAndSubscriptionProps & ReduxDispatchProps & ReduxSelectorProps) => {
  const dispatch = useAppDispatch();
  const isTermsAccepted = useAppSelector(
    state => state.termsAndSub.isTermsAccepted,
  );
  const cartData = useAppSelector(state => state.cart.cartData);
  const storeData = useAppSelector(state => state.store.storeData);
  const publish = EventPublisher.getInstance().publish;

  const [viewTerms, setViewTerms] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="py-2">
        <fieldset>
          <legend className="sr-only">Notifications</legend>
          <div className="space-y-5">
            <div className="flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="terms_of_service"
                  type="checkbox"
                  className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600`}
                  onChange={() => {
                    const newIsTermsAccepted = !isTermsAccepted;
                    dispatch(setIsTermsAccepted(newIsTermsAccepted));
                    if (newIsTermsAccepted && cartData && storeData) {
                      publish('AgreeToTerms', {
                        eventId: uuidv4(),
                        shopId: storeData?.ShopID || '',
                        eventName: 'AgreeToTerms',
                        cart: cartData,
                      });
                    }
                  }}
                  checked={isTermsAccepted}
                />
              </div>
              <div
                className="checkbox-text ml-3 leading-6 !bg-none  vip-terms-text text-left text-black"
                dangerouslySetInnerHTML={{ __html: acknowledgeContent }}
              />
            </div>
          </div>
        </fieldset>
      </div>
      {extraTerms?.viewMoreTerms && (
        <>
          <button
            className="hover:text-indigo-500 text-xs"
            type="button"
            onClick={() => setViewTerms(!viewTerms)}>
            + Learn More
          </button>
          {isEditModeOn ? (
            <div
              className={`mx-auto max-w-2xl text-center transition-all duration-500 ease-in-out ${'max-h-screen'} overflow-hidden`}>
              <div
                className="mt-3 vip-terms-text text-left leading-5 text-black"
                dangerouslySetInnerHTML={{
                  __html: extraTerms?.moreTerms,
                }}></div>
            </div>
          ) : (
            <div
              className={`mx-auto max-w-2xl text-center transition-all duration-500 ease-in-out ${
                viewTerms ? 'max-h-screen' : 'max-h-0'
              } overflow-hidden`}>
              <div
                className="mt-3 vip-terms-text text-left leading-5 text-black"
                dangerouslySetInnerHTML={{
                  __html: extraTerms?.moreTerms,
                }}></div>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};
