import { PostUpSellVarient } from './PostUpsellVarient';
import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../../page-builder/puck/reusable-props/margin';
import {
  paddingFields,
  PaddingProps,
} from '../../../../page-builder/puck/reusable-props/padding';
import {
  JustifyContentField,
  JustifyContentProps,
} from '../../../../page-builder/puck/reusable-props/justify-content';
import {
  fontField,
  FontProps,
} from '../../../../page-builder/puck/reusable-props/font';
import {
  AlignItemsProps,
  alignItemsField,
} from '../../../../page-builder/puck/reusable-props/align-items';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../../services/constants';
import CustomTooltip from '../../../../components/common/tooltip';

export type ProductProps = MarginProps &
  PaddingProps &
  JustifyContentProps &
  FontProps &
  AlignItemsProps;

export interface VarientProps {
  title: string;
  originalPrice: string;
  discountedPrice: string;
  rating: number;
  ratingsCount: number;
  imageSrc: string;
}

export const Product = ({
  useAppSelector,
  useAppDispatch,
}: ReduxSelectorProps & ReduxDispatchProps): ComponentConfig<ProductProps> => {
  return {
    label: (
      <CustomTooltip title="Product">
        <span>Product</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...marginFields,
      ...paddingFields,
      ...JustifyContentField,
      ...fontField,
      ...alignItemsField,
    },
    defaultProps: {
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      justifyContent: 'justify-center',
      font: {
        size: 'text-2xl',
        weight: 'font-bold',
      },
      alignItems: 'items-center',
    },
    render: ({ margin, padding, justifyContent, alignItems }) => {
      const postUpsell = useAppSelector(state => state.postUpSell);
      const postUpsellState = useAppSelector(state => state.postUpSellState);
      const postUpSellRecommendation = postUpsell.postUpsellData;

      const currentRecommendation =
        postUpSellRecommendation[postUpsellState.currentIndex];

      return (
        <div
          className={`flex  w-full h-full ${justifyContent} ${alignItems} ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}`}>
          <PostUpSellVarient
            currentRecommendation={currentRecommendation}
            selectedVarient={postUpsellState.selectedVariant}
            useAppDispatch={useAppDispatch}
            useAppSelector={useAppSelector}
          />
        </div>
      );
    },
  };
};
