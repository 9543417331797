import 'components/onboarding/formComponents/welcomePage/style.scss';
import { TextFieldControl } from 'components/common/textField';
import { Button } from 'shared-components';
import { FC } from 'react';

const WelcomePage: FC = () => {
  return (
    <div className="welcomePage">
      <div className="heading-text-area">
        <p className="text-bold">{`Welcome! Let's Begin`}</p>
      </div>
      <div className="onboarding-fields">
        <div className="onboarding-field">
          <TextFieldControl
            name="FirstName"
            rules={{
              required: 'This field is required',
            }}
            className="onboarding-input"
            placeholder="Enter First Name"
            fixedSize="md"
            label="First Name"
          />
        </div>
        <div className="onboarding-field">
          <TextFieldControl
            name="LastName"
            rules={{
              required: 'This field is required',
            }}
            className="onboarding-input"
            placeholder="Enter Last Name"
            fixedSize="md"
            label="Last Name"
          />
        </div>
        <div className="onboarding-field">
          <TextFieldControl
            name="PhoneNumber"
            rules={{
              required: 'This field is required',
            }}
            className="onboarding-input"
            placeholder="Enter Phone Number"
            fixedSize="md"
            label="Phone Number"
          />
        </div>
      </div>
      <div className="button-field">
        <Button
          variant="phoenix"
          className="onboarding-continue-button"
          type="submit"
          label="CONTINUE"
        />
      </div>
    </div>
  );
};

export default WelcomePage;
