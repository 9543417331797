import React from 'react';
import { CartItem } from './cart-item/CartItem';
import { V2LineItem } from '../../interface/checkoutInterface';
import LoadingSpinner from '../shared/LoadingSpinner';
import { NoCartItem } from './cart-item/NoCartItem';
import { MarginProps } from '../../../page-builder/puck/reusable-props/margin';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';

export const CartComponent: React.FC<
  MarginProps & ReduxSelectorProps & ReduxDispatchProps
> = ({ margin, useAppSelector, useAppDispatch }) => {
  const isCartLoading = useAppSelector(state => state.cart.isCartLoading);
  const lineItems = useAppSelector(state => state.cart?.cartData?.LineItems);

  return (
    <>
      <ul
        className={`divide-y divide-gray-200 text-sm font-medium text-gray-900 ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
        <div
          className={`relative w-full ${isCartLoading ? 'opacity-20' : 'opacity-100'}`}>
          {(!lineItems || lineItems?.length === 0) && <NoCartItem />}
          {lineItems?.map((product: V2LineItem) => (
            <CartItem
              key={product.ProductID}
              product={product}
              useAppDispatch={useAppDispatch}
              useAppSelector={useAppSelector}
            />
          ))}
          {isCartLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </ul>
    </>
  );
};
