import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import storeService from 'services/storeService';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { numberRegex } from 'utils/constants';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import { subscription_frequency, subscription_trial_unit } from 'utils/helper';
import { ISubscriptionPlan } from 'interface/settingsInterface';
import { showNotification } from 'helper/common/commonFunctions';
import { Select } from 'shared-components';
dayjs.extend(utc);

interface IProps {
  handleClose: () => void;
  onApiResponse?: (_: boolean) => void;
  onSuccess: () => void;
  defaultStoreValues: ISubscriptionPlan;
}

const AddSubscriptionPlan: FC<IProps> = ({
  handleClose,
  defaultStoreValues,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue } = useForm<ISubscriptionPlan>({
    defaultValues: defaultStoreValues,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!inputValue) return 0;
    const isValid = numberRegex.test(inputValue);
    if (!isValid) return null;
    const numericValue = parseFloat(inputValue);
    return numericValue;
  };

  const createSubscriptionPlan = async (data: ISubscriptionPlan) => {
    setLoading(true);
    const res = await storeService.createSubscriptionPlan(data);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Subscription Added Successfully');
      onSuccess();
    }
    handleClose();
  };
  const updateSubscriptionPlan = async (data: ISubscriptionPlan) => {
    setLoading(true);
    const res = await storeService.updateSubscriptionPlan(data);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Subscription Updated Successfully');
      onSuccess();
    }
    handleClose();
  };

  const handleFormsSubmit = (data: ISubscriptionPlan) => {
    if (!defaultStoreValues.ID) {
      createSubscriptionPlan(data);
    } else {
      updateSubscriptionPlan(data);
    }
  };

  const trimValue = (value: string, field: keyof ISubscriptionPlan) => {
    const trimmedValue = value.trim();
    setValue(field, trimmedValue);
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: !defaultStoreValues.ID ? 'Add' : 'Update',
            form: 'AddSubscriptionPlan',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={`${!defaultStoreValues.ID ? 'Add' : 'Update'} Subscription Plan`}
        className="flex-container">
        <div className="popup-section">
          <form
            className="grid gap-3"
            id="AddSubscriptionPlan"
            onSubmit={handleSubmit(handleFormsSubmit)}>
            <Controller
              name="Name"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    errorString={error?.message}
                    label="Name"
                    onBlur={value => trimValue(value.target.value, 'Name')}
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter Name"
                    ref={ref}
                  />
                );
              }}
            />
            <Controller
              name="Description"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...rest}
                    errorString={error?.message}
                    label="Description"
                    onBlur={value =>
                      trimValue(value.target.value, 'Description')
                    }
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter Description"
                    ref={ref}
                  />
                );
              }}
            />
            <div className="flex gap-5">
              <Controller
                name="FreeTrialUnit"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({ field: { ...rest }, fieldState: { error } }) => {
                  return (
                    <Select
                      {...rest}
                      error={error?.message}
                      className="w-full"
                      label="Trail period unit"
                      options={subscription_trial_unit}
                      placeholder="Select Unit"
                    />
                  );
                }}
              />
              <Controller
                name="FreeTrialLength"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({
                  field: { ref, value, onChange, ...rest },
                  fieldState: { error },
                }) => {
                  const handleChange = (
                    e: React.ChangeEvent<HTMLInputElement>,
                  ) => {
                    onChange(handleInputChange(e) ?? value);
                  };
                  return (
                    <TextField
                      {...rest}
                      value={value}
                      errorString={error?.message}
                      label="Interval"
                      className="w-full"
                      fixedSize="md"
                      placeholder="Enter Trial period"
                      ref={ref}
                      onChange={handleChange}
                    />
                  );
                }}
              />
            </div>

            <div className="flex  gap-5">
              <Controller
                name="Frequency"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({ field: { ...rest }, fieldState: { error } }) => {
                  return (
                    <Select
                      {...rest}
                      className="w-full"
                      label="Frequency Unit"
                      error={error?.message}
                      options={subscription_frequency}
                      placeholder="Select Frequency"
                    />
                  );
                }}
              />
              <Controller
                name="Interval"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({
                  field: { ref, value, onChange, ...rest },
                  fieldState: { error },
                }) => {
                  const handleChange = (
                    e: React.ChangeEvent<HTMLInputElement>,
                  ) => {
                    onChange(handleInputChange(e) ?? value);
                  };
                  return (
                    <TextField
                      {...rest}
                      value={value}
                      errorString={error?.message}
                      label="Interval"
                      className="w-full"
                      fixedSize="md"
                      placeholder="Enter Authorization period"
                      ref={ref}
                      onChange={handleChange}
                    />
                  );
                }}
              />
            </div>
            <Controller
              name="Amount"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, onChange, ...rest },
                fieldState: { error },
              }) => {
                const handleChange = (
                  e: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  onChange(
                    e.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*?)\..*/g, '$1'),
                  );
                };
                return (
                  <TextField
                    {...rest}
                    value={value}
                    errorString={error?.message}
                    label="Price of subscription"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter price"
                    ref={ref}
                    onChange={handleChange}
                  />
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default AddSubscriptionPlan;
