import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import globalUpSellService from 'services/upSellService';
import { showNotification } from 'helper/common/commonFunctions';
import { IPreUpSellData } from '..';
import ProductsSearch from '../../postUpsells/productsSearch';
dayjs.extend(utc);

interface IProps {
  handleClose: () => void;
  onSuccess: () => void;
  defaultStoreValues: IPreUpSellData;
  channelId: string;
  shopId: string;
  selectedPreUpSells: IPreUpSellData[];
  storeId: string;
}

const AddPreUpSell: FC<IProps> = ({
  handleClose,
  defaultStoreValues,
  onSuccess,
  channelId,
  storeId,
  shopId,
  selectedPreUpSells,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IPreUpSellData>({
    defaultValues: defaultStoreValues,
  });

  const addPreUpSell = async (data: IPreUpSellData) => {
    if (selectedPreUpSells.some(store => store.productId === data.productId)) {
      showNotification('error', 'Product already added');
      return;
    }
    setLoading(true);
    const res = await globalUpSellService.createupSell({
      ChannelID: channelId,
      ProductID: data.productId,
    });
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'PreUpSell Added Successfully');
      onSuccess();
    }
    handleClose();
  };

  const updatePreUpSell = async (data: IPreUpSellData) => {
    if (selectedPreUpSells.some(store => store.productId === data.productId)) {
      showNotification('error', 'Product already added');
      return;
    }
    setLoading(true);
    const res = await globalUpSellService.updateUpSell({
      ChannelID: channelId,
      ProductID: data.productId,
      ID: data.ID,
      Version: data.Version,
    });
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'PreUpSell Updated Successfully');
      onSuccess();
    }
    handleClose();
  };

  const handleFormSubmit = (data: IPreUpSellData) => {
    if (!defaultStoreValues.ID) {
      addPreUpSell(data);
    } else {
      updatePreUpSell(data);
    }
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: !defaultStoreValues.ID ? 'Add' : 'Update',
            form: 'AddStoreFront',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={`${!defaultStoreValues.ID ? 'Add' : 'Update'}`}
        className="flex-container">
        <div className="popup-section">
          <form
            className="grid gap-3"
            id="AddStoreFront"
            onSubmit={handleSubmit(handleFormSubmit)}>
            <Controller
              name="productId"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <ProductsSearch
                    errorMessages={error?.message}
                    onChange={val => onChange(val)}
                    shopId={shopId}
                    storeId={storeId}
                    value={value}
                  />
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};
export default AddPreUpSell;
