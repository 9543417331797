import { useEffect, useCallback } from 'react';
import { AddressForm } from './AddressForm';
import { ComponentConfig } from '@measured/puck';
import { useFormContext } from 'react-hook-form';
import useDebounce from '../../../page-builder/hooks/useDebounce';
import { PhoneNumberControl } from '../../../page-builder/components/form-fields/PhoneNumber';
import { phoneRegExp } from '../../../page-builder/utils/constants';
import { FormData } from '../../../page-builder/interface/checkoutInterface';
import { ShippingMethod } from '../../../page-builder/interface/checkoutInterface';
import {
  setApiLoading,
  setAvailableShippingMethods,
} from '../../../page-builder/redux/slices/checkoutSlice';
import { setCheckoutErrorMessage } from '../../../page-builder/redux/slices/commonSlice';
import checkoutService from '../../api/checkoutService';
import {
  marginFields,
  MarginProps,
} from '../../../page-builder/puck/reusable-props/margin';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import { ExtractPhxRequestId } from '../../utils/extractPhoenixId';
import { EventPublisher } from '../../utils/publishEvent';

export const ShippingForm = ({
  useAppDispatch,
  useAppSelector,
}: ReduxDispatchProps & ReduxSelectorProps): ComponentConfig<MarginProps> => {
  return {
    label: (
      <CustomTooltip title="ShippingForm">
        <span>Shipping Form</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...marginFields,
    },
    defaultProps: {
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
    },
    render: ({ margin }) => {
      const dispatch = useAppDispatch();
      const {
        watch,
        setValue,
        formState: { errors },
      } = useFormContext<FormData>();

      const cartData = useAppSelector(state => state.cart?.cartData);
      const storeData = useAppSelector(state => state.store.storeData);
      const publish = EventPublisher.getInstance().publish;

      const {
        Address1,
        Address2,
        City,
        CountryCode,
        FirstName,
        LastName,
        Organization,
        ProvinceCode,
        Zip,
      } = watch('Shipping');

      const isShippingAddressValid =
        FirstName &&
        LastName &&
        Address1 &&
        City &&
        CountryCode &&
        ProvinceCode &&
        Zip &&
        !errors.Shipping?.FirstName &&
        !errors.Shipping?.LastName &&
        !errors.Shipping?.Address1 &&
        !errors.Shipping?.City &&
        !errors.Shipping?.CountryCode &&
        !errors.Shipping?.ProvinceCode &&
        !errors.Shipping?.Zip;

      const updateShippingDetails = useCallback(async () => {
        if (
          isShippingAddressValid &&
          cartData?.ID &&
          storeData?.ShopID &&
          storeData?.StoreID
        ) {
          const payload = {
            Address: {
              Address1,
              Address2,
              City,
              CountryCode,
              FirstName,
              LastName,
              Organization,
              ProvinceCode,
              Zip,
            },
            ID: cartData.ID,
            ShopID: storeData.ShopID,
            StoreID: storeData.StoreID,
          };

          dispatch(setApiLoading(true));
          try {
            const res = await checkoutService.setShippingAddress(payload);
            publish('ShippingAdded', {
              eventName: 'ShippingAdded',
              eventId: ExtractPhxRequestId(res),
              shopId: storeData.ShopID,
              cart: res.data.Cart,
            });
            dispatch(
              setAvailableShippingMethods(
                res?.data?.Cart.AvailableShippingMethods as ShippingMethod[],
              ),
            );
          } catch (error: unknown) {
            if (error instanceof Error) {
              dispatch(setCheckoutErrorMessage(error.message));
              return;
            }

            dispatch(setCheckoutErrorMessage('An unknown error occurred'));
          }

          dispatch(setApiLoading(false));
        }
      }, [
        dispatch,
        isShippingAddressValid,
        cartData?.ID,
        storeData?.ShopID,
        storeData?.StoreID,
        Address1,
        Address2,
        City,
        CountryCode,
        FirstName,
        LastName,
        Organization,
        ProvinceCode,
        Zip,
      ]);

      const updateShippingDetailsDebounce = useDebounce(
        updateShippingDetails,
        500,
      );

      useEffect(() => {
        updateShippingDetailsDebounce();
      }, [
        isShippingAddressValid,
        FirstName,
        LastName,
        Address1,
        City,
        CountryCode,
        ProvinceCode,
        Zip,
        errors.Shipping,
        updateShippingDetailsDebounce,
      ]);

      useEffect(() => {
        setValue('phone_code', '+1');
      }, [setValue]);

      return (
        <div
          className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
          <AddressForm fieldPrefix="Shipping" />
          <div>
            <PhoneNumberControl
              selectProps={{
                id: 'phone_code',
                autoComplete: 'phone_code',
              }}
              inputProps={{
                id: 'PhoneNumber',
                placeholder: 'Phone',
              }}
              phoneNumberName="CustomerData.PhoneNumber"
              phoneCodeName="phone_code"
              rules={{
                pattern: {
                  value: phoneRegExp,
                  message: 'Please enter a valid phone number',
                },
              }}
            />
          </div>
        </div>
      );
    },
  };
};
