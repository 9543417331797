import { TextFieldControl } from 'components/common/textField';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { PopUpModal, showNotification } from 'shared-components';
import { ICreateTrackingBody } from '..';
import conversionTrackingService from 'services/conversionTracking';
import AutoCompleteSelect from 'components/common/selectAutoComplete';

export const trackingtTypes = [
  {
    label: 'Google',
    value: 'google',
  },
  {
    label: 'Meta',
    value: 'meta',
  },
  {
    label: 'Tiktok',
    value: 'tiktok',
  },
];

interface IProps {
  selectedValue: ICreateTrackingBody;
  handleClose: () => void;
  channelId: string;
  onSuccess: () => void;
}

const AddOrEditTracking: React.FC<IProps> = ({
  selectedValue,
  channelId,
  handleClose,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const methods = useForm<ICreateTrackingBody>({
    defaultValues: selectedValue,
  });
  const { control, handleSubmit } = methods;

  const addTracking = async (data: ICreateTrackingBody) => {
    setLoading(true);
    const response = await conversionTrackingService.createTracking({
      ChannelID: channelId,
      Creds: data.Creds,
      Type: data.Type,
    });
    if (response.status === 200) {
      showNotification('success', 'Added Successfully');
      onSuccess();
    }
    handleClose();
    setLoading(false);
  };

  const updateTracking = async (data: ICreateTrackingBody) => {
    setLoading(true);
    const response = await conversionTrackingService.updateTracking(data);
    if (response.status === 200) {
      showNotification('success', 'Updated Successfully');
      onSuccess();
    }
    handleClose();
    setLoading(false);
  };
  const onSubmit = async (data: ICreateTrackingBody) => {
    if (selectedValue?.ID) {
      updateTracking(data);
    } else {
      addTracking(data);
    }
  };
  return (
    <PopUpModal
      open={true}
      handleClose={() => {
        handleClose();
      }}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => {
            handleClose();
          },
        },
        {
          buttonType: 'positive',
          form: 'trackingForm',
          buttonLabel: selectedValue?.ID ? 'Update' : 'Add',
          type: 'submit',
          disabled: loading,
          loading: loading,
        },
      ]}
      heading={selectedValue?.ID ? 'Update Tracking' : 'Add Tracking'}
      className="flex-container">
      <div className="popup-section">
        <div className="conversion-tracking">
          <form
            id="trackingForm"
            onSubmit={handleSubmit(onSubmit)}
            className="filter-selection-form grid gap-2.5">
            <FormProvider {...methods}>
              <Controller
                name="Type"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <AutoCompleteSelect
                      options={trackingtTypes}
                      placeholder="select"
                      labelKey="label"
                      onChange={val => onChange(val?.value || null)}
                      value={
                        trackingtTypes?.find(type => type.value === value) ||
                        null
                      }
                      className="w-full dropdown-field"
                      label="Type"
                    />
                  );
                }}
              />
              <TextFieldControl
                name="Creds.PixelID"
                fixedSize="md"
                label="Pixel ID"
                placeholder="Enter meta pixel ID"
                rules={{ required: 'Pixel ID is required' }}
              />
              <TextFieldControl
                name="Creds.AccessToken"
                fixedSize="md"
                label="Access Token"
                placeholder="Enter meta access token"
                rules={{ required: 'Token is required' }}
              />
            </FormProvider>
          </form>
        </div>
      </div>
    </PopUpModal>
  );
};

export default AddOrEditTracking;
