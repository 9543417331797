import { Config, Data } from '@measured/puck';
import {
  Common,
  defaultComponentProps,
  mandatoryComponents,
  navigationPathComponents,
} from 'shared-components';
import { useState } from 'react';
import { ConvertJsonToBase64 } from 'shared-components';
import { useAppDispatch } from 'hooks/reduxHooks';
import {
  showNotification,
  StatusCode,
  toasterMessage,
} from 'shared-components';
import { ICheckoutPage } from 'shared-components';
import { PopUpModal } from 'shared-components';
import { Loader } from 'shared-components';
import _ from 'lodash';
import storeService from 'services/storeService';
import Editor from '../editor';
import { setMandatoryComponents } from 'shared-components/src/page-builder/redux/slices/commonSlice';

const extractUsedComponents = (data: Data) => {
  const set = new Set<string>();
  const usedNavigationFields: string[] = [];
  data.content.forEach(content => set.add(content.type as string));
  if (data.zones) {
    Object.values(data.zones).forEach(zone => {
      zone.forEach(c => {
        if (
          navigationPathComponents.includes(c.type as string) &&
          c?.props?.nextPagePath?.label === '' &&
          c?.props?.nextPagePath?.value === ''
        ) {
          usedNavigationFields.push(c.type as string);
        }
        set.add(c.type as string);
      });
    });
  }
  return {
    usedNavigationFields: usedNavigationFields,
    usedComponents: Array.from(set),
  };
};
interface IProps {
  data: Data;
  config: Config;
  checkoutPage: ICheckoutPage | null;
  onSuccessCallBack: () => void;
}
// Render Puck editor
const HomeEdit = ({
  data,
  config,
  checkoutPage,
  onSuccessCallBack,
}: IProps) => {
  const dispatch = useAppDispatch();
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const [missingComponents, setMissingComponents] = useState<string[]>([]);
  const [isDuplicateBilling, setDuplicateBilling] = useState<boolean>(false);
  const [isDuplicatePayment, setDuplicatePayment] = useState<boolean>(false);
  const [missingNavigationFields, setMissingNavigationFields] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  // Describe the initial data
  const template = new URLSearchParams(location.search).get('template') || '';
  const [isConfigUpdated, setConfigUpdated] = useState<string | null>(null);
  const [payload, setPayload] = useState<Data>();

  // Save the data to your database
  const save = async () => {
    if (!payload) {
      return;
    }

    console.log(payload, template, `page data`);
    if (checkoutPage) {
      checkoutPage.PageData = await ConvertJsonToBase64(payload);
      setLoading(true);
      const res = await storeService.updateCheckoutPage(checkoutPage);
      setLoading(false);
      if (res.status === 200) {
        showNotification(StatusCode.success, toasterMessage.pageUpdateSuccess);
        onSuccessCallBack();
      }
    }
  };

  const handleClose = () => {
    setPopupOpen(false);
    setConfigUpdated(null);
  };

  const handleSave = () => {
    setPopupOpen(false);
    setConfigUpdated(null);
    save();
  };

  const openAlert = (payload: Data) => {
    const { usedNavigationFields, usedComponents } =
      extractUsedComponents(payload);

    const Component = mandatoryComponents[template]?.filter(
      component => !usedComponents?.find(c => c.startsWith(component)),
    );
    const isPaymentDuplicate =
      usedComponents.filter(item =>
        item.startsWith(defaultComponentProps.payment),
      ).length === 2;
    setDuplicatePayment(isPaymentDuplicate);

    if (payload?.zones) {
      const isBillingAddress: boolean = Object.entries(payload.zones).some(
        ([, zoneItems]) =>
          zoneItems.length > 1 &&
          zoneItems.some(
            item =>
              (item.type === 'Payment' || item.type === 'PaymentRadio') &&
              item.props.isShowBillingAddress,
          ),
      );
      if (isBillingAddress) {
        if (
          usedComponents.some(
            item => item === defaultComponentProps.billingForm,
          )
        ) {
          setDuplicateBilling(true);
          Component.push(defaultComponentProps.billingForm);
        } else {
          const indexToRemove = Component.indexOf(
            defaultComponentProps.billingForm,
          );
          if (indexToRemove !== -1) {
            Component.splice(indexToRemove, 1);
          }
        }
      }
    }
    setMissingComponents(Component);
    dispatch(setMandatoryComponents(Component));
    setMissingNavigationFields(usedNavigationFields);
    if (
      mandatoryComponents[template]?.length > 0 &&
      Component?.length === 0 &&
      !isPaymentDuplicate &&
      usedNavigationFields.length === 0
    ) {
      setPayload(payload);
      const areEqual = _.isEqual(data, payload);
      if (!areEqual) {
        setPopupOpen(true);
        setConfigUpdated(Common.UNSAVED_CHANGES);
      } else {
        setPopupOpen(true);
        setConfigUpdated(Common.NO_CHANGES);
      }
    } else {
      setPopupOpen(true);
    }
  };

  return (
    <div className="relative h-full">
      <Editor
        config={config}
        data={data}
        onPublish={openAlert}
        checkoutPage={checkoutPage}
      />
      <div>
        {isPopupOpen && (
          <PopUpModal
            open={isPopupOpen}
            handleClose={handleClose}
            buttons={
              isConfigUpdated === Common.UNSAVED_CHANGES
                ? [
                    {
                      buttonType: 'negative',
                      buttonLabel: 'No',
                      buttonFunction: handleClose,
                    },
                    {
                      buttonType: 'positive',
                      buttonLabel: 'Yes Proceed',
                      buttonFunction: handleSave,
                    },
                  ]
                : [
                    {
                      buttonType: 'negative',
                      buttonLabel: 'Close',
                      buttonFunction: handleClose,
                    },
                  ]
            }
            heading={''}>
            <div className="popup-section !w-full">
              {isConfigUpdated ? (
                <>
                  <p className="popup-question flex flex-wrap justify-center items-center">
                    {isConfigUpdated}
                  </p>
                </>
              ) : (
                <>
                  {isDuplicateBilling ? (
                    <p className="popup-question flex flex-wrap justify-center items-center">
                      Having multiple billing address components, please remove
                      any one.
                    </p>
                  ) : (
                    missingComponents?.length > 0 && (
                      <p className="popup-question flex flex-wrap justify-center items-center">
                        Please add {missingComponents?.join(', ')} components to
                        publish the page.
                      </p>
                    )
                  )}
                  {missingNavigationFields?.length > 0 && (
                    <p className="popup-question flex flex-wrap justify-center items-center">
                      Please add navigation Path for{' '}
                      {missingNavigationFields?.join(', ')} components to
                      publish the page.
                    </p>
                  )}
                  {isDuplicatePayment && (
                    <p className="popup-question flex flex-wrap justify-center items-center">
                      Pleade remove any one payment component.
                    </p>
                  )}
                </>
              )}
            </div>
          </PopUpModal>
        )}
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default HomeEdit;
