import { FieldLabel, Fields } from '@measured/puck';
import { Select } from '../../../page-builder/components/form-fields/Select';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CustomTooltip from '../../../components/common/tooltip';

export type MarginSubProps = {
  top: string;
  bottom: string;
  left: string;
  right: string;
};
export type MarginType = {
  desktop: MarginSubProps;
  tablet: MarginSubProps;
  mobile: MarginSubProps;
};
export type MarginProps = {
  margin: MarginType;
};
const marginTopOptions = {
  desktop: [
    { label: '0', value: 'mt-0' },
    { label: '1', value: 'mt-1' },
    { label: '2', value: 'mt-2' },
    { label: '4', value: 'mt-4' },
    { label: '6', value: 'mt-6' },
    { label: '8', value: 'mt-8' },
    { label: '10', value: 'mt-10' },
    { label: '12', value: 'mt-12' },
    { label: '14', value: 'mt-14' },
    { label: '16', value: 'mt-16' },
    { label: '20', value: 'mt-20' },
    { label: '24', value: 'mt-24' },
    { label: '28', value: 'mt-28' },
    { label: '32', value: 'mt-32' },
    { label: '36', value: 'mt-36' },
    { label: '40', value: 'mt-40' },
    { label: '44', value: 'mt-44' },
    { label: '48', value: 'mt-48' },
    { label: '52', value: 'mt-52' },
    { label: '56', value: 'mt-56' },
    { label: '60', value: 'mt-60' },
    { label: '64', value: 'mt-64' },
    { label: '72', value: 'mt-72' },
    { label: '80', value: 'mt-80' },
    { label: '96', value: 'mt-96' },
  ],
  tablet: [
    { label: '0', value: 'max-md:mt-0' },
    { label: '1', value: 'max-md:mt-1' },
    { label: '2', value: 'max-md:mt-2' },
    { label: '4', value: 'max-md:mt-4' },
    { label: '6', value: 'max-md:mt-6' },
    { label: '8', value: 'max-md:mt-8' },
    { label: '10', value: 'max-md:mt-10' },
    { label: '12', value: 'max-md:mt-12' },
    { label: '14', value: 'max-md:mt-14' },
    { label: '16', value: 'max-md:mt-16' },
    { label: '20', value: 'max-md:mt-20' },
    { label: '24', value: 'max-md:mt-24' },
    { label: '28', value: 'max-md:mt-28' },
    { label: '32', value: 'max-md:mt-32' },
  ],
  mobile: [
    { label: '0', value: 'max-sm:mt-0' },
    { label: '1', value: 'max-sm:mt-1' },
    { label: '2', value: 'max-sm:mt-2' },
    { label: '4', value: 'max-sm:mt-4' },
    { label: '6', value: 'max-sm:mt-6' },
    { label: '8', value: 'max-sm:mt-8' },
    { label: '10', value: 'max-sm:mt-10' },
    { label: '12', value: 'max-sm:mt-12' },
    { label: '14', value: 'max-sm:mt-14' },
    { label: '16', value: 'max-sm:mt-16' },
    { label: '20', value: 'max-sm:mt-20' },
    { label: '24', value: 'max-sm:mt-24' },
    { label: '28', value: 'max-sm:mt-28' },
    { label: '32', value: 'max-sm:mt-32' },
  ],
};
const marginBottomOptions = {
  desktop: [
    { label: '0', value: 'mb-0' },
    { label: '1', value: 'mb-1' },
    { label: '2', value: 'mb-2' },
    { label: '4', value: 'mb-4' },
    { label: '6', value: 'mb-6' },
    { label: '8', value: 'mb-8' },
    { label: '10', value: 'mb-10' },
    { label: '12', value: 'mb-12' },
    { label: '14', value: 'mb-14' },
    { label: '16', value: 'mb-16' },
    { label: '20', value: 'mb-20' },
    { label: '24', value: 'mb-24' },
    { label: '28', value: 'mb-28' },
    { label: '32', value: 'mb-32' },
    { label: '36', value: 'mb-36' },
    { label: '40', value: 'mb-40' },
    { label: '44', value: 'mb-44' },
    { label: '48', value: 'mb-48' },
    { label: '52', value: 'mb-52' },
    { label: '56', value: 'mb-56' },
    { label: '60', value: 'mb-60' },
    { label: '64', value: 'mb-64' },
    { label: '72', value: 'mb-72' },
    { label: '80', value: 'mb-80' },
    { label: '96', value: 'mb-96' },
  ],
  tablet: [
    { label: '0', value: 'max-md:mb-0' },
    { label: '1', value: 'max-md:mb-1' },
    { label: '2', value: 'max-md:mb-2' },
    { label: '4', value: 'max-md:mb-4' },
    { label: '6', value: 'max-md:mb-6' },
    { label: '8', value: 'max-md:mb-8' },
    { label: '10', value: 'max-md:mb-10' },
    { label: '12', value: 'max-md:mb-12' },
    { label: '14', value: 'max-md:mb-14' },
    { label: '16', value: 'max-md:mb-16' },
    { label: '20', value: 'max-md:mb-20' },
    { label: '24', value: 'max-md:mb-24' },
    { label: '28', value: 'max-md:mb-28' },
    { label: '32', value: 'max-md:mb-32' },
  ],
  mobile: [
    { label: '0', value: 'max-sm:mb-0' },
    { label: '1', value: 'max-sm:mb-1' },
    { label: '2', value: 'max-sm:mb-2' },
    { label: '4', value: 'max-sm:mb-4' },
    { label: '6', value: 'max-sm:mb-6' },
    { label: '8', value: 'max-sm:mb-8' },
    { label: '10', value: 'max-sm:mb-10' },
    { label: '12', value: 'max-sm:mb-12' },
    { label: '14', value: 'max-sm:mb-14' },
    { label: '16', value: 'max-sm:mb-16' },
    { label: '20', value: 'max-sm:mb-20' },
    { label: '24', value: 'max-sm:mb-24' },
    { label: '28', value: 'max-sm:mb-28' },
    { label: '32', value: 'max-sm:mb-32' },
  ],
};
const marginLeftOptions = {
  desktop: [
    { label: '0', value: 'ml-0' },
    { label: '1', value: 'ml-1' },
    { label: '2', value: 'ml-2' },
    { label: '4', value: 'ml-4' },
    { label: '6', value: 'ml-6' },
    { label: '8', value: 'ml-8' },
    { label: '10', value: 'ml-10' },
    { label: '12', value: 'ml-12' },
    { label: '14', value: 'ml-14' },
    { label: '16', value: 'ml-16' },
    { label: '20', value: 'ml-20' },
    { label: '24', value: 'ml-24' },
    { label: '28', value: 'ml-28' },
    { label: '32', value: 'ml-32' },
    { label: '36', value: 'ml-36' },
    { label: '40', value: 'ml-40' },
    { label: '44', value: 'ml-44' },
    { label: '48', value: 'ml-48' },
    { label: '52', value: 'ml-52' },
    { label: '56', value: 'ml-56' },
    { label: '60', value: 'ml-60' },
    { label: '64', value: 'ml-64' },
    { label: '72', value: 'ml-72' },
    { label: '80', value: 'ml-80' },
    { label: '96', value: 'ml-96' },
  ],
  tablet: [
    { label: '0', value: 'max-md:ml-0' },
    { label: '1', value: 'max-md:ml-1' },
    { label: '2', value: 'max-md:ml-2' },
    { label: '4', value: 'max-md:ml-4' },
    { label: '6', value: 'max-md:ml-6' },
    { label: '8', value: 'max-md:ml-8' },
    { label: '10', value: 'max-md:ml-10' },
    { label: '12', value: 'max-md:ml-12' },
    { label: '14', value: 'max-md:ml-14' },
    { label: '16', value: 'max-md:ml-16' },
    { label: '20', value: 'max-md:ml-20' },
    { label: '24', value: 'max-md:ml-24' },
    { label: '28', value: 'max-md:ml-28' },
    { label: '32', value: 'max-md:ml-32' },
  ],
  mobile: [
    { label: '0', value: 'max-sm:ml-0' },
    { label: '1', value: 'max-sm:ml-1' },
    { label: '2', value: 'max-sm:ml-2' },
    { label: '4', value: 'max-sm:ml-4' },
    { label: '6', value: 'max-sm:ml-6' },
    { label: '8', value: 'max-sm:ml-8' },
    { label: '10', value: 'max-sm:ml-10' },
    { label: '12', value: 'max-sm:ml-12' },
    { label: '14', value: 'max-sm:ml-14' },
    { label: '16', value: 'max-sm:ml-16' },
    { label: '20', value: 'max-sm:ml-20' },
    { label: '24', value: 'max-sm:ml-24' },
    { label: '28', value: 'max-sm:ml-28' },
    { label: '32', value: 'max-sm:ml-32' },
  ],
};
const marginRightOptions = {
  desktop: [
    { label: '0', value: 'mr-0' },
    { label: '1', value: 'mr-1' },
    { label: '2', value: 'mr-2' },
    { label: '4', value: 'mr-4' },
    { label: '8', value: 'mr-8' },
    { label: '10', value: 'mr-10' },
    { label: '12', value: 'mr-12' },
    { label: '14', value: 'mr-14' },
    { label: '16', value: 'mr-16' },
    { label: '20', value: 'mr-20' },
    { label: '24', value: 'mr-24' },
    { label: '28', value: 'mr-28' },
    { label: '32', value: 'mr-32' },
    { label: '36', value: 'mr-36' },
    { label: '40', value: 'mr-40' },
    { label: '44', value: 'mr-44' },
    { label: '48', value: 'mr-48' },
    { label: '52', value: 'mr-52' },
    { label: '56', value: 'mr-56' },
    { label: '60', value: 'mr-60' },
    { label: '64', value: 'mr-64' },
    { label: '72', value: 'mr-72' },
    { label: '80', value: 'mr-80' },
    { label: '96', value: 'mr-96' },
  ],
  tablet: [
    { label: '0', value: 'max-md:mr-0' },
    { label: '1', value: 'max-md:mr-1' },
    { label: '2', value: 'max-md:mr-2' },
    { label: '4', value: 'max-md:mr-4' },
    { label: '8', value: 'max-md:mr-8' },
    { label: '10', value: 'max-md:mr-10' },
    { label: '12', value: 'max-md:mr-12' },
    { label: '14', value: 'max-md:mr-14' },
    { label: '16', value: 'max-md:mr-16' },
    { label: '20', value: 'max-md:mr-20' },
    { label: '24', value: 'max-md:mr-24' },
    { label: '28', value: 'max-md:mr-28' },
    { label: '32', value: 'max-md:mr-32' },
  ],
  mobile: [
    { label: '0', value: 'max-sm:mr-0' },
    { label: '1', value: 'max-sm:mr-1' },
    { label: '2', value: 'max-sm:mr-2' },
    { label: '4', value: 'max-sm:mr-4' },
    { label: '8', value: 'max-sm:mr-8' },
    { label: '10', value: 'max-sm:mr-10' },
    { label: '12', value: 'max-sm:mr-12' },
    { label: '14', value: 'max-sm:mr-14' },
    { label: '16', value: 'max-sm:mr-16' },
    { label: '20', value: 'max-sm:mr-20' },
    { label: '24', value: 'max-sm:mr-24' },
    { label: '28', value: 'max-sm:mr-28' },
    { label: '32', value: 'max-sm:mr-32' },
  ],
};
export const marginFields: Fields<MarginProps> = {
  margin: {
    type: 'custom',
    label: 'Margin',
    render: ({ value, onChange }) => {
      const [isOpen, setIsOpen] = useState(false);

      const toggleAccordion = () => {
        setIsOpen(!isOpen);
      };

      const handleChange = (
        platform: string,
        key: string,
        option: React.ChangeEvent<HTMLSelectElement>,
      ) => {
        onChange({
          ...value,
          [platform]: {
            ...value[platform as keyof MarginType],
            [key]: option.target.value,
          },
        });
      };
      return (
        <div>
          <div
            className=" flex justify-between font-extrabold cursor-pointer p-3 bg-slate-100 rounded-md pagebuilder-theme"
            onClick={toggleAccordion}>
            <h3>Customize Margin</h3>
            {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </div>
          {isOpen && (
            <div className="flex flex-col gap-4 mt-3  items-center border  border-gray-300 rounded  p-2 w-full pagebuilder-theme">
              <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="desktopMargin">
                        <span>Desktop</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <div className="grid grid-cols-[1fr_1fr] gap-2 border-t-1">
                    <Select
                      value={value.desktop.top}
                      onChange={e => {
                        handleChange('desktop', 'top', e);
                      }}
                      options={marginTopOptions.desktop}
                      label="Top"
                    />
                    <Select
                      value={value.desktop.bottom}
                      onChange={e => {
                        handleChange('desktop', 'bottom', e);
                      }}
                      options={marginBottomOptions.desktop}
                      label="Bottom"
                    />
                    <Select
                      value={value.desktop.left}
                      onChange={e => {
                        handleChange('desktop', 'left', e);
                      }}
                      options={marginLeftOptions.desktop}
                      label="Left"
                    />
                    <Select
                      value={value.desktop.right}
                      onChange={e => {
                        handleChange('desktop', 'right', e);
                      }}
                      options={marginRightOptions.desktop}
                      label="Right"
                    />
                  </div>
                </FieldLabel>
              </div>
              <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="tabletMargin">
                        <span>Tablet</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <div className="grid grid-cols-[1fr_1fr] gap-2 ">
                    <Select
                      value={value.tablet.top}
                      onChange={e => {
                        handleChange('tablet', 'top', e);
                      }}
                      options={marginTopOptions.tablet}
                      label="Top"
                    />
                    <Select
                      value={value.tablet.bottom}
                      onChange={e => {
                        handleChange('tablet', 'bottom', e);
                      }}
                      options={marginBottomOptions.tablet}
                      label="Bottom"
                    />
                    <Select
                      value={value.tablet.left}
                      onChange={e => {
                        handleChange('tablet', 'left', e);
                      }}
                      options={marginLeftOptions.tablet}
                      label="Left"
                    />
                    <Select
                      value={value.tablet.right}
                      onChange={e => {
                        handleChange('tablet', 'right', e);
                      }}
                      options={marginRightOptions.tablet}
                      label="Right"
                    />
                  </div>
                </FieldLabel>
              </div>
              <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="mobileMargin">
                        <span>Mobile</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <div className="grid grid-cols-[1fr_1fr] gap-2">
                    <Select
                      value={value.mobile.top}
                      onChange={e => {
                        handleChange('mobile', 'top', e);
                      }}
                      options={marginTopOptions.mobile}
                      label="Top"
                    />
                    <Select
                      value={value.mobile.bottom}
                      onChange={e => {
                        handleChange('mobile', 'bottom', e);
                      }}
                      options={marginBottomOptions.mobile}
                      label="Bottom"
                    />
                    <Select
                      value={value.mobile.left}
                      onChange={e => {
                        handleChange('mobile', 'left', e);
                      }}
                      options={marginLeftOptions.mobile}
                      label="Left"
                    />
                    <Select
                      value={value.mobile.right}
                      onChange={e => {
                        handleChange('mobile', 'right', e);
                      }}
                      options={marginRightOptions.mobile}
                      label="Right"
                    />
                  </div>
                </FieldLabel>
              </div>
            </div>
          )}
        </div>
      );
    },
  },
};
