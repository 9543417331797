import { useMemo, FC } from 'react';
import { TransactionStats } from 'interface/dashboardInterface';
import { chargeType, transactionStatus } from 'components/dashboard/constant';
import { StackedBarChart } from 'components/common/chart/barChart';
import { backgroundBarPlugin } from 'components/common/chart/barChart/constant';
import { percentage, UsDollarFormatter } from 'utils/helper';

interface IProps {
  groupedTransactionStats: [string, TransactionStats[]][];
}
interface IDatasets {
  revenuePercentageByAmount: number[];
  refundPercentageByAmount: number[];
  voidPercentageByAmount: number[];
  revenueAmount: string[];
  refundAmount: string[];
  voidDataAmount: string[];
}

const SubscriptionRefundRatio: FC<IProps> = ({ groupedTransactionStats }) => {
  const subscriptionRefundRatioData = useMemo(() => {
    const values: IDatasets = {
      revenuePercentageByAmount: [],
      refundPercentageByAmount: [],
      voidPercentageByAmount: [],
      revenueAmount: [],
      refundAmount: [],
      voidDataAmount: [],
    };

    let totalAmount = 0;

    // cumulative total of amount and count for each dataset
    let totalCapturedAmount = 0;
    let totalVoidAmount = 0;
    let totalRefundAmount = 0;

    groupedTransactionStats.forEach(([, statsData]) => {
      let refundAmount = 0;
      let voidAmount = 0;
      let capturedAmount = 0;
      let totalAmountPerDay = 0;

      statsData.forEach(stat => {
        // don't count failed transactions
        if (
          stat.ChargeType !== chargeType.subscription ||
          stat.Status === transactionStatus.failcapture ||
          stat.Status === transactionStatus.failAuth
        ) {
          return;
        }

        totalAmount += stat.Amount;
        totalAmountPerDay += stat.Amount;
        if (
          stat.Status === transactionStatus.refunded ||
          stat.Status === transactionStatus.partialRefund
        ) {
          totalRefundAmount += stat?.Amount;
          refundAmount += stat?.Amount;
        }

        if (stat.Status === transactionStatus.voided) {
          totalVoidAmount += stat?.Amount;
          voidAmount += stat?.Amount;
        } else {
          totalCapturedAmount += stat?.Amount;
          capturedAmount += stat?.Amount;
        }
      });
      // for revenue, refun, void percentage calculation
      values.revenuePercentageByAmount.push(
        percentage(capturedAmount, totalAmountPerDay, true),
      );
      values.refundPercentageByAmount.push(
        percentage(refundAmount, totalAmountPerDay, true),
      );
      values.voidPercentageByAmount.push(
        percentage(voidAmount, totalAmountPerDay, true),
      );
      // For amount calculation
      values.revenueAmount.push(UsDollarFormatter(capturedAmount));
      values.refundAmount.push(UsDollarFormatter(refundAmount));
      values.voidDataAmount.push(UsDollarFormatter(voidAmount));
    });

    return {
      labels: groupedTransactionStats.map(([date]) => date),
      datasets: [
        {
          label: 'Revenue',
          borderColor: '#F90182',
          backgroundColor: '#F90182',
          legend: 'Revenue',
          data: values.revenuePercentageByAmount,
          amount: values.revenueAmount, // to show amount on tooltip
          order: 3,
          percentage: percentage(totalCapturedAmount, totalAmount).toFixed(1), // to show amount on legend
          total: UsDollarFormatter(totalCapturedAmount), // to show amount on legend
        },
        {
          label: 'Refund',
          borderColor: '#6AD2FF',
          backgroundColor: '#6AD2FF',
          legend: 'Refund',
          data: values.refundPercentageByAmount,
          amount: values.refundAmount,
          order: 2,
          percentage: percentage(totalRefundAmount, totalAmount).toFixed(1),
          total: UsDollarFormatter(totalRefundAmount),
        },
        {
          label: 'Void',
          borderColor: '#C237F3',
          backgroundColor: '#C237F3',
          legend: 'Void',
          data: values.voidPercentageByAmount,
          amount: values.voidDataAmount,
          order: 1,
          percentage: percentage(totalVoidAmount, totalAmount).toFixed(1),
          total: UsDollarFormatter(totalVoidAmount),
        },
      ],
    };
  }, [groupedTransactionStats]);

  return (
    <div className="md_card_container row-span-2">
      <div className="header">
        <p className="header_text extend_sm">Subscription Refund Ratio</p>
      </div>
      <div className="chart_wrapper">
        <StackedBarChart
          data={subscriptionRefundRatioData}
          className="chart"
          plugins={[backgroundBarPlugin]}
          suffix="%"
        />
      </div>
      <div className="refund_legends">
        {subscriptionRefundRatioData.datasets.map(data => {
          return (
            <div className="sections" key={data?.legend}>
              <div className="legend">
                <span
                  className="indicator"
                  style={{ background: data.backgroundColor }}
                />
                <div>
                  <div>{data.legend}</div>
                  <div className="percentage">
                    ${data.total + '/' + data?.percentage || 0 + '%'}%
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionRefundRatio;
