import React, { useState } from 'react';
import { ComponentConfig, CustomField } from '@measured/puck';
import { MarginProps, marginFields } from '../../puck/reusable-props/margin';
import CreditCardLogos from '../../../assets/svg/credit-logos';
import Afterpay from '../../../assets/svg/after-pay';
import SezzleLogo from '../../../assets/svg/sezzle';
import { RadioGroup } from '@headlessui/react';
import { PaymentController } from '../payment/PaymentController';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import InputField from '../../../components/common/input-field';

export type PaymentRadioProps = {
  subText?: string;
  isShowBillingAddress?: boolean;
} & MarginProps;

interface PaymentLogoSectionProps {
  name: string;
  LogoComponent: React.ElementType;
  logoProps?: React.ComponentProps<'img'>;
}

interface PaymentMethod {
  id: string;
  logo: React.JSX.Element;
  component: React.JSX.Element;
}

const PaymentLogoSection: React.FC<PaymentLogoSectionProps> = ({
  name,
  LogoComponent,
  logoProps,
}) => {
  return (
    <div className="flex justify-between items-center w-full">
      <p className="text-sm text-black">{name}</p>
      <LogoComponent className="pb-1" {...logoProps} />
    </div>
  );
};

const CurrentlyNotAvailable = () => {
  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-[10px] sm:grid-cols-4 items-center justify-center w-full h-full p-5 border-t border-gray-200 mt-2">
      <p className="text-gray-500 text-sm w-full col-span-4 text-center">
        Currently not available
      </p>
    </div>
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const PaymentRadio = ({
  useAppDispatch,
  useAppSelector,
}: ReduxDispatchProps &
  ReduxSelectorProps): ComponentConfig<PaymentRadioProps> => {
  return {
    label: (
      <CustomTooltip title="PaymentRadio">
        <span>Payment Radio</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      subText: {
        type: 'custom',
        label: 'Sub Text',
        render: ({ value, onChange, field }) => (
          <InputField
            value={value}
            field={field as CustomField<string>}
            onChange={onChange}
          />
        ),
      },
      isShowBillingAddress: {
        type: 'radio',
        label: 'Show Billing Address',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      ...marginFields,
    },
    defaultProps: {
      subText: 'All transactions are secured and encrypted',
      isShowBillingAddress: true,
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
    },
    render: ({ margin, isShowBillingAddress, subText }) => {
      const paymentMethods: PaymentMethod[] = [
        {
          id: 'creditCard',
          logo: (
            <PaymentLogoSection
              name="Credit card"
              LogoComponent={CreditCardLogos}
              logoProps={{ className: 'h-9' }}
            />
          ),
          component: (
            <PaymentController
              useAppDispatch={useAppDispatch}
              useAppSelector={useAppSelector}
              fields={{ margin, isShowBillingAddress }}
              fromRadio
            />
          ),
        },
        {
          id: 'sezzle',
          logo: (
            <PaymentLogoSection
              name="Buy Now, Pay Later with Sezzle"
              LogoComponent={SezzleLogo}
              logoProps={{ className: 'h-9 w-12' }}
            />
          ),
          component: <CurrentlyNotAvailable />,
        },
        {
          id: 'afterpay',
          logo: (
            <PaymentLogoSection
              name="Afterpay"
              LogoComponent={Afterpay}
              logoProps={{ className: 'h-12' }}
            />
          ),
          component: <CurrentlyNotAvailable />,
        },
      ];

      const [selected, setSelected] = useState(paymentMethods[0].id);

      return (
        <fieldset
          className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
      ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
      ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
          <p className="text-sm text-gray-500 mb-2">{subText}</p>
          <RadioGroup
            value={selected}
            onChange={newValue => setSelected(newValue)}
            className="rounded-md bg-white">
            {paymentMethods.map((pm, index) => (
              <RadioGroup.Option
                key={pm.id}
                value={pm.id}
                className={({ checked }) =>
                  classNames(
                    index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                    index === paymentMethods.length - 1
                      ? 'rounded-bl-md rounded-br-md'
                      : '',
                    checked
                      ? 'shipping-border z-10 border-indigo-200'
                      : 'border-gray-200',
                    'relative border py-4 px-4 focus:outline-none',
                  )
                }>
                {({ checked }) => (
                  <>
                    <div className="flex items-center w-full">
                      <span
                        className={classNames(
                          checked
                            ? 'check-box border-transparent bg-page_builder_theme'
                            : 'border-gray-300 bg-white',
                          'h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border flex',
                        )}
                        aria-hidden="true">
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <span className="flex-none w-full pl-2 pr-4">
                        {pm.logo}
                      </span>
                    </div>
                    {checked && <div className="mt-2">{pm.component}</div>}
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </fieldset>
      );
    },
  };
};
