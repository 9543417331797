import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Cart as ICart } from 'shared-components/src/page-builder/interface/checkoutInterface';
import SearchFilter from 'components/common/searchFilter';
import { SearchProps } from 'components/transactions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { IOrderTableFilter } from 'interface/orderInterface';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import orderService from 'services/orderService';
import { iterateHeadCellKeys } from 'utils/helper';
import Pagination from 'components/common/pagination';
import { Loader } from 'components/common/loader';
import { get } from 'lodash';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';
import SideFilter from 'components/abandonedCarts/abandonedCartFilter/sideFilter';
import Cart from 'components/abandonedCarts/cart';
import {
  ICartsPayloadBody,
  ICartsSideFilter,
} from 'interface/processorAssignment';
import StatusBadge from 'components/common/statusBadge';
import globalOrderService from 'services/orderService';
import dayjs from 'dayjs';

export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: ICart) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFiedtType?: 'input' | 'select';
  searchFieldOptions?: { label: string; value: string }[];
  inputType?: string;
}

export const defaultCartFilterValues: ICartsSideFilter = {
  Emails: [],
  MaxTotalAmount: 0,
  MinTotalAmount: 0,
  Status: [],
  TrafficChannels: [],
  IncludeNoContactInfo: false,
  StartDate: null,
  EndDate: null,
};

const AbandonedCarts = () => {
  const dispatch = useAppDispatch();
  const [search, setSearchValue] = useState<SearchProps>({});
  const [limit, setLimit] = useState<number>(25);
  const [totalPage, settotalPage] = useState<number>(0);
  const { orderColumnFilter } = useAppSelector(state => state.coloumnFilter);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const [loading, setLoading] = useState<boolean>(false);
  const [cartList, setCartList] = useState<ICart[]>([]);
  const [selected, setSelected] = useState<Map<string, ICart>>(new Map());
  const rowsPerPage = 25;
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState<boolean>(false);

  const headCells: readonly HeadCell[] = [
    {
      id: 'Email',
      label: 'Email',
      key: 'Email',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'PhoneNumber',
      label: 'Phone Number',
      key: 'PhoneNumber',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'IPAddress',
      label: 'IP Address',
      key: 'IPAddress',
      cellRender: row => row?.IPAddress ?? '-',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'HasSubscription',
      label: 'Has Subscription',
      key: 'HasSubscription',
      cellRender: row => (
        <span
          className={row.HasSubscription ? 'text-green-700' : 'text-red-700'}>
          {row.HasSubscription ? 'Yes' : 'No'}
        </span>
      ),
      hide: false,
      showInSearch: true,
      showSortIcon: true,
      searchFiedtType: 'select',
      searchFieldOptions: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
    },
    {
      id: 'Status',
      label: 'Status',
      key: 'Status',
      hide: false,
      cellRender: row => <StatusBadge status={row.Status} />,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'LineItems',
      label: 'Cart',
      key: 'cart',
      cellRender: row => <Cart row={row} />,
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'CostSummary.TotalAmount',
      label: 'Total',
      key: 'Total',
      cellRender: row => row?.CostSummary?.TotalAmount || '-',
      hide: false,
      showInSearch: false,
      showSortIcon: false,
    },
    {
      id: 'TrafficSource',
      label: 'Traffic',
      key: 'TrafficSource',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
  ];

  const [headCellList, setHeadCellList] = useState<HeadCell[]>([
    ...headCells.map(value => {
      return {
        ...value,
        hide: orderColumnFilter?.includes(value.label),
      };
    }),
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const filteredField = iterateHeadCellKeys(headCellList);
  const [page, setPage] = useState<number>(
    Number(searchParams.get('page_count') || 1),
  );
  const [filter, setFilter] = useState<IOrderTableFilter>({
    Descending: searchParams.get('descending') === 'false' ? false : true,
    OrderBy: searchParams.get('order_by') || 'ID',
    Limit: rowsPerPage,
  });
  const [sideFormFilter, setSideFormFilter] = useState<ICartsSideFilter>({
    Emails: searchParams?.get('emails')
      ? JSON.parse(searchParams.get('emails') || '')
      : [],
    MaxTotalAmount: searchParams.get('MaxTotalAmount')
      ? Number(searchParams.get('MaxTotalAmount'))
      : 0,
    MinTotalAmount: searchParams.get('MinTotalAmount')
      ? Number(searchParams.get('MinTotalAmount'))
      : 0,
    Status: searchParams.get('status')
      ? JSON.parse(searchParams.get('status') || '')
      : [],
    StoreIDs: searchParams.get('storeIDs')
      ? JSON.parse(searchParams.get('storeIDs') || '')
      : [],
    TrafficChannels: searchParams.get('TrafficChannels')
      ? JSON.parse(searchParams.get('TrafficChannels') || '')
      : [],
    IncludeNoContactInfo:
      searchParams.get('IncludeNoContactInfo') === 'true' ? true : false,
    StartDate: searchParams.get('StartDate')
      ? dayjs(searchParams.get('StartDate')).tz()
      : null,
    EndDate: searchParams.get('EndDate')
      ? dayjs(searchParams.get('EndDate')).tz()
      : null,
  });

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  const generatePayload = (isSetSearchParams = true) => {
    const {
      Emails,
      Status,
      MinTotalAmount,
      MaxTotalAmount,
      TrafficChannels,
      IncludeNoContactInfo,
      StartDate,
      EndDate,
    } = sideFormFilter;
    const { Descending, OrderBy } = filter;
    const payload: ICartsPayloadBody = {
      ...filter,
      Page: page - 1,
      Limit: limit,
    };
    if (isSetSearchParams) {
      setSearchParams(
        {
          status: Status ? JSON.stringify(Status) : '',
          Emails: Emails ? JSON.stringify(Emails) : '',
          TrafficChannels: TrafficChannels
            ? JSON.stringify(TrafficChannels)
            : '',
          IncludeNoContactInfo:
            IncludeNoContactInfo === true ? 'true' : 'false',
          StartDate: StartDate ? StartDate.tz().format() : '',
          EndDate: EndDate ? EndDate.tz().format() : '',
          MinTotalAmount: MinTotalAmount ? String(MinTotalAmount) : '',
          MaxTotalAmount: MaxTotalAmount ? String(MaxTotalAmount) : '',
          page_count: String(page),
          desending: String(Descending),
          order_by: OrderBy,
        },
        { replace: true },
      );
    }
    payload.IncludeNoContactInfo = IncludeNoContactInfo;
    if (Status?.length) {
      payload.Status = Status;
    } else {
      payload.Status = ['pending', 'failed'];
    }
    if (Emails?.length) {
      payload.Emails = Emails;
    }
    if (MaxTotalAmount) {
      payload.MaxTotalAmount = Number(MaxTotalAmount);
    }
    if (MinTotalAmount) {
      payload.MinTotalAmount = Number(MinTotalAmount);
    }
    if (TrafficChannels?.length) {
      payload.TrafficChannels = TrafficChannels;
    }
    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
    }
    if (storeIds?.length) {
      payload.StoreIDs = storeIds?.map(store => store.ID);
    }
    if (StartDate) {
      payload.StartDate = StartDate;
    }
    if (EndDate) {
      payload.EndDate = EndDate;
    }
    return payload;
  };

  const downloadCsvFile = async () => {
    const payload = generatePayload(false);
    payload.Page = 0;
    payload.Limit = totalPage || 25;
    const response = await globalOrderService.exportAbandonedCart(payload);
    if (response?.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'abandonedCart.csv');
      document.body.appendChild(link);
      link.click();
    }
  };

  const searchCartList = async () => {
    setLoading(true);
    const payload = generatePayload();
    const res = await orderService.listCarts(payload);

    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        setCartList(res?.data?.Result);
        settotalPage(res?.data?.TotalCount);
        setIsNextBtnDisabled(res?.data?.Result?.length < rowsPerPage);
      } else {
        settotalPage(0);
        setIsNextBtnDisabled(true);
        setCartList([]);
      }
    } else {
      settotalPage(0);
      setCartList([]);
    }
    setLoading(false);
  };

  const filterSubmission = (formData: ICartsSideFilter) => {
    setSideFormFilter(formData);
    setPage(1);
  };

  useEffect(() => {
    searchCartList();
  }, [sideFormFilter, filter, page, limit, search, storeIds]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentFilter(''));
    };
  }, []);

  return (
    <div className="orders_wrapper">
      <div className="order">
        <div className="order_search_container">
          <SearchFilter
            filteredField={filteredField}
            setSearchValue={data => {
              setPage(1);
              setSearchValue(data);
            }}
            searchValue={search}
          />
        </div>
        <TableContainer className="Common_Table">
          <Table
            className="orders_table"
            aria-labelledby="tableTitle"
            stickyHeader>
            <TableHead className="table_header">
              <TableRow>
                {headCellList.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell className="table_header_cell" key={headCell.key}>
                      {headCell?.showSortIcon ? (
                        <TableSortLabel
                          className="header_text"
                          active={filter.OrderBy === headCell.id}
                          direction={
                            filter?.OrderBy === headCell.id
                              ? filter.Descending
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => sortHandler(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {cartList?.length > 0 ? (
                cartList.map(row => {
                  const isItemSelected = !!selected.get(row.ID);
                  return (
                    <TableRow
                      hover
                      className="table_row"
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ID}
                      selected={isItemSelected}>
                      {headCellList.map(headCell => {
                        if (headCell.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            className="table_cell"
                            key={headCell.label}
                            component="th"
                            id={headCell.key}
                            scope="row">
                            {headCell?.cellRender
                              ? headCell.cellRender(row)
                              : get(row, headCell.id)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <div className="no-data-row">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          totalData={totalPage}
          limit={limit}
          onRowsPerChange={val => {
            setLimit(val);
          }}
          perPage={rowsPerPage}
          currentPage={page}
          isNextDisabled={isNextBtnDisabled}
          onHandleChange={page => {
            setSelected(new Map());
            setPage(page);
          }}
        />
        <Loader loading={loading} />
      </div>
      <SideFilter
        headCellList={headCellList}
        setHeadCellList={setHeadCellList}
        sideFormFilter={sideFormFilter}
        filterSubmission={filterSubmission}
        exportList={downloadCsvFile}
      />
    </div>
  );
};

export default AbandonedCarts;
