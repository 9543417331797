import { VipProps } from './index';
import { useSearchParams } from 'react-router-dom';
import { TextEditorProps } from '../text-editor/TextEditor';
import { ReduxSelectorProps } from '../../services/constants';

export const Vip = ({
  useAppSelector,
  ...vipData
}: VipProps & TextEditorProps & ReduxSelectorProps) => {
  const storeState = useAppSelector(state => state.store);
  const storeData = { ...storeState.storeData, VipClubInfo: vipData };

  const cartData = useAppSelector(state => state.cart?.cartData);
  const [searchParams] = useSearchParams();

  return (
    <>
      {(searchParams.get('cart') == 'PHOENIX_CART_PREVIEW' ||
        cartData?.HasSubscription) && (
        <>
          <div className="pt-2 border-t border-gray-200 ">
            <div className="border border-black rounded-lg px-6 py-10 sm:py-5 lg:px-8 bg-white">
              <div className="mx-auto max-w-2xl text-center mb-6">
                <h2
                  className={`flex gap-0.5 justify-center items-end font-bold rounded tracking-tight sm:text-1xl`}>
                  {storeData?.VipClubInfo?.title}
                  <span className="text-l relative top-[-3px]">&reg;</span>
                </h2>
                <p className="mt-4 vip-terms-text font-bold text-left text-black">
                  {storeData?.VipClubInfo?.description}
                </p>
                <div className="mt-6 vip-terms-text text-left leading-5 text-black">
                  <span>
                    The VIP Club is an exclusive program that provides you with:
                  </span>
                  {storeData?.VipClubInfo?.vipClubBenefits
                    .split(',')
                    .map((benefit, index) => (
                      <p key={index} className="mb-4">
                        {benefit}
                      </p>
                    ))}
                </div>
                <p className="mt-4 vip-terms-text text-left leading-5 text-black">
                  <div dangerouslySetInnerHTML={{ __html: vipData?.content }} />
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      <>
        <br />
        <div className="text-base border-t border-gray-200 ">
          <br />
          <dd className="text-sm font-medium">
            This offer is exclusively for paying members. Any orders from
            non-paying customers will be refunded and not fulfilled.
          </dd>
        </div>
      </>
    </>
  );
};
