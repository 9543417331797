import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Button } from 'shared-components';
import { ControlledDatePicker } from 'components/common/datePicker';
import { IRiskFormFilter } from 'interface/riskInterface';
import { FC } from 'react';
import { SelectControl } from 'shared-components';
import { transactionTypeOptions } from 'components/dashboard/constant';
import { cardBrandOptions } from 'components/risk/constant';
import { defaultOrderFilterValues } from 'components/risk';
import { Select } from 'shared-components';
import { getDateFilter } from 'utils/helper';
import {
  allDateRange,
  dateRangeOptionsWithAllTime,
} from 'components/dashboard/constant';
import dayjs from 'dayjs';

interface IProps {
  sideFormFilter: IRiskFormFilter;
  filterSubmission: (_data: IRiskFormFilter) => void;
}

const dateFilterOptions = [
  {
    label: 'Acquired',
    value: 'Acquired',
  },
  {
    label: 'Transaction',
    value: 'Transaction',
  },
];

const FilterForm: FC<IProps> = ({ sideFormFilter, filterSubmission }) => {
  const methods = useForm<IRiskFormFilter>({
    defaultValues: sideFormFilter,
  });
  const { handleSubmit, control, reset, setValue, watch } = methods;
  const onSubmit = (data: IRiskFormFilter) => {
    filterSubmission(data);
  };

  return (
    <div className="order-filter-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <FormProvider {...methods}>
          <SelectControl
            name="dateFilterBy"
            label="Date filter by"
            options={dateFilterOptions}
            placeholder="Select"
          />
          <Controller
            name="dateRange"
            control={control}
            render={({ field: { onChange, value, ...rest } }) => {
              const handleChange = (e: string) => {
                if (e === 'All Time') {
                  setValue('startTime', null);
                  setValue('endTime', null);
                } else if (e !== 'Custom Range') {
                  const { start_time, end_time } = getDateFilter(e);
                  setValue('startTime', start_time);
                  setValue('endTime', end_time);
                } else {
                  setValue('startTime', null);
                  setValue('endTime', null);
                }
                onChange(e);
              };
              return (
                <Select
                  onChange={handleChange}
                  {...rest}
                  label={
                    watch('dateFilterBy') === 'Acquired'
                      ? 'Acquisition Date Range'
                      : 'Transaction Date Range'
                  }
                  options={dateRangeOptionsWithAllTime}
                  value={value}
                  placeholder="Select"
                />
              );
            }}
          />
          <ControlledDatePicker
            name="startTime"
            label="Start Date"
            maxDate={dayjs(watch('endTime')).tz()}
            disableFuture={true}
            onAccept={() => {
              setValue('dateRange', allDateRange.customRange);
            }}
            valueHandler={(value: string) => {
              return value ? dayjs(value).tz().startOf('day') : value;
            }}
          />
          <ControlledDatePicker
            name="endTime"
            label="End Date"
            minDate={dayjs(watch('startTime')).tz()}
            disableFuture={true}
            onAccept={() => {
              setValue('dateRange', allDateRange.customRange);
            }}
            valueHandler={(value: string) => {
              return value ? dayjs(value).tz().endOf('day') : value;
            }}
          />
          <SelectControl
            name="cardBrand"
            label="Card Brand"
            options={cardBrandOptions}
            placeholder="Select"
          />
          <SelectControl
            name="transactiontype"
            label="Transaction Type"
            options={transactionTypeOptions}
            placeholder="Select"
          />
          <div className="button_wrapper">
            <Button
              className="transaction_for_submit white-space-nowrap"
              type="submit"
              label="Apply Filter"
            />
            <Button
              variant="secondary"
              className="transaction_for_submit white-space-nowrap"
              onClick={() => {
                reset(defaultOrderFilterValues);
              }}
              label="Clear Filter"
            />
          </div>
        </FormProvider>
      </form>
    </div>
  );
};
export default FilterForm;
