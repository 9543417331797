import { ComponentConfig } from '@measured/puck';
import { spacingOptions } from '../../../../page-builder/puck/reusable-props/options';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import CustomTooltip from '../../../../components/common/tooltip';

export type VerticalSpaceProps = {
  size: string;
  backgroundColor: string;
};

export const VerticalSpace: ComponentConfig<VerticalSpaceProps> = {
  label: (
    <CustomTooltip title="VerticalSpace">
      <span>Vertical Space</span>
    </CustomTooltip>
  ) as React.ReactNode as string,
  fields: {
    size: {
      type: 'select',
      options: spacingOptions,
    },
    backgroundColor: {
      type: 'custom',
      label: 'Background Color',
      render: ({ value, onChange }) => (
        <FieldLabel
          label={
            (
              <CustomTooltip title="backgroundColor">
                <span>Background Color</span>
              </CustomTooltip>
            ) as React.ReactNode as string
          }>
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
  },
  defaultProps: {
    size: '24px',
    backgroundColor: 'white',
  },
  render: ({ size, backgroundColor }) => {
    return <div style={{ height: size, width: '100%', backgroundColor }} />;
  },
};
