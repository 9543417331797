import { ComponentConfig } from '@measured/puck';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import CustomTooltip from '../../../../components/common/tooltip';

export type DividerProps = {
  size: string;
  direction: string;
  bgColor: string;
};

export const sizeOptions = [
  { label: '1px', value: '1px' },
  { label: '2px', value: '2px' },
  { label: '3px', value: '3px' },
  { label: '4px', value: '4px' },
];

export const directionOptions = [
  { label: 'horizontal', value: 'height' },
  { label: 'vertical', value: 'width' },
];

export const Divider: ComponentConfig<DividerProps> = {
  label: (
    <CustomTooltip title="Divider">
      <span>Divider</span>
    </CustomTooltip>
  ) as React.ReactNode as string,
  fields: {
    size: {
      type: 'select',
      options: sizeOptions,
    },
    direction: {
      type: 'select',
      options: directionOptions,
    },
    bgColor: {
      type: 'custom',
      label: 'Background Color',
      render: ({ value, onChange }) => (
        <FieldLabel
          label={
            (
              <CustomTooltip title="backgroundColor">
                <span>Background Color</span>
              </CustomTooltip>
            ) as React.ReactNode as string
          }>
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
  },
  defaultProps: {
    size: '1px',
    direction: 'height',
    bgColor: 'black',
  },
  render: ({ size, direction, bgColor }) => {
    return (
      <div
        style={{
          [direction]: size,
          [direction === 'height' ? 'width' : 'height']: '100%',
          background: bgColor,
        }}
      />
    );
  },
};
